import React, { useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateContact } from "../../../app/features/user/userSlice";
import Joi from "joi";
import axios from "axios";
import OtpInput from "react-otp-input";
import { apiBaseUrl } from "../../config/Config";
import $ from "jquery";
import { contactRegex } from "../../../shared/validators";

const AddPhoneModal = () => {
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [userContact, setUserContact] = useState({
    userContact: "",
  });
  const [errors, setErrors] = useState({
    userContact: "",
  });

  const [userContactError, setUserContactError] = useState(false);
  const [userContactErrorMessage, setUserContactErrorMessage] = useState("");

  const [userContactChangeOtp, setUserContactChangeOtp] = useState(false);
  const [OTP, setOTP] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [otpToken, setOtpToken] = useState(null);

  const handleChange = (OTP) => {
    setOTP(OTP);
  };

  const handleModalClose = () => {
    $("#add-number-modal").modal("hide");
  };

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setUserContact({
      ...userContact,
      [name]: value,
    });
    setErrors({});
  };

  var schema = Joi.object ({
    userContact: Joi.string().regex(contactRegex).min(6).max(15).required().label("userContact").messages({
      "string.empty": "Contact cannot be empty",
      "string.pattern.base": "Please enter correct Contact",
      "string.min": "Contact must be at least 6 digits",
      "string.max":"Contact  must be no more than 15 digits"
    }),
  });

  const formValidation = () => {
    const result = schema.validate(userContact);
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors({ ...formErrors });
    } else {
      setErrors({});
      submitUserContact(userContact.userContact);
    }
  };

  const submitUserContact = (contact) => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .put(`${apiBaseUrl}/api/user/change-contact`, { contact: contact }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          setUserContactChangeOtp(true);
          setOtpToken(res.data.data.token);
          setUserContact({});
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setUserContactError(true);
          setUserContactErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setUserContactError(false);
            setUserContactErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 401) {
          setUserContactError(true);
          setUserContactErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setUserContactError(false);
            setUserContactErrorMessage("");
          }, 5000);
        }
      });
  };

  const handleOnSubmitOtp = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "x-auth-otp": otpToken,
      },
    };
    const otpParse = Number(OTP);

    axios
      .put(`${apiBaseUrl}/api/user/verify-change-contact`, { otp: otpParse }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          dispatch(updateContact(res.data.data.contact));
          setUserContactChangeOtp(false);
          setOTP("");
          handleModalClose();
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 401) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }
      });
  };

  return (
    <div
      className="modal"
      id="add-number-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="addNumberModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="add-number-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                {userContactChangeOtp === false && (
                  <>
                    <form onSubmit={handleOnSubmit}>
                      <div className="mt-1">
                        <div className="heading-modal">Add Phone Number!</div>
                      </div>
                      {userContactError && (
                        <div
                          className="error-text text-center alert-danger"
                          role="alert"
                        >
                          {userContactErrorMessage}
                        </div>
                      )}
                      <div className="mt-3">
                        <div className="field-heading">Phone</div>
                        <div className="form-group">
                          <input
                            type="contact"
                            className="form-control"
                            value={userContact.userContact || ""}
                            name="userContact"
                            onChange={handleInputOnChange}
                          />
                          {errors.userContact && (
                            <div className="error-text">
                              {errors.userContact}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-1 mb-3">
                        <button type="submit" className="btn-sign-in w-100">
                          Save Change
                        </button>
                      </div>
                    </form>
                  </>
                )}
                {userContactChangeOtp === true && (
                  <>
                    <form onSubmit={handleOnSubmitOtp}>
                      <div className="mt-1">
                        <div className="heading-modal">
                          Otp sent on {userContact.userContact}!
                        </div>
                      </div>
                      {otpError && (
                        <div
                          className="error-text text-center alert-danger"
                          role="alert"
                        >
                          {otpErrorMessage}
                        </div>
                      )}
                      <div className="mt-3">
                        <div className="field-heading">OTP</div>
                        <div className="mt-1">
                          <OtpInput
                            onChange={handleChange}
                            value={OTP}
                            inputStyle="otpinputStyle"
                            numInputs={6}
                            separator={<span></span>}
                          />
                        </div>
                        <div className="d-flex justify-content-between mt-2 mb-3">
                          <div className="otp-resend">Didn't receive OTP</div>
                          <div role="button" className="otp-resend-link">
                            Resend
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 mb-3">
                        <button type="submit" className="btn-sign-in w-100">
                          Save Change
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPhoneModal;
