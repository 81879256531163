import React from "react";
import { useLocation, Navigate } from "react-router-dom";

const PublicRoute = ({ component: Component, ...restofprops }) => {
  const isAuthenticated = sessionStorage.getItem("token");
  const location = useLocation();
  return isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default PublicRoute;
