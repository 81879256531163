import React from "react";

import UserAvatar from "../../Assets/images/avatar.svg";
import IconLocation from "../../Assets/images/icons/dashboard-icons/icon-location.svg";
import IconLocationDanger from "../../Assets/images/icons/dashboard-icons/icon-location-danger.svg";
import IconName from "../../Assets/images/icons/dashboard-icons/userprofile.svg";
import IconEmail from "../../Assets/images/icons/dashboard-icons/icon-email.svg";
import IconEmailDanger from "../../Assets/images/icons/dashboard-icons/icon-email-danger.svg";
import IconPhone from "../../Assets/images/icons/dashboard-icons/icon-phone.svg";
import IconPhoneDanger from "../../Assets/images/icons/dashboard-icons/icon-phone-danger.svg";
import IconPassword from "../../Assets/images/icons/dashboard-icons/icon-password.svg";
import ChangeNameModal from "../../Components/DashboardComponents/Modals/ChangeNameModal";
import ChangeEmailModal from "../../Components/DashboardComponents/Modals/ChangeEmailModal";
import ChangePasswordModal from "../../Components/DashboardComponents/Modals/ChangePasswordModal";
import ChangeNumberModal from "../../Components/DashboardComponents/Modals/ChangeNumberModal";
import { useSelector } from "react-redux";
import ChangeProfileModal from "../../Components/DashboardComponents/Modals/ChangeProfileModal";
import AddPhoneModal from "../../Components/DashboardComponents/Modals/AddPhoneModal";
import AddEmailModal from "../../Components/DashboardComponents/Modals/AddEmailModal";
import AddAddressModal from "../../Components/DashboardComponents/Modals/AddAddressModal";
import { useNavigate } from "react-router-dom";
import { imgBaseUrl } from "../../Components/config/Config";
import AddLocationModal from "../../Components/DashboardComponents/Modals/AddLocationModal";
import MapView from "../../Components/MapView/MapView";

const ProfileSetting = () => {
  const { userInfo } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const NavigateToAddressBook = () => {
    navigate("/user-address");
  };
  return (
    <>
      <div id="profilesetting" className="p-3">
        <div className="d-flex flex-wrap  justify-content-start">
          {userInfo.image === null ? (
            <>
              <img src={UserAvatar} className="image-user" alt="UserAvatar" />
              <div className="ml-3 d-flex flex-column justify-content-between">
                <div className="name">{userInfo.name}</div>
                <div
                  role="button"
                  className="button-status-cancel text-center mb-2"
                  data-toggle="modal"
                  data-target="#profile-modal"
                >
                  Add Profile Picture
                </div>
              </div>
            </>
          ) : (
            <>
              <img
                src={`${imgBaseUrl}/profile/avatar_${userInfo.image}`}
                className="image-user"
                alt="UserImage"
                loading="lazy"
              />
              <div className="ml-3 d-flex flex-column justify-content-between">
                <div className="name">{userInfo.name}</div>
                <div
                  role="button"
                  className="button-status-cancel text-center mb-2"
                  data-toggle="modal"
                  data-target="#profile-modal"
                >
                  Change Profile Picture
                </div>
              </div>
            </>
          )}
        </div>
        <div className="mt-4 d-flex flex-lg-row flex-md-column flex-sm-column flex-wrap justify-content-between">
          <fieldset className="field-set p-1 address-1">
            <legend className="w-auto ml-2 mr-2 field-set-legend">
              <div className="ml-2 mr-2">Name</div>
            </legend>
            <div className="d-flex flex-column justify-content-start">
              <div className="d-flex">
                <div className="mx-3">
                  <img
                    className="field-set-icon"
                    src={IconName}
                    alt="IconName"
                  />
                </div>
                <div className="field-set-text">{userInfo.name}</div>
              </div>
              <div className="d-flex justify-content-end py-2 px-2">
                <div
                  className="field-set-link mx-2"
                  role="button"
                  data-toggle="modal"
                  data-target="#name-modal"
                >
                  Change
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="field-set p-1 address-1">
            <legend className="w-auto ml-2 mr-2 field-set-legend">
              <div className="ml-2 mr-2">Email</div>
            </legend>
            {userInfo.isEmailVerify === true ? (
              <>
                <div className="d-flex flex-column justify-content-start">
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        className="field-set-icon"
                        src={IconEmail}
                        alt="IconEmail"
                      />
                    </div>
                    <div className="field-set-text">{userInfo.email}</div>
                  </div>
                  <div className="d-flex justify-content-end py-2 px-2">
                    <div
                      className="field-set-link mx-2"
                      role="button"
                      data-toggle="modal"
                      data-target="#email-modal"
                    >
                      Change
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex flex-column justify-content-start">
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        className="field-set-icon"
                        src={IconEmailDanger}
                        alt="IconEmailDanger"
                      />
                    </div>
                    <div className="field-set-text-danger">
                      Email not verified yet!
                    </div>
                  </div>
                  <div className="d-flex justify-content-end py-2 px-2">
                    <div
                      className="field-set-text-danger mx-2"
                      role="button"
                      data-toggle="modal"
                      data-target="#add-email-modal"
                    >
                      Add Email
                    </div>
                  </div>
                </div>
              </>
            )}
          </fieldset>
        </div>
        <div className="mt-4 d-flex flex-wrap flex-lg-row flex-md-column flex-sm-column justify-content-between">
          <fieldset className="field-set p-1 address-1">
            <legend className="w-auto ml-2 mr-2 field-set-legend">
              <div className="ml-2 mr-2">Phone</div>
            </legend>
            {userInfo.isContactVerify === true ? (
              <>
                <div className="d-flex flex-column justify-content-start">
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        className="field-set-icon"
                        src={IconPhone}
                        alt="IconPhone"
                      />
                    </div>
                    <div className="field-set-text">{userInfo.contact}</div>
                  </div>
                  <div className="d-flex justify-content-end py-2 px-2">
                    <div
                      className="field-set-link mx-2"
                      role="button"
                      data-toggle="modal"
                      data-target="#number-modal"
                    >
                      Change
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex flex-column justify-content-start">
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        className="field-set-icon"
                        src={IconPhoneDanger}
                        alt="IconPhoneDanger"
                      />
                    </div>
                    <div className="field-set-text-danger">
                      Contact not verified yet!
                    </div>
                  </div>
                  <div className="d-flex justify-content-end py-2 px-2">
                    <div
                      className="field-set-text-danger mx-2"
                      role="button"
                      data-toggle="modal"
                      data-target="#add-number-modal"
                    >
                      Add Phone
                    </div>
                  </div>
                </div>
              </>
            )}
          </fieldset>
          <fieldset className="field-set p-1 address-1">
            <legend className="w-auto ml-2 mr-2 field-set-legend">
              <div className="ml-2 mr-2">Password</div>
            </legend>
            <div className="d-flex flex-column justify-content-start">
              <div className="d-flex">
                <div className="mx-3">
                  <img
                    className="field-set-icon"
                    src={IconPassword}
                    alt="IconPassword"
                  />
                </div>
                <div className="field-set-text">**********************</div>
              </div>
              <div className="d-flex justify-content-end py-2 px-2">
                <div
                  className="field-set-link mx-2"
                  role="button"
                  data-toggle="modal"
                  data-target="#password-modal"
                >
                  Change
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div className="mt-3">
  {userInfo.address.length === 0 ? (
    <>
      <fieldset className="field-set p-1">
        <legend className="w-auto ml-2 mr-2 field-set-legend">
          <div className="ml-2 mr-2">Address</div>
        </legend>
        <div className="d-flex flex-column justify-content-start">
          <div className="d-flex">
            <div className="mx-3">
              <img
                className="field-set-icon"
                src={IconLocationDanger}
                alt="IconLocationDanger"
              />
            </div>
            <div className="field-set-text-danger">
              No address added!
            </div>
          </div>
          <div className="d-flex justify-content-end py-2 px-2">
            <div
              className="field-set-text-danger mx-2"
              role="button"
              data-toggle="modal"
              data-target="#add-address-modal"
            >
              Add Address
            </div>
          </div>
        </div>
      </fieldset>
    </>
  ) : (
    <>
      <fieldset className="field-set p-1">
        <legend className="w-auto ml-2 mr-2 field-set-legend">
          <div className="ml-2 mr-2">Address 1</div>
        </legend>
        <div className="d-flex flex-column justify-content-start">
          <div className="d-flex mb-3">
            <div className="mx-3">
              <img
                className="field-set-icon"
                src={IconLocation}
                alt="IconLocation"
              />
            </div>
            <div className="field-set-text">
              {userInfo.address[0]?.completeAddress} {", "}
              {userInfo.address[0]?.landmark}
              {", "}
              {userInfo.address[0]?.city}
              {", "}
              {userInfo.address[0]?.postalOrZip}
              {", "}
              {userInfo.address[0]?.province}
            </div>
          </div>
          <div className="d-flex justify-content-end py-2 px-2">
            <div
              className="field-set-link mx-2"
              role="button"
              onClick={NavigateToAddressBook}
            >
              Change
            </div>
          </div>
        </div>
      </fieldset>
    </>
  )}
</div>
<div className="mt-3">
       <>
            {
              userInfo['isMapAddress'] ? 
              
              <div className="mt-3">
                  <fieldset className="field-set p-1">
                    <legend className="w-auto ml-2 mr-2 field-set-legend">
                      <div className="ml-2 mr-2">Map Location</div>
                    </legend>
                    <div style={{width:'auto',height:'auto'}} >
                      <MapView lat={userInfo?.location?.coordinates?.[1]} lng={userInfo?.location?.coordinates?.[0]} mapAddress={userInfo['mapAddress']} zoom={15} width={'46vw'} height={'25vh'} />
                    </div>
                    <div className="d-flex justify-content-end py-2 px-2">
                    <div
                    className="field-set-link mx-2"
                      role="button"
                      data-toggle="modal"
                      data-target="#add-location-modal">{userInfo.isMapAddress?'Change Location':'Add Location'}</div>
                     </div>
                  </fieldset>
                </div>
               : <>
                <div className="mt-3">
                  <fieldset className="field-set p-1">
                    <legend className="w-auto ml-2 mr-2 field-set-legend">
                      <div className="ml-2 mr-2">Map Location</div>
                    </legend>
                    <div className="d-flex flex-column justify-content-start">
                      <div className="d-flex justify-content-end py-2 px-2">
                        <div
                          className="field-set-link mx-2"
                          role="button"
                          data-toggle="modal"
                          data-target="#add-location-modal" //change it
                        >
                          {" "}
                          Add Map Location
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </>
            }
          </>
        </div>
      </div>

      {/* Change Profile Modal */}
      <ChangeProfileModal />

      {/* Change Name Modal */}
      <ChangeNameModal />

      {/* Change Email Modal */}
      <ChangeEmailModal />
      {/* Add Number Modal */}
      <AddEmailModal />
      {/* Change Number Modal */}
      <ChangeNumberModal />
      {/* Add Number Modal */}
      <AddPhoneModal />

      {/* Change password Modal */}
      <ChangePasswordModal />
      
      {/* Add Address Modal */}
      <AddAddressModal />
      {/* Add Location Modal */}
      <AddLocationModal isMapAddress ={userInfo?.isMapAddress}/>

    </>
  );
};

export default ProfileSetting;
