import React from "react";
import "./Banner.css";
import banner_1 from "../../Assets/images/shop/banner-1.png";
import banner_2 from "../../Assets/images/shop/banner-2.png";
import banner_3 from "../../Assets/images/shop/banner-3.png";

const Banner = () => {
  return (
    <section className="banners  mt-10">
      <div className="container">
        <div className="d-flex x-scrolls">
          <div className="col-lg-4 col-md-9  col-sm-8 col-10 mb-15  banner-div">
            <div className="banner-img wow fadeIn animated">
              <div className="img-inner bg-light">
                <img src={banner_1} alt="banner_1" loading="lazy"/>
              </div>

              <div className="banner-text">
                <span className="banner-text-span">Smart Offer</span>
                <h4 className="banner-text-h4">
                  Save 20% on <br />
                  Woman Bag
                </h4>
                <a className="banner-text-a" href="/">
                  Shop Now <i className="banner-text-a-i fa fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-9  col-sm-8 col-10 mb-15  banner-div">
            <div className="banner-img wow fadeIn animated">
              <div className="img-inner bg-light">
                <img src={banner_2} alt="banner_2" loading="lazy"/>
              </div>
              <div className="banner-text">
                <span className="banner-text-span">Sale off</span>
                <h4 className="banner-text-h4">
                  Great Summer <br />
                  Collection
                </h4>
                <a className="banner-text-a" href="/">
                  Shop Now <i className="banner-text-a-i fa fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-9  col-sm-8 col-10 mb-15  banner-div">
            <div className="banner-img wow fadeIn animated">
              <div className="img-inner bg-light">
                <img src={banner_3} alt="banner_3" loading="lazy"/>
              </div>
              <div className="banner-text">
                <span className="banner-text-span">New Arrivals</span>
                <h4 className="banner-text-h4">
                  Shop Today’s <br />
                  Deals & Offers
                </h4>
                <a className="banner-text-a" href="/">
                  Shop Now <i className="banner-text-a-i fa fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
