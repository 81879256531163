import React, { useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateName } from "../../../app/features/user/userSlice";
import Joi from "joi";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl } from "../../config/Config";
import { nameregex } from "../../../shared/validators";
const ChangeNameModal = () => {
  const { userToken, userInfo } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [userName, setUserName] = useState({
    userName: userInfo.name?userInfo.name:"",
  });
  const [errors, setErrors] = useState({
    userName: "",
  });
  const [userNameError, setUserNameError] = useState(false);
  const [userNameErrorMessage, setUserNameErrorMessage] = useState("");

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setUserName({
      ...userName,
      [name]: value,
    });
    setErrors({});
  };

  var schema = Joi.object({
    userName: Joi.string().required().regex(nameregex).min(3).max(35).label("userName").messages({
      "string.empty": "Name cannot be empty",
      "string.pattern.base": "Enter correct name",
      "string.min": "Name must be at least 3 characters",
    }),
  });
  const formValidation = () => {
    const result = schema.validate(userName);
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors({ ...formErrors });
    } else {
      setErrors({});
      submitUserName(userName.userName);
    }
  };

  const handleModalClose = () => {
    $("#name-modal").modal("hide");
  };
  const submitUserName = (name) => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .put(`${apiBaseUrl}/api/user/change-name`, { name: name }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          dispatch(updateName(res.data.data.name));
          setUserName({});
          handleModalClose();
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setUserNameError(true);
          setUserNameErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setUserNameError(false);
            setUserNameErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 401) {
          setUserNameError(true);
          setUserNameErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setUserNameError(false);
            setUserNameErrorMessage("");
          }, 5000);
        }
      });
  };

  return (
    <div
      className="modal"
      id="name-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="nameModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="name-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                <form onSubmit={handleOnSubmit}>
                  <div className="mt-1">
                    <div className="heading-modal">Change Name!</div>
                  </div>
                  {userNameError && (
                    <div
                      className="error-text text-center alert-danger"
                      role="alert"
                    >
                      {userNameErrorMessage}
                    </div>
                  )}
                  <div className="mt-3">
                    <div className="field-heading">Name</div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={userName.userName}
                        name="userName"
                        onChange={handleInputOnChange}
                      />
                      {errors.userName && (
                        <div className="error-text">{errors.userName}</div>
                      )}
                    </div>
                  </div>
                  <div className="mt-1 mb-3">
                    <button type="submit" className="btn-sign-in w-100">
                      Save Change
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeNameModal;
