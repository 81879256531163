import { io } from "socket.io-client";
import { apiBaseUrl } from "../config/Config";

const socket = userToken => new io(apiBaseUrl,{
    autoConnect: false,
    withCredentials: true,
    auth:{
        token: userToken
    }
})

export default socket