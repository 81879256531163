import React from "react";
import Lottie from "react-lottie";
import animationData from "../../Assets/CartbayLoader.json";

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div id="loader">
      <div className="h-100 d-flex align-items-center justify-content-center">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    </div>
  );
};

export default Loader;
