import React, { useState } from "react";
import IconLocation from "../../Assets/images/icons/icon-location-card.svg";
import NoAddress from "../../Assets/images/icons/dashboard-icons/no-address-yet.svg";
import AddAddressModal from "../../Components/DashboardComponents/Modals/AddAddressModal";
import ChangeAddressModal from "../../Components/DashboardComponents/Modals/ChangeAddressModal";
import { toast,ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { apiBaseUrl } from "../../Components/config/Config";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { addAddress } from "../../app/features/user/userSlice";
const AddressBook = () => {
  const { userInfo,userToken } = useSelector((state) => state.user);
  const [addressId, setAddressId] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const OnClickChangeAddress = (e, id) => {
    e.preventDefault();
    setAddressId(id);
  };
  const config = {
    headers: {
      "x-auth-token": userToken,
    },
  };
  const dispatch = useDispatch();
  
  const handleAddAddressClick = () => {
    if (userInfo?.address.length < 5) {
      setShowAlert(false);
    } else {
      setShowAlert(true);
      toast.error("No more than five addresses");
    }
  };

  const  removeAddress = async(indexNumber)=>{
    try{
        axios.delete(`${apiBaseUrl}/api/user/deleteaddress/${indexNumber}`,config).then((res)=>
    {
      dispatch(addAddress(res.data.data.address));
    })}
     catch(error) {
      toast.error("error occurred while deleting address");
      }
   }
  return (
    <>
      <div id="addressbook" className="p-3">
      <ToastContainer />
        <div className="d-flex justify-content-between">
          <div className="heading">Address Book</div>
          <div
            role="button"
            className="button-status-cancel text-center"
            data-toggle="modal"
           data-target={showAlert ? undefined : "#add-address-modal"}
            onClick={handleAddAddressClick}
          >
            Add New Address
          </div>
        </div>
        {userInfo.address.length === 0 ? (
          <>
            <div className="h-100 d-flex align-items-center justify-content-center">
              <img src={NoAddress} alt="NoAddress" />
            </div>
          </>
        ) : (
          <>
            <div className="mt-4 address-1 mb-2">
              {userInfo.address.map((address, index) => {
                return (
                  <fieldset key={index} className="field-set p-1">
                    <legend className="w-auto ml-2 mr-2 field-set-legend">
                      <div className="ml-2 mr-2">Address {index + 1}</div>
                    </legend>
                    <div className="d-flex flex-column justify-content-start">
                      <div className="d-flex">
                        <div className="mx-3">
                          <img
                            className="field-set-icon"
                            src={IconLocation}
                            alt="IconLocation"
                          />
                        </div>
                        <div className="field-set-text">
                          {address.completeAddress} {", "}
                          {address.landmark}
                          {", "}
                          {address.city}
                          {", "}
                          {address.postalOrZip}
                          {", "}
                          {address.province}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end py-2 px-2">
                      {
                        userInfo?.address.length>1?<div
                          className="field-set-link mx-2 text-danger"
                          role="button"
                          onClick={()=>removeAddress(index)}
                            >
                          Remove
                        </div>:<></>
                      }
                        <div
                          className="field-set-link mx-2"
                          role="button"
                          onClick={(e) => {
                            OnClickChangeAddress(e,index);
                          }}
                          data-toggle="modal"
                          data-target="#address-modal"
                        >
                          Change
                        </div>
                      </div>
                    </div>
                  </fieldset>
                );
              })}
            </div>
          </>
        )}
      </div>
      <ChangeAddressModal addressId={addressId} />
       <AddAddressModal />
    </>
  );
};

export default AddressBook;
