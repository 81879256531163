import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Joi from "joi";
import { useNavigate } from "react-router-dom";
import LoginImage from "../Assets/images/login-img.svg";
import IconWave from "../Assets/images/icons/icon-wave.svg";
import IconFacebook from "../Assets/images/icons/icon-facebook.svg";
import IconGoogle from "../Assets/images/icons/icon-google.svg";
import Logo from "../Assets/images/logo-white.svg";
import { apiBaseUrl } from "../Components/config/Config";
import { contactRegex } from "../shared/validators";
import { emailRegex ,nameregex,passwordregex, confirmpasswordregex} from "../shared/validators";
export default function Register() {
  let navigate = useNavigate();
  const [registerError, setRegisterError] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");
  const [inputType, setInputType] = useState("email");
  const [isEmail, setIsEmail] = useState(true);
  const[error , setError] = useState("");
  const [emailValues, setEmailValues] = useState({
    email: "",
  });
  const [contactValues, setContactValues] = useState({
    contact: "",
  });
  const [values, setValues] = useState({
    name: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    password: "",
    confirmPassword: "",
  });

  const onChangeInputType = (e) => {
    let value = e.target.value;
    if (value && value.includes("@")) {
      setError("");
       setInputType("email");
      setIsEmail(true);
      setEmailValues({
        ...emailValues,
        email: value,
      });
    } else {
      setError("");
       setInputType("contact");
      setIsEmail(false);
      setContactValues({
        ...contactValues,
        contact: value,
      });
    }
  };

  const handleInputOnChange = (e) => {
    setErrors("");
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  var schema = Joi.object ({
    name:Joi.string().required().regex(nameregex).min(3).max(35).label('Name').messages({
      "string.empty": "Name cannot be empty",
      "string.min": "Name must be at least 3 characters long",
      "string.max": "Name must be no more than 35 characters long",
      "string.pattern.base": "Please enter correct  name",
    }),
    password: Joi.string().regex(passwordregex).required().min(7).max(100).messages({
      "string.empty": "Password cannot be empty",
      "string.pattern.base": "Please enter the correct format for the password",
      "string.min": "Password must be at least 8 characters long",
      "string.max": "Password must be no more than 100 characters long"
    }),
    confirmPassword:Joi.string().required().valid(Joi.ref('password')).label("confirmPassword").messages({
      "any.only": "Passwords do not match",
      "any.required": "Confirm password is required"
    }),
  });
  const formValidation = () => {
    const result = schema.validate(values);
    if (!result.error) return null;

    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const validateEmail = (email)=>{
    return emailRegex.test(email);
  }
  const validateContact = (contact) => {
    return contactRegex.test(contact);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let { name, password } = values;
    let { email } = emailValues;
    let { contact } = contactValues;

    const eerrors = formValidation();
    if (eerrors) {
      setErrors({ ...eerrors });
    } else {
      setErrors({});
      if (isEmail === true) {
        if(validateEmail(email)){
          registerAccount({
            isEmail,
            name,
            email,
            password,
          });
        }else{
          setError("Email is not valid!")
        }
      } else if (isEmail === false) {
        if(validateContact(contact)){
          registerAccount({
            isEmail,
            name,
            contact,
            password,
          });
        }else{
          setError("Add valid contact like:(03xxxxxxxxx)")
        }
      }
    }
  };
  const registerAccount = (values) => {
    axios
      .post(`${apiBaseUrl}/api/user/signup`, { ...values })
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          sessionStorage.setItem("token", res.data.data.token);
          sessionStorage.setItem("isEmail", res.data.data.isEmail);
          if(res.data.data.isEmail){
            sessionStorage.setItem("email", res.data.data.email);
          }else{
            sessionStorage.setItem("contact", res.data.data.contact);
          }
          sessionStorage.setItem("password", res.data.data.password);
          navigate("/register-otp");
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setRegisterError(true);
          setRegisterErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setRegisterError(false);
            setRegisterErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 401) {
          setRegisterError(true);
          setRegisterErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setRegisterError(false);
            setRegisterErrorMessage("");
          }, 5000);
        }
      });
  };
  return (
    <div id="register">
<div className="girl-image">
<div className="login-container">
        <Link to="/" className="header-logo">
          <img src={Logo} alt="Logo" />
        </Link>
        <div className="d-flex full-sec flex-xl-row flex-lg-row flex-md-row flex-sm-column  justify-content-between">
          <div className="left-sec">
            <div className="heading-login">Amazing day.</div>
            <div className="description-login mt-3">
              Maybe some text here will help me see it better. Oh God. Oke,
              let’s do it then.
            </div>
          </div>
          <div className="log">
                                   <img
              className="login-image-section"
              src={LoginImage}
              alt="LoginImage"
            />
          </div>
          <div className="mb-5 right-sec form-wid">
            <div className="login-form ">
              <img src={IconWave} alt="IconCancel" />
              <div className="">
                <div className="heading-form">Create Account!</div>
                {/* <div className="text-para mt-2">
                  Please Signup to Create Account
                </div> */}
                {registerError && (
                  <div
                    className="error-text text-center alert-danger"
                    role="alert"
                  >
                    {registerErrorMessage}
                  </div>
                )}
              </div>
              <form onSubmit={handleOnSubmit}>
                <div className="">
                  <div className="field-heading">Full Name</div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type your full name"
                      name="name"
                      value={values.name || ""}
                      onChange={handleInputOnChange}
                    />
                  </div>
                  {errors.name && (
                    <div className="error-text">{errors.name}</div>
                  )}
                </div>
                <div>
                  <div className="field-heading">E-mail or phone number</div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Type your e-mail or phone number"
                      name={inputType}
                      // type={inputType}
                      onChange={(e) => onChangeInputType(e)}
                      required
                    />
                  </div>
                  {
                    <div className="error-text pb-1">
                       {error}
                    </div>
                  }
                </div>
                <div>
                  <div className="field-heading">Password</div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="********"
                      name="password"
                      value={values.password || ""}
                      onChange={handleInputOnChange}
                    />
                  </div>
                  {errors.password && (
                    <div className="error-text pb-1">
                     {errors.password}
                    </div>
                  )}
                </div>
                <div>
                  <div className="field-heading">Confirm Password</div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="********"
                      value={values.confirmPassword || ""}
                      name="confirmPassword"
                      onChange={handleInputOnChange}
                    />
                  </div>
                  {errors.confirmPassword && (
                    <div className="error-text pb-1">
                     {errors.confirmPasswords}
                    </div>
                  )}
                </div>

                <div className="">
                  <button type="submit" className="btn-sign-in w-100">
                    Sign Up
                  </button>
                </div>
              </form>
              <div className="d-flex justify-content-center align-items-center">
                <span className="line-login"></span>
                <small className="px-2 line-text">
                  or do it via other accounts
                </small>
                <span className="line-login"></span>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-2">
                <div role="button" className="icon-card mx-3">
                  <img src={IconGoogle} alt="IconGoogle" />
                </div>
                <div role="button" className="icon-card mx-3">
                  <img src={IconFacebook} alt="IconFacebook" />
                </div>
              </div>
              <div className="mt-1">
                <div className="login-footer mx-3">
                  Already have an account?{" "}
                  <span className="login-footer-span">
                    <Link to="/login">Login</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
    </div>
  );
}
