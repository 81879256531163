import React, { useState } from "react";
import "./chat.css";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { apiBaseUrl, imgBaseUrl } from "../config/Config";

const ChatConversations = ({ setConversationId, setItemId, setStoreId, messageStatusId, setMessageStatusId }) => {
  const [conversationList, setConversationList] = useState([]);
  const { userToken, userInfo } = useSelector((state) => state.user);
  const [selectedConversationIndex, setSelectedConversationIndex] = useState(0);
  let config = {
    headers: {
      "x-auth-token": userToken,
    },
  };

  const updateMessageStatus = (id, unRead) => {
    if (id!=null && id!=undefined && unRead != 0) {
      const apiUrl = `${apiBaseUrl}/api/message/user-message-status/${id}`;
      axios.put(apiUrl, {}, config).then((res) => {
        const updatedConversationList = conversationList.map((conversation, index) => {
          if (conversationList._id === res.data.conversationId) {
            return {
              ...conversation,
              unRead: 0,
            };
          }
          return conversation;
        });
        setConversationList(updatedConversationList);
      });
    }
  }

  const socketMessageStatus = (id) => {
    if(id!=null){
      const updatedConversationList = conversationList.map((conversation, index)=>{
        if (conversation.id === id) {
          return{
            ...conversation,
            unRead: conversation['unRead']+1
          }
        }
        return conversation
      })
      setConversationList(updatedConversationList);
    }
    setMessageStatusId(null)
  };

  useEffect(()=>{
    if(messageStatusId!=null){
      socketMessageStatus(messageStatusId)
    }
  }, [messageStatusId])

  useEffect(() => {
    const page = 0;
    const apiUrl = `${apiBaseUrl}/api/conversion/user-conversation-list?page=${page}`;
    axios.get(apiUrl, config).then((res) => {
      setConversationList(res?.data?.data?.conversationList);
      setConversationId(res.data.data.conversationList[0].id);
      setItemId(res.data.data.conversationList[0].itemId);
      setStoreId(res.data.data.conversationList[0].storeId);
      updateMessageStatus(res.data.data.conversationList[0].id, res.data.data.conversationList[0].unRead)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contact-list">
      <div className="Loreal-Cosm ">
        {conversationList?.map((data, index) => (
          <div className="border-bottom">
            <div
              className={`person  p-1 d-flex mx-auto ${selectedConversationIndex === index ? "selected" : ""
                }`}
              key={index}
              onClick={() => {
                updateMessageStatus(data?.id, data.unRead)
                setSelectedConversationIndex(index);
                setConversationId(data?.id);
                setItemId(data.itemId);
                setStoreId(data.storeId);
              }}
            >

              <div className=" p-1 d-flex">
                <img
                  className="rounded rounded-circle"
                  src={`${imgBaseUrl}/listing/small_${data?.data.images[0]}`}
                  alt="thumb-img"
                  loading="lazy"
                />
                <span className="d-flex">
                  <p className="p-2">
                    {data?.data?.title}
                    </p>
                    {data.unRead?<>{data.unRead > 9 ? <p className="unread-message">9+</p> : <p className="unread-message">{data.unRead}</p>}</>:<></>}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatConversations;
