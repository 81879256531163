import React from "react";
import feature_1 from "../../Assets/images/icons/feature-1.png";
import feature_2 from "../../Assets/images/icons/feature-2.png";
import feature_3 from "../../Assets/images/icons/feature-3.png";
import feature_4 from "../../Assets/images/icons/feature-4.png";
import feature_5 from "../../Assets/images/icons/feature-5.png";
import feature_6 from "../../Assets/images/icons/feature-6.png";

const Feature = () => {
  return (
    <div id="feature" className="container">
      <section>
        <div className="d-lg-flex justify-content-center col-md-column  m-0 p-0">
          <div className="d-flex">
          <div className="col-lg-4 col-md-4 col-sm-4  mb-sm-3 bottom-set  mb-md-3 mb-lg-0">
            <div className="banner-features wow fadeIn animated hover-up">
              <img src={feature_1} alt="feature" loading="lazy" />
              <h4 className="bg-1">Free Shipping</h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4  mb-sm-3 bottom-set mb-md-3 mb-lg-0">
            <div className="banner-features wow fadeIn animated hover-up">
              <img src={feature_2} alt="feature" loading="lazy" />
              <h4 className="bg-3">Online Order</h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4  mb-sm-3 bottom-set mb-md-3 mb-lg-0">
            <div className="banner-features wow fadeIn animated hover-up">
              <img src={feature_3} alt="feature" loading="lazy" />
              <h4 className="bg-2">Save Money</h4>
            </div>
          </div>
          </div>
          <div className="d-flex">
          <div className="col-lg-4 col-md-4 col-sm-4  mb-sm-3 bottom-set mb-md-3 mb-lg-0">
            <div className="banner-features wow fadeIn animated hover-up">
              <img src={feature_4} alt="feature" loading="lazy" />
              <h4 className="bg-4">Promotions</h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4  mb-sm-3 bottom-set mb-md-3 mb-lg-0">
            <div className="banner-features wow fadeIn animated hover-up">
              <img src={feature_5} alt="feature" loading="lazy" />
              <h4 className="bg-5">Happy Sell</h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4  mb-sm-3 bottom-set mb-md-3 mb-lg-0">
            <div className="banner-features wow fadeIn animated hover-up">
              <img src={feature_6} alt="feature" loading="lazy" />
              <h4 className="bg-6">24/7 Support</h4>
            </div>
          </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Feature;
