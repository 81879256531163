import React, { useState } from "react";
import AllOrders from "../../Components/DashboardComponents/UserOrder/AllOrders";
import OrderWithStatus from "../../Components/DashboardComponents/UserOrder/OrderWithStatus";

function OrderPlace() {
  const [statusFilter, setStatusFilter] = useState("All");

  // useEffect(() => {
  //   (
  //     async function getStatus() {

  //     setTimeout(getStatus, 5000);
  //   })()
  //   const config = {
  //     headers: {
  //       "x-auth-token": sessionStorage.getItem("token"),
  //     },
  //   };
  //   axios
  //     .get(`api/order/${page}`, config)
  //     .then((res) => {
  //       setOrdervalue([...ordervalue, ...res?.data?.orders]);
  //       setItemdata([...itemdata, ...res?.data?.itemsData]);
  //       setLoading(false);
  //       if (res.data.page === page) {
  //         setIsLoad(false);
  //       } else {
  //         setPage(res.data.page);
  //       }
  //     })
  //     .catch((err) => {});
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loadData]);

  // const updateStatus = (id, status) => {
  //   const config = {
  //     headers: {
  //       "x-auth-token": sessionStorage.getItem("token"),
  //     },
  //   };
  //   axios
  //     .put(`api/orderresturent/${id}/${status}`, config)
  //     .then((res) => {
  //       if (res.data.status) {
  //         // setRefresh(true)
  //         const config = {
  //           headers: {
  //             "x-auth-token": sessionStorage.getItem("token"),
  //           },
  //         };
  //         axios
  //           .get("api/order", config)
  //           .then((res) => {
  //             setOrdervalue(res?.data?.orders);
  //             setItemdata(res?.data?.itemsData);
  //           })
  //           .catch((err) => {});
  //       }
  //     })
  //     .catch((err) => {});
  // };
  // const SubmitRating = (id) => {
  //   const config = {
  //     headers: {
  //       "x-auth-token": sessionStorage.getItem("token"),
  //     },
  //     data: { id, rating, comment },
  //   };
  //   axios
  //     .put(`api/order/rating`, config)
  //     .then((res) => {
  //       if (res.data.status) {
  //         // setRefresh(true)
  //         window.location.reload();
  //       }
  //     })
  //     .catch((err) => {});
  // };
  const filterStatus = (categitem) => {
    setStatusFilter(categitem);
  };

  return (
    <>
    <div className="w-100">
    <div id="buyerorder">
        <div className="x-scrolls d-flex align-items-center justify-content-start">
          <div className="d-flex align-item-center justify-content-center ">
            <div
              role="button"
              className={
                statusFilter === "All"
                  ? "filter-button-selected mr-3 text-center"
                  : "filter-button mr-3 text-center"
              }
              onClick={(e) => {
                filterStatus("All");
              }}
            >
              All
            </div>
            <div
              role="button"
              className={
                statusFilter === "Accepted"
                  ? "filter-button-selected mr-3 text-center"
                  : "filter-button mr-3 text-center"
              }
              onClick={(e) => {
                filterStatus("Accepted");
              }}
            >
              Accepted
            </div>
            <div
              role="button"
              className={
                statusFilter === "Pending"
                  ? "filter-button-selected mr-3 text-center"
                  : "filter-button mr-3 text-center"
              }
              onClick={(e) => {
                filterStatus("Pending");
              }}
            >
              Pending
            </div>
            <div
              role="button"
              className={
                statusFilter === "Shipped"
                  ? "filter-button-selected mr-3 text-center"
                  : "filter-button mr-3 text-center"
              }
              onClick={(e) => {
                filterStatus("Shipped");
              }}
            >
              Shipped
            </div>
            <div
              role="button"
              className={
                statusFilter === "Delivered"
                  ? "filter-button-selected mr-3 text-center"
                  : "filter-button mr-3 text-center"
              }
              onClick={(e) => {
                filterStatus("Delivered");
              }}
            >
              Delivered
            </div>
            <div
              role="button"
              className={
                statusFilter === "Cancelled"
                  ? "filter-button-selected mr-3 text-center"
                  : "filter-button mr-3 text-center"
              }
              onClick={(e) => {
                filterStatus("Cancelled");
              }}
            >
              Cancelled
            </div>
          </div>
        </div>
        {statusFilter === "All" && <AllOrders />}
        {statusFilter === "Accepted" && (
          <OrderWithStatus status={statusFilter} />
        )}
        {statusFilter === "Pending" && (
          <OrderWithStatus status={statusFilter} />
        )}
        {statusFilter === "Shipped" && (
          <OrderWithStatus status={statusFilter} />
        )}
        {statusFilter === "Delivered" && (
          <OrderWithStatus status={statusFilter} />
        )}
        {statusFilter === "Cancelled" && (
          <OrderWithStatus status={statusFilter} />
        )}
      </div>
    </div>
    </>
  );
}
export default OrderPlace;
