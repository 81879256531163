import React from "react";
import NoData from "../../Assets/images/no-data.svg";

const NoDataFound = () => {
  return (
    <div className="text-center mb-5">
      <img src={NoData} alt="emptyimage" />
      <div className="no-data-text">No Data Found</div>
    </div>
  );
};

export default NoDataFound;
