import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import iconDashboard from "../../../Assets/images/icons/dashboard-icons/dashboard.svg";
import iconDashboardActive from "../../../Assets/images/icons/dashboard-icons/dashboardactive.svg";
import iconMyorder from "../../../Assets/images/icons/dashboard-icons/myorder.svg";
import iconMyorderActive from "../../../Assets/images/icons/dashboard-icons/myorderactive.svg";
import iconAddressbook from "../../../Assets/images/icons/dashboard-icons/addressbook.svg";
import iconAddressbookActive from "../../../Assets/images/icons/dashboard-icons/addressbookactive.svg";
import iconMyfavourite from "../../../Assets/images/icons/dashboard-icons/myfavourite.svg";
import iconMyfavouriteActive from "../../../Assets/images/icons/dashboard-icons/myfavouriteactive.svg";
import iconSetting from "../../../Assets/images/icons/dashboard-icons/setting.svg";
import iconSettingActive from "../../../Assets/images/icons/dashboard-icons/settingactive.svg";

function SideBarUser() {
  const [user, setUser] = useState({});
  let pathname = window.location.pathname;

  useEffect(() => {
    setUser(JSON.parse(sessionStorage.getItem("User")));
  }, []);

  return (
    <ul className="navbar-nav sidebar accordion" id="accordionSidebar">
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/dashboard-buyer"
          
        >
          {pathname.match("/dashboard-buyer") ? (
            <>
              <img
                className="mr-3"
                src={iconDashboardActive}
                alt="iconDashboardActive"
              />
            </>
          ) : (
            <>
              <img className="mr-3" src={iconDashboard} alt="iconDashboard" />
            </>
          )}

          <span
            className={`${
              pathname.match("/dashboard-buyer")
                ? "sidebar-span-active"
                : "sidebar-span"
            }`}
          >
            Dashboard
          </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/user-orders"
          
        >
          {pathname.match("/user-orders") ? (
            <>
              <img
                className="mr-3"
                src={iconMyorderActive}
                alt="iconMyorderActive"
              />
            </>
          ) : (
            <>
              <img className="mr-3" src={iconMyorder} alt="iconMyorder" />
            </>
          )}
          <span
            className={`${
              pathname.match("/user-orders")
                ? "sidebar-span-active"
                : "sidebar-span"
            }`}
          >
            My Orders
          </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/user-address"
          
        >
          {pathname.match("/user-address") ? (
            <>
              <img
                className="mr-3"
                src={iconAddressbookActive}
                alt="iconAddressbookActive"
              />
            </>
          ) : (
            <>
              <img
                className="mr-3"
                src={iconAddressbook}
                alt="iconAddressbook"
              />
            </>
          )}
          <span
            className={`${
              pathname.match("/user-address")
                ? "sidebar-span-active"
                : "sidebar-span"
            }`}
          >
            Address Book
          </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/user-favourite"
          
        >
          {pathname.match("/user-favourite") ? (
            <>
              <img
                className="mr-3"
                src={iconMyfavouriteActive}
                alt="iconMyfavouriteActive"
              />
            </>
          ) : (
            <>
              <img
                className="mr-3"
                src={iconMyfavourite}
                alt="iconMyfavourite"
              />
            </>
          )}
          <span
            className={`${
              pathname.match("/user-favourite")
                ? "sidebar-span-active"
                : "sidebar-span"
            }`}
          >
            My Favourites
          </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/user-profile-setting"
          
        >
          {pathname.match("/user-profile-setting") ? (
            <>
              <img
                className="mr-3"
                src={iconSettingActive}
                alt="iconSettingActive"
              />
            </>
          ) : (
            <>
              <img className="mr-3" src={iconSetting} alt="iconSetting" />
            </>
          )}
          <span
            className={`${
              pathname.match("/user-profile-setting")
                ? "sidebar-span-active"
                : "sidebar-span"
            }`}
          >
            Accout Setting
          </span>
        </Link>
      </li>
    </ul>
  );
}

export default SideBarUser;
