import React, { useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import { useSelector, useDispatch } from "react-redux";
import { addAddress } from "../../../app/features/user/userSlice";
import Joi from "joi";
import axios from "axios";
import $ from "jquery";
import { apiBaseUrl } from "../../config/Config";
import { cityregex,postalzipcdoregex,landmarkregex,completeaddressregex } from "../../../shared/validators";

const AddAddressModal = () => {
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [address, setAddress] = useState({
    province: "",
    city: "",
    postalOrZip: 0,
    landmark: "",
    completeAddress: "",
  });
  const [errors, setErrors] = useState({
    province: "",
    city: "",
    postalOrZip: "",
    landmark: "",
    completeAddress: "",
  });
  const [addressError, setAddressError] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  const handleModalClose = () => {
    $("#add-address-modal").modal("hide");
  };

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setAddress({
      ...address,
      [name]: value,
    });
    setErrors({});
  };
  var schema = Joi.object({
    province: Joi.string().required().label("province").messages({
      "string.empty": "Province cannot be empty"}),
    city: Joi.string().regex(cityregex).min(3).max(40).required().label("city").messages({
      "string.empty": "City name cannot be empty",
      "string.pattern.base": "Please enter valid City name",
      "string.min": "City name must be at least 3 characters",
      "string.max": "City name must be no more than 40 characters"
    }),
    postalOrZip: Joi.string().min(3).max(10).regex(postalzipcdoregex).min(2).required().label("postalOrZip").required().label("postalOrZip").messages({
      "string.empty": "Postal code cannot be empty",
      "string.pattern.base": "Please enter valid Postal code",
      "string.min": "Postal code must be at least 3 characters",
      "string.max": "Postal code must be no more than 10 characters"
    }),
    landmark: Joi.string().regex(landmarkregex).min(3).max(200).required().label("landmark").messages({
      "string.empty": "Location cannot be empty",
      "string.pattern.base": "Please enter valid location",
      "string.min": "Location must be at least 3 characters",
      "string.max": "Location must be no more than 200 characters"
    }),
    completeAddress: Joi.string().regex(completeaddressregex).min(3).max(200).required().label("completeAddress").messages({
      "string.empty": " Address cannot be empty",
      "string.pattern.base": "Please enter valid address",
      "string.min": "Address must be at least 3 characters",
      "string.max": "Address must be no more than 200 characters"
    }),
  });
  const formValidation = () => {
    const result = schema.validate(address);
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const fromErrors = formValidation();
    if (fromErrors) {
      setErrors({ ...fromErrors });
    } else {
      setErrors({});
      submitAddress({
        ...address,
      });
    }
  };
  const submitAddress = (address) => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .put(`${apiBaseUrl}/api/user/address`, address, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          setAddress({});
          dispatch(addAddress(res.data.data.address));
          handleModalClose();
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setAddressError(true);
          setAddressErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setAddressError(false);
            setAddressErrorMessage("");
          }, 5000);
        }

        if (err.response.data.meta.statusCode === 401) {
          setAddressError(true);
          setAddressErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setAddressError(false);
            setAddressErrorMessage("");
          }, 5000);
        }
      });
  };
  return (
    <div
      className="modal"
      id="add-address-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="AddAddressModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="add-address-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">Add New Address!</div>
                </div>
                <form onSubmit={handleOnSubmit}>
                  {addressError && (
                    <div
                      className="error-text text-center alert-danger"
                      role="alert"
                    >
                      {addressErrorMessage}
                    </div>
                  )}
                  <div className="d-flex justify-content-between   mt-4">
                    <div className="mt-2 w-100 mr-4">
                      <div className="field-heading">Province</div>
                      <div className="form-group">
                        <select
                          className="custom-select form-control h-100"
                          id="inputGroupSelect01"
                          value={address.province || ""}
                          name="province"
                          onChange={handleInputOnChange}
                        >
                          <option value="">Select Province</option>
                          <option value="Sindh">Sindh</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Balochistan">Balochistan</option>
                          <option value="Islamabad">
                            Islambad Capital Territory
                          </option>
                          <option value="Khyber-Pakhtunkhwa">
                            Khyber Pakhtunkhwa
                          </option>
                          <option value="Gilgit-Baltistan">
                            Gilgit Baltistan
                          </option>
                          <option value="Azad-Kashmir">Azad Kashmir</option>
                        </select>
                        {errors.province && (
                          <div className="error-text">
                            {errors.province}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-2 w-100 ml-4">
                      <div className="field-heading">City</div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          placeholder="City"
                          value={address.city || ""}
                          onChange={handleInputOnChange}
                        />
                        {errors.city && (
                          <div className="error-text">{errors.city}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="mt-2 w-100 mr-4">
                      <div className="field-heading">Postal/Zip Code</div>
                      <div className="form-group">
                        <input
                          type="number"
                          name="postalOrZip"
                          className="form-control"
                          placeholder="Postal/Zip Code"
                          value={address.postalOrZip || ""}
                          onChange={handleInputOnChange}
                        />
                        {errors.postalOrZip && (
                          <div className="error-text">
                           {errors.postalOrZip}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-2 w-100 ml-4">
                      <div className="field-heading">Landmark</div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="landmark"
                          className="form-control"
                          placeholder="Landmark (i.e.,) Near Train Station"
                          value={address.landmark || ""}
                          onChange={handleInputOnChange}
                        />
                        {errors.landmark && (
                          <div className="error-text">
                           {errors.landmark}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2 pb-5">
                    <div className="mt-2 w-50 pr-4">
                      <div className="field-heading">Complete Address</div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="completeAddress"
                          className="form-control"
                          placeholder="House Number, Building , Area, Street"
                          value={address.completeAddress || ""}
                          onChange={handleInputOnChange}
                        />
                        {errors.completeAddress && (
                          <div className="error-text">
                            {errors.completeAddress}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 w-100">
                    <button type="submit" className="btn-next w-100">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAddressModal;
