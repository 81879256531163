import React, { useEffect, useState } from "react";
import CartIcon from "../../Assets/images/icons/icon-cart.svg";
import StarFilled from "../../Assets/images/icons/icon-star-filled.svg";
import LoadMore from "../../Assets/images/icons/icon-load-more.svg";
import FavHeart from "../FavHeart/FavHeart";
import axios from "axios";
import { Link } from "react-router-dom";
import SmallLoader from "../Loader/SmallLoader";
import { apiBaseUrl, imgBaseUrl } from "../config/Config";

const ProductPageRelatedProducts = ({ id, keyWords }) => {
  let ProductKeyWords = JSON.parse(keyWords);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [noRelatedProducts, setNoRelatedProducts] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${apiBaseUrl}/api/listing/detail-related-product/${id}/${ProductKeyWords}?page=${page}`
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.meta.statusCode === 200) {
          setRelatedProducts([...relatedProducts, ...res.data.data.listing]);
        }
        if (res.data.meta.statusCode === 206) {
          setNoRelatedProducts(true);
        }
        if (res.data.meta.page === page) {
          setIsLoad(false);
        } else {
          setPage(res.data.meta.page);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);
  return (
    <>
      {isLoading ? (
        <>
          <SmallLoader />
        </>
      ) : (
        <>
          {noRelatedProducts === true || relatedProducts.length === 0 ? (
            <></>
          ) : (
            <>
              <div className="mt-5 mb-5">
                <div className="mb-3 d-flex justify-content-between">
                  <div className="heading-section">Related Products</div>
                </div>

                <div
                  id="product-card"
                  className="d-flex flex-wrap justify-content-start"
                >
                  {relatedProducts?.map((item, index) => {
                    return (
                      <div key={index} className="products-card h-100">
                        <div className="position-relative">
                          <div className="d-flex align-item-center p-1 w-100 justify-content-between position-absolute">
                            {item.isSale === true ? (
                              <>
                                <div className="products-card-percentage">
                                  <div className="percentage">
                                    {item.salePercent} %
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div></div>
                              </>
                            )}
                            <div
                              role="button"
                              className="products-card-fav-icon"
                            >
                              <div className="products-card-fav-icon-img position-absolute">
                                <FavHeart key={item._id} itemId={item._id} />
                              </div>
                            </div>
                          </div>
                          <Link to={`/product/${item._id}`}>
                            <img
                              className="products-card-image"
                              src={`${imgBaseUrl}/listing/${item.images[0]}`}
                              alt="product"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                        <h1>{item.title}</h1>
                        <div className="products-card-price d-flex">
                          <h5 className="card-price">
                            RS.{" "}
                            {item.price - (item.price * item.salePercent) / 100}
                          </h5>
                          {item.isSale === true && (
                            <h5 className="card-discount-price ml-2">
                              {item.price}
                            </h5>
                          )}
                        </div>
                        <div className="d-flex align-item-center justify-content-between mt-2">
                          <div className="d-flex ">
                            <img
                              className="star-icon"
                              src={StarFilled}
                              alt="StarFilled"
                            />
                            <div className="star-count ml-2">
                              {item.averageRating}
                            </div>
                            <div className="products-card-sold ml-2">
                              ({item.sold} Sold)
                            </div>
                          </div>
                          <div className="products-card-sold">
                            <Link to={`/product/${item._id}`}>
                              <div
                                role="button"
                                className="products-card-cart-icon p-0 m-0"
                              >
                                <img
                                  className="products-card-cart-icon-img position-absolute"
                                  src={CartIcon}
                                  alt="CartIcon"
                                />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {isLoad ? (
                  <>
                    <div
                      className="d-flex justify-content-end heading-more mt-3"
                      role="button"
                      onClick={() => setLoadData(!loadData)}
                    >
                      Load More{" "}
                      <img className="ml-2" src={LoadMore} alt="LoadMore" />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProductPageRelatedProducts;
