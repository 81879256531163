import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../Components/Header/Header";
import UserAvatar from "../Assets/images/avatar.svg";
import StarFilled from "../Assets/images/icons/icon-star-filled.svg";
import StarEmpty from "../Assets/images/icons/icon-star-empty.svg";
import LoadMore from "../Assets/images/icons/icon-load-more.svg";
import { apiBaseUrl, imgBaseUrl } from "../Components/config/Config";
import SmallCardSkeleton from "../Components/Skeleton/SmallCardSkeleton";
import { useSelector } from "react-redux";

const NearStores = () => {
  const {userInfo} = useSelector((state) => state.user);
  const [listing, setLising] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [loadData, setLoadData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  let limit = 10;

  useEffect(() => {
    const geometry =   { type:"Point",  coordinates:userInfo?.location}
    axios
      .get(`${apiBaseUrl}/api/store/nearest-store?page=${page}&limit=${limit}}&geometry=${JSON.stringify(geometry)}`)
      .then((res) => {
        if(res.data.data.meta.status){
        setLising([...listing, ...res.data.data.store]);
        setIsLoading(false);
        }
        if (res.data.meta.page === page) {
          setIsLoad(false);
        } else {
          setPage(res.data.meta.page);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);
  return (
    <>
      <Header />

          {isLoading ? (
            <>
              {
        <div className="scroll_on_bg_card">
          <div
              id="product-card" 
              className="container d-flex x-scrolls justify-content-start flex-wrap "
            >
              {[0,1,2,3,4,6,7,8,9,10,11,12,13,14,15].map((index)=>(<div key={index} className="products-card h-100 mb-3"><SmallCardSkeleton /></div>)
              )}
            </div>
        </div>
      }
            </>
          ) : (
            <>
              <>
                <section className="mt-5">
                  <div className="container mb-5">
                    <div
                      id="store-card"
                      className="d-flex flex-wrap justify-content-start"
                    >
                      {listing.map((store, index) => {
                        return (
                          <div key={index} className="store-card h-100">
                            <Link to={`/store/${store._id}`}>
                              {store.logo === null ? (
                                <>
                                  <img
                                    className="store-card-image"
                                    src={UserAvatar}
                                    alt="product"
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    className="store-card-image"
                                    src={`${imgBaseUrl}/profile/small_${store.logo}`}
                                    alt="product"
                                    loading="lazy"
                                  />
                                </>
                              )}
                            </Link>
                            <h1 className="text-center brand-name">
                              {JSON.parse(store.keyWords)[0]}
                            </h1>
                            <h5 className="text-center store-name">
                              {store.storeName}
                            </h5>
                            <div className="text-center">
                              {store.averageRating ? (
                                <>
                                  {store.averageRating >= 1 ? (
                                    <img
                                      className="star-icon"
                                      src={StarFilled}
                                      alt="StarFilled"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        className="star-icon"
                                        src={StarEmpty}
                                        alt="StarEmpty"
                                      />
                                    </>
                                  )}
                                  {store.averageRating >= 2 ? (
                                    <img
                                      className="star-icon"
                                      src={StarFilled}
                                      alt="StarFilled"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        className="star-icon"
                                        src={StarEmpty}
                                        alt="StarEmpty"
                                      />
                                    </>
                                  )}
                                  {store.averageRating >= 3 ? (
                                    <img
                                      className="star-icon"
                                      src={StarFilled}
                                      alt="StarFilled"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        className="star-icon"
                                        src={StarEmpty}
                                        alt="StarEmpty"
                                      />
                                    </>
                                  )}
                                  {store.averageRating >= 5 ? (
                                    <img
                                      className="star-icon"
                                      src={StarFilled}
                                      alt="StarFilled"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        className="star-icon"
                                        src={StarEmpty}
                                        alt="StarEmpty"
                                      />
                                    </>
                                  )}
                                  {store.averageRating === 5 ? (
                                    <img
                                      className="star-icon"
                                      src={StarFilled}
                                      alt="StarFilled"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        className="star-icon"
                                        src={StarEmpty}
                                        alt="StarEmpty"
                                      />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <img
                                    className="star-icon"
                                    src={StarEmpty}
                                    alt="StarFilled"
                                  />
                                  <img
                                    className="star-icon"
                                    src={StarEmpty}
                                    alt="StarFilled"
                                  />
                                  <img
                                    className="star-icon"
                                    src={StarEmpty}
                                    alt="StarFilled"
                                  />
                                  <img
                                    className="star-icon"
                                    src={StarEmpty}
                                    alt="StarFilled"
                                  />
                                  <img
                                    className="star-icon"
                                    src={StarEmpty}
                                    alt="StarFilled"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {isLoad ? (
                      <>
                        <div
                          className="d-flex justify-content-center heading-more mt-3"
                          role="button"
                          onClick={() => setLoadData(!loadData)}
                        >
                          Load More{" "}
                          <img className="ml-2" src={LoadMore} alt="LoadMore" />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              </>
            </>
          )}
        </>
  );
};

export default NearStores;
