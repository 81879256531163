import React, { useState } from "react";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import LoginImage from "../Assets/images/login-img.svg";
import Logo from "../Assets/images/logo-white.svg";
import IconWave from "../Assets/images/icons/icon-wave.svg";
import { useNavigate } from "react-router-dom";
import { clearOtpStorage } from "../shared/clearOtpStorage";
import axios from "axios";
import { apiBaseUrl } from "../Components/config/Config";

function RegisterOtp() {
  let navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const handleChange = (OTP) => {
    setOTP(OTP);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    otpCall(OTP);
  };
  const otpCall = (otp) => {
    const otpParse = Number(otp);
    const config = {
      headers: {
        "x-auth-otp": sessionStorage.getItem("token"),
      },
    };
    axios
      .post(`${apiBaseUrl}/api/user/verify-signup-user-otp`, { otp: otpParse }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          clearOtpStorage();
          navigate("/login");
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }

        if (err.response.data.meta.statusCode === 401) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 404) {
          setOtpError(true);
          setOtpErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setOtpError(false);
            setOtpErrorMessage("");
          }, 5000);
        }
      });
  };

  const BackToLogin = () => {
    clearOtpStorage()
    navigate("/register");
  };

  return (
    <div id="forgetpassword">
<div className="girl-image">
<div className="login-container">
        <Link to="/">
          <div className="header-logo">
            <img src={Logo} alt="Logo" />
          </div>
        </Link>
        <div className="d-flex full-sec flex-xl-row flex-lg-row flex-md-row flex-sm-column  justify-content-between">
          <div className="left-sec">
            <div className="heading-login">Amazing day.</div>
            <div className="description-login mt-3">
              Maybe some text here will help me see it better. Oh God. Oke,
              let’s do it then.
            </div>
          </div>
          <div className="log">
                                   <img
              className="login-image"
              src={LoginImage}
              alt="LoginImage"
            />
          </div>
          <div className="mb-5 right-sec form-wid">
            <div className="login-form mr-5">
              <img src={IconWave} alt="IconCancel" />
              <div className="mt-2">
                <div className="heading-form">Enter OTP!</div>
                <div className="text-para mt-2">
                  Please Enter OTP Send to Email or Phone Number
                </div>
                {otpError && (
                  <div
                    className="error-text text-center alert-danger"
                    role="alert"
                  >
                    {otpErrorMessage}
                  </div>
                )}
              </div>
              <form onSubmit={handleOnSubmit}>
                <div className="py-2 mt-2">
                  <div className="field-heading">OTP</div>
                  <div className="mt-1">
                    <OtpInput
                      onChange={handleChange}
                      value={OTP}
                      inputStyle="otpinputStyle"
                      numInputs={6}
                      separator={<span></span>}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-2 mb-3">
                  <div className="otp-resend">Didn't receive OTP</div>
                  <div role="button" className="otp-resend-link">
                    Resend
                  </div>
                </div>
                <div className="py-2">
                  <button type="submit" className="btn-sign-in w-100">
                    Verify
                  </button>
                </div>
              </form>
              <div className="mt-5 ">
                <div className="login-footer mx-3">
                  Back to{" "}
                  <span
                    role="button"
                    className="login-footer-span"
                    onClick={BackToLogin}
                  >
                    Register
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
    </div>
  );
}

export default RegisterOtp;
