import React from "react";
import IconCancel from "../../Assets/images/icons/icon-cancel.svg";

const ColorAndSizeSelectionModal = () => {
  return (
    <div
      className="modal"
      id="color-and-size-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="color-and-size-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body m-0 p-0">
              <div className="body-modal">
                <div className="py-2 mt-3">
                  <div className="field-heading">
                    Please Select Color and Quantity
                  </div>
                </div>
                <div className="py-2">
                  <button
                    type="button"
                    className="btn-sign-in w-100"
                    data-dismiss="modal"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorAndSizeSelectionModal;
