import React, { useState } from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import ItemPreview from "../../../Assets/images/icons/dashboard-icons/item-preview.svg";
import CancelPreview from "../../../Assets/images/icons/dashboard-icons/icon-cancel-preview.svg";

import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import $ from "jquery";
import { apiBaseUrl, imgBaseUrl } from "../../config/Config";

const MAX_LENGTH = 5;

const OrderModalReview = ({
  ordervalue,
  orderId,
  itemId,
  storeId,
  index,
  setOrdervalue,
}) => {
  const { userToken } = useSelector((state) => state.user);

  const [selectedFile, setSelectedFile] = useState([]);
  const [previewFile, setPreviewFile] = useState([]);

  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState("");

  const handleInputOnChange = (e) => {
    setRating(e.target.value);
  };

  const onSelectFile = (e) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      if (fileArray.length > MAX_LENGTH) {
        e.preventDefault();
        alert(`Cannot upload files more than ${MAX_LENGTH}`);
        return;
      }
      setSelectedFile(e.target.files);
      setPreviewFile((prevImages) => prevImages.concat(fileArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };
  const deleteHandler = (e, image) => {
    e.preventDefault();
    setPreviewFile(previewFile.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  };
  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <>
          <div className="position-relative">
            <div
              role="button"
              className="mr-1 d-flex justify-content-end"
              onClick={(e) => deleteHandler(e, photo)}
            >
              <img
                className="position-absolute"
                src={CancelPreview}
                alt="CancelPreview"
              />
            </div>
            <img
              className="image-proof mr-3"
              src={photo}
              key={photo}
              alt="itemPreview"
            />
          </div>
        </>
      );
    });
  };

  const handleModalClose = () => {
    $("#reviewordermodal").modal("hide");
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-auth-token": userToken,
      },
    };
    const formData = new FormData();
    formData.append("itemId", itemId);
    formData.append("storeId", storeId);
    formData.append("message", comment);
    formData.append("ratingNumber", rating);
    for (const key of Object.keys(selectedFile)) {
      formData.append("images", selectedFile[key]);
    }
    formData.append("orderId", orderId);
    axios
      .post(`${apiBaseUrl}/api/reviews/place-review`, formData, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          let filtereData = ordervalue.map((value) => {
            if (value._id !== res.data.data.order._id) {
              return value;
            } else {
              return res.data.data.order;
            }
          });
          setOrdervalue(filtereData);
          handleModalClose();
        }
      })
      .catch((err) => {
        console.log("error >>>", err.response);
      });
  };

  return (
    <div
      className="modal"
      id="reviewordermodal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="reviewordermodal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <div className="heading-modal">Product Review!</div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body pt-0 review-order-modal-body">
              <div className="order-info">
                Order # {ordervalue[index].orderId}
              </div>
              <div className="mt-3 order-info">
                Placed on{" "}
                {moment(ordervalue[index].createdAt).format("DD  MMMM  YYYY")}{" "}
                {moment(ordervalue[index].createdAt).format("h : mm : ss a")}
              </div>
              <div className="d-flex mt-3 justify-content-start">
                <img
                  className="image-item"
                  src={`${imgBaseUrl}/listing/small_${ordervalue[index].itemProperties.image}`}
                  alt="item"
                  loading="lazy"
                />
                <div className="ml-4 d-flex flex-column w-75 flex-wrap justify-content-between">
                  <div className="item-title">
                    {ordervalue[index].itemProperties.title}
                  </div>
                  <div className="d-flex">
                    <div className="item-price">
                      RS.{" "}
                      {ordervalue[index].itemProperties.price -
                        (ordervalue[index].itemProperties.price *
                          ordervalue[index].itemProperties.salePercent) /
                          100}
                    </div>
                    {ordervalue[index].itemProperties.isSale === true && (
                      <h5 className="item-discount-price ml-2">
                        {ordervalue[index].itemProperties.price}
                      </h5>
                    )}
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="">
                      <div className="item-detail-heading">Colors</div>
                      <div className="d-flex flex-wrap mt-1">
                        <div
                          className="color-index mr-2 mb-2"
                          style={{
                            background: `${ordervalue[index].itemProperties.color}`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="">
                      <div className="item-detail-heading">Size</div>
                      <div className="d-flex flex-wrap mt-1">
                        <div className="size-index mr-2 mb-2 text-center">
                          <div className="size-index-text mt-1">
                            {ordervalue[index].itemProperties.type}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="item-detail-heading">Quantity</div>
                      <div className="d-flex mt-2">
                        <div className="item-quantity">
                          {ordervalue[index].itemProperties.quantity}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form onSubmit={handleOnSubmit}>
                <div className="mt-2">
                  <div className="sub-heading">Images</div>
                  <div className="mt-2 d-flex flex-wrap justify-content-start">
                    {selectedFile.length > 0 ? (
                      <>{renderPhotos(previewFile)}</>
                    ) : (
                      <>
                        <img
                          className="image-proof mr-3"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="image-proof mr-3"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="image-proof mr-3"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="image-proof mr-3"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                        <img
                          className="image-proof mr-3"
                          src={ItemPreview}
                          alt="ItemPreview"
                        />
                      </>
                    )}
                  </div>
                  <label
                    htmlFor="uploadCommentImages"
                    className="w-100 d-flex justify-content-end"
                  >
                    <div role="button">
                      <div className="btn-select text-center pr-3 pl-3">
                        Select File
                      </div>
                      <input
                        type="file"
                        id="uploadCommentImages"
                        multiple
                        hidden
                        accept="image/png, image/jpeg"
                        onChange={onSelectFile}
                      />
                    </div>
                  </label>
                </div>
                <div className="field-heading">Review</div>
                <div className="form-group">
                  <select
                    className="custom-select form-control"
                    id="inputGroupSelect01"
                    value={rating}
                    name="rating"
                    onChange={handleInputOnChange}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
                </div>
                <div className="form-group">
                  <div className="field-heading">Comment</div>
                  <textarea
                    className="w-100"
                    type="text"
                    maxLength={200}
                    style={{ minHeight: "150px" }}
                    placeholder="Comment"
                    name="comment"
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                  />
                </div>
                <div className="mt-1 mb-3">
                  <button type="submit" className="btn-sign-in w-100">
                    Post
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderModalReview;
