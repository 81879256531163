import "./Assets/vendors/fontawesome-free/css/all.min.css";
import "./Assets/scss/sb-admin-2.scss";
import "bootstrap";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Home from "./Pages/Home";
import ProductPage from "./Pages/ProductPage";
import OrderPlace from "./Pages/Buyer/OrderPlace";
import Cart from "./Pages/Cart";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./Components/protectedroute/ProtectedRoute";
import ForgetPassword from "./Pages/ForgetPassword";
import ResetPassword from "./Pages/ResetPassword";
import MyFavourite from "./Pages/Buyer/MyFavourite";
import AboutStore from "./Pages/AboutStore";
import PageLayoutUser from "./Components/DashboardComponents/BuyerDashboard/PageLayoutUser";
import ProfileSetting from "./Pages/Buyer/ProfileSetting";
import Unauthorized from "./Pages/Unauthorized";
import PublicRoute from "./Components/publicroute/PublicRoute";
import TopStoresPage from "./Pages/TopStoresPage";
import Otp from "./Pages/Otp";
import DashboardBuyer from "./Pages/Buyer/DashboardBuyer";
import AddressBook from "./Pages/Buyer/AddressBook";
import ProductsOnSale from "./Pages/ProductsOnSale";
import TopSellingProducts from "./Pages/TopSellingProducts";
import RegisterOtp from "./Pages/RegisterOtp";
import ForgetOtp from "./Pages/ForgetOtp";
import { useSelector } from "react-redux";
import socketConn from "./Components/Chat/socket"
import { useState, useEffect } from "react";
import SearchProduct from "./Pages/Buyer/SearchProduct";
import NearStores from "./Pages/NearStores";

function App() {
  const { userToken } = useSelector((state) => state.user);
  const [socket, setSocket] = useState(()=>socketConn(userToken))
  useEffect(()=>{
    if(userToken){
      setSocket(()=>socketConn(userToken));
      window.globalSocket = socket;
      socket?.connect()
    }
  },[userToken])
  
  return (
    <div>
      <ToastContainer />
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/aboutstore" element={<AboutStore />} />
            <Route  path="/search" element={<SearchProduct/>} />

            <Route exact path="store/:id" element={<AboutStore />} />
            {/* product detail with particular id */}
            <Route exact path="product/:id" element={<ProductPage />} />

            <Route
              exact
              path="/topsellingproducts"
              element={<TopSellingProducts/>}
            />
            <Route exact path="/productsonsale" element={<ProductsOnSale />} />
            <Route exact path="/nearStore" element={<NearStores/>} />
            <Route exact path="/topstores" element={<TopStoresPage />} />
             <Route exect path="/register" element={<Register />} />

            <Route
              exect
              path="/register-otp"
              element={<PublicRoute component={RegisterOtp} />}
            />

            <Route exact path="/login" element={<Login />} />
            <Route
              exect
              path="/otp"
              element={<PublicRoute component={Otp} />}
            />

            <Route exact path="/forgot-password" element={<ForgetPassword />} />
            <Route
              exect
              path="/forgot-otp"
              element={<PublicRoute component={ForgetOtp} />}
            />
            <Route
              exect
              path="/reset-password"
              element={<PublicRoute component={ResetPassword} />}
            />

            <Route
              exect
              path="/dashboard-buyer"
              element={
                <PageLayoutUser>
                  <ProtectedRoute component={DashboardBuyer} />
                </PageLayoutUser>
              }
            />
            <Route
              exact
              path="/user-orders"
              element={
                <PageLayoutUser>
                  <ProtectedRoute component={OrderPlace} />
                </PageLayoutUser>
              }
            />
            <Route
              exact
              path="/user-address"
              element={
                <PageLayoutUser>
                  <ProtectedRoute component={AddressBook} />
                </PageLayoutUser>
              }
            />
            <Route
              exact
              path="/user-favourite"
              element={
                <PageLayoutUser>
                  <ProtectedRoute component={MyFavourite} />
                </PageLayoutUser>
              }
            />
            <Route
              exact
              path="/user-profile-setting"
              element={
                <PageLayoutUser>
                  <ProtectedRoute component={ProfileSetting} />
                </PageLayoutUser>
              }
            />
            {/* cart */}
            <Route exact path="/cart" element={<Cart />} />

            <Route
              exect
              path="/page-not-found"
              element={
                <PageLayoutUser>
                  <ProtectedRoute component={Unauthorized} />
                </PageLayoutUser>
              }
            />

            <Route
              path="*"
              element={
                <PageLayoutUser>
                  <ProtectedRoute component={Unauthorized} />
                </PageLayoutUser>
              }
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
