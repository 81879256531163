import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import { useParams, useLocation, Link, useNavigate  } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import FavHeart from "../Components/FavHeart/FavHeart";
import Loader from "../Components/Loader/Loader";
import rightArrow from "../Assets/images/icons/arrow-circle-right.svg";
import leftArrow from "../Assets/images/icons/arrow-circle-left.svg";
import StarFilled from "../Assets/images/icons/icon-star-filled.svg";
import StarEmpty from "../Assets/images/icons/icon-star-empty.svg";
import IconMinus from "../Assets/images/icons/icon-minus.svg";
import IconPlus from "../Assets/images/icons/icon-plus.svg";
import IconShare from "../Assets/images/icons/icon-share.svg";
import IconChat from "../Assets/images/icons/icon-chat.svg";
import { apiBaseUrl, imgBaseUrl } from "../Components/config/Config";
import IconLocation from "../Assets/images/icons/icon-location-card.svg";
import IconLocationDanger from "../Assets/images/icons/dashboard-icons/icon-location-danger.svg";
import DetailScreenSkeleton from "../Components/Skeleton/DetailScreenSkeleton";
import StoreProfile from "../Assets/images/nike-logo.png";
import LoginModal from "../Components/LoginModal/LoginModal";
import ColorAndSizeSelectionModal from "../Components/ColorAndSizeSelectionModal/ColorAndSizeSelectionModal";
import ProductPageTopSellingProducts from "../Components/ProductPageComponents/ProductPageTopSellingProducts";
import ProductPageRelatedProducts from "../Components/ProductPageComponents/ProductPageRelatedProducts";
import ProductReviews from "../Components/ProductPageComponents/ProductReviews";
import ShareOptions from "../Components/ShareOptions/ShareOptions";
import ChatButton from "../Components/Chat/ChatButton";
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function ProductPage() {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const storeId = queryParams.get('storeId');
  const chatStatus = queryParams.get('chatStatus');

  let productUrl = window.location.pathname;

  let navigate = useNavigate();
  const { userToken, userInfo } = useSelector((state) => state.user);
  const [item, setItem] = useState({});
  const [imgIndex, setImageIndex] = useState(0);
  const [colorIndex, setColorIndex] = useState(0);
  const [qtyLimit, setQtyLimit] = useState(0);
  const [subPropertyType, setSubPropertyType] = useState("");
  const [subPropertyColor, setSubPropertyColor] = useState("");
  const [num, setNum] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const incNum = () => {
    if (num < qtyLimit) {
      setNum(Number(num) + 1);
    }
  };
  const decNum = () => {
    if (num > 1) {
      setNum(num - 1);
    }
  };

  useEffect(() => {
    axios.get(`${apiBaseUrl}/api/listing/product/${id}`).then((res) => {
      if(res.data.meta.status){
        setItem(res.data.data);
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleTab = (index) => {
    setImageIndex(index);
  };
  const handleColorIndex = (index, color) => {
    setColorIndex(index);
    setSubPropertyColor(color);
    setNum(1);
  };
  const handleSubPropertySize = (subQuantity, subType) => {
    setQtyLimit(subQuantity);
    setSubPropertyType(subType);
    setNum(1);
  };
  let config = {
    headers: {
      "x-auth-token": userToken,
    },
  };
  const addItemToCart = () => {
    let cartItem = {
      color: subPropertyColor,
      type: subPropertyType,
      quantity: num,
    };

    axios
      .post(`${apiBaseUrl}/api/cart/add-to-cart/${id}`, cartItem, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          navigate("/cart");
        }
      })
      .catch((err) => {
        console.log("error >>>", err.response);
      });
  };

  const NavigateToAddressBook = () => {
    navigate("/user-address");
  };

  const OnClickShare = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigateToChat = (storeId, chatStatus) => {
    const initiateConversation = { storeId: storeId, itemId: id }
    const apiUrl = `${apiBaseUrl}/api/conversion/add-conversation`;
    axios.post(apiUrl, initiateConversation, config).then((res) => {
      if(res.data.meta.status){
        const url = `/product/${id}?storeId=${storeId}&chatStatus=${chatStatus}`;
        navigate(url);
      }
    });
  }
 
  return (
    <>
      {isLoading ? (
        <div className="bg-white">
          <Header />
          <div id="productdetail">
            <div className="details-app">
              <DetailScreenSkeleton />
            </div> 
          </div>
        </div>
      ) : (
        <>
              <div className="bg-white">
                <Header />
                <div id="productdetail">
                  <section>
                  <div>
                      <div className="justify-content-end">
                        <ChatButton productId={id} storeId={storeId} chatStatus={chatStatus}/>
                      </div>
                    </div>
                    <div className="container">
                      <div className="details-app">
                        {item.listing ? (
                          <div className="details d-flex flex-lg-row flex-md-row flex-sm-column justify-content-start">
                            <div className="d-flex flex-column mr-3">
                              <div className="big-img border">
                                {item.listing && item?.listing?.images ? (
                                  <img
                                    className="img-tag"
                                    src={`${imgBaseUrl}/listing/medium_${item.listing.images[imgIndex]}`}
                                    alt="tag-pic"
                                    loading="lazy"
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="mt-3">
                                <div className="thumb d-flex ">
                                  <img src={leftArrow} alt="leftArrow" />
                                  {item && item?.listing?.images ? (
                                    item.listing.images.map((img, index) => (
                                      <img
                                        key={index}
                                        className={
                                          imgIndex === index
                                            ? "active-thumb"
                                            : "thumb-img ml-2"
                                        }
                                        src={`${imgBaseUrl}/listing/small_${item.listing.images[index]}`}
                                        alt="thumb-img"
                                        onClick={() => handleTab(index)}
                                        loading="lazy"
                                      />
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                  <img src={rightArrow} alt="leftArrow" />
                                </div>
                              </div>
                            </div>

                            <div className="d-flex flex-column w-100">
                              <div className="">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>

                                    <h2 className="product-title p-0">
                                    <Helmet>
                                      <title> {item.listing.title}</title>
                                    <meta name="description" content="my cartsbay website" />
                                   < meta name="keywords" content="bags, flowers, mobiles" />
                                    <link rel="canonical"href = "http://localhost:3001/"></link>
                                    <meta name="robots" content="index, follow" />
                                      <meta name="author" content="cartsbay.com" />
                                      <meta name="publisher" content="cartsbay.com" />
                                    </Helmet>
                                    {item.listing.title}
                                  </h2></div>
                                  <div className="d-flex justify-content-end w-25">
                                    <div className="mr-5 iconsize" >
                                      <img
                                        role="button"
                                        src={IconShare}
                                        alt="IconShare"
                                        onClick={OnClickShare}
                                      />
                                    </div>
                                    {isMenuOpen && (
                                      <>
                                        <ShareOptions
                                          setIsMenuOpen={setIsMenuOpen}
                                          productUrl={productUrl}
                                          item={item.listing}
                                        />
                                      </>
                                    )}
                                    <div className="iconsize">
                                      <FavHeart
                                        key={item.listing._id}
                                        itemId={item.listing._id}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-start align-item-center">
                                  {item.listing.averageRating ? (
                                    <>
                                      {item.listing.averageRating >= 1 ? (
                                        <img
                                          className="mr-1"
                                          src={StarFilled}
                                          alt="StarFilled"
                                        />
                                      ) : (
                                        <>
                                          <img
                                            className="mr-1"
                                            src={StarEmpty}
                                            alt="StarEmpty"
                                          />
                                        </>
                                      )}
                                      {item.listing.averageRating >= 2 ? (
                                        <img
                                          className="mr-1"
                                          src={StarFilled}
                                          alt="StarFilled"
                                        />
                                      ) : (
                                        <>
                                          <img
                                            className="mr-1"
                                            src={StarEmpty}
                                            alt="StarEmpty"
                                          />
                                        </>
                                      )}
                                      {item.listing.averageRating >= 3 ? (
                                        <img
                                          className="mr-1"
                                          src={StarFilled}
                                          alt="StarFilled"
                                        />
                                      ) : (
                                        <>
                                          <img
                                            className="mr-1"
                                            src={StarEmpty}
                                            alt="StarEmpty"
                                          />
                                        </>
                                      )}
                                      {item.listing.averageRating >= 5 ? (
                                        <img
                                          className="mr-1"
                                          src={StarFilled}
                                          alt="StarFilled"
                                        />
                                      ) : (
                                        <>
                                          <img
                                            className="mr-1"
                                            src={StarEmpty}
                                            alt="StarEmpty"
                                          />
                                        </>
                                      )}
                                      {item.listing.averageRating === 5 ? (
                                        <img
                                          className="mr-1"
                                          src={StarFilled}
                                          alt="StarFilled"
                                        />
                                      ) : (
                                        <>
                                          <img
                                            className="mr-1"
                                            src={StarEmpty}
                                            alt="StarEmpty"
                                          />
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="ml-2 mt-1 sold-items">
                                    ({item.listing.sold} Sold)
                                  </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center ">
                                  <span className="p-0 ">
                                    <span className="text-store">Store : </span>
                                    <Link to={`/store/${item.storeInfo._id}`}>
                                      <span className="link brand-link">
                                        {item.storeInfo.storeName}
                                      </span>
                                    </Link>
                                  </span>
                                </div>
                                <div className="mt-2 d-flex justify-content-between align-items-start  ">
                                  <span className="product-price w-50">
                                    RS.{" "}
                                    {Math.ceil(item.listing.price -
                                      (item.listing.price *
                                        item.listing.salePercent) /
                                      100)}
                                  </span>
                                  {item.listing.isSale === true && (
                                    <h5 className="product-discount-price">
                                      {item.listing.price}
                                    </h5>
                                  )}
                                </div>
                                <div className="mt-2 d-flex justify-content-between align-items-center ">
                                  <span className="p-0 text-store">Colors</span>
                                </div>
                                <div className="colors">
                                  {item.listing && item?.listing?.properties ? (
                                    JSON.parse(item.listing.properties).map(
                                      (color, index) => (
                                        <button
                                          key={index}
                                          className={
                                            colorIndex === index
                                              ? "active-color"
                                              : "colors-button"
                                          }
                                          style={{
                                            background: `${color.color}`,
                                          }}
                                          onClick={() =>
                                            handleColorIndex(index, color.color)
                                          }
                                        ></button>
                                      )
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div>
                                  {colorIndex >= 0 ? (
                                    <>
                                      <div className="mt-1 d-flex justify-content-between align-items-center ">
                                        <span className="text-store">
                                          Sizes
                                        </span>
                                      </div>
                                      <div className="">
                                        {JSON.parse(
                                          item.listing.properties
                                        ).map((properties, indexI) => {
                                          return (
                                            <div
                                              key={indexI}
                                              className="d-flex flex-row"
                                            >
                                              {colorIndex === indexI ? (
                                                <>
                                                  {properties[
                                                    "subProperty"
                                                  ].map(
                                                    (subProperty, indexJ) => {
                                                      return (
                                                        <div
                                                          className="mr-2"
                                                          key={indexJ}
                                                        >
                                                          <button
                                                            className={
                                                              subPropertyType ===
                                                                subProperty.type
                                                                ? "btn-size-active"
                                                                : "btn-size"
                                                            }
                                                            type="button"
                                                            onClick={() =>
                                                              handleSubPropertySize(
                                                                subProperty.quantity,
                                                                subProperty.type
                                                              )
                                                            }
                                                          >
                                                            <div className="text-sizes">
                                                              {subProperty.type}
                                                            </div>
                                                          </button>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </>
                                  ) : (
                                    <>please select color first</>
                                  )}
                                </div>
                                <div className="">
                                  <div className="mt-1 d-flex justify-content-between align-items-center ">
                                    <span className="text-store">Quantity <span> {qtyLimit}</span></span>
                                  </div>
                                  <div className="row justify-content-between text-left">
                                    <div className="col-sm-6 col-md-12">
                                      <div className="input-group">
                                        <div className="mr-2 input-group-prepend">
                                          <img
                                            role="button"
                                            min="1"
                                            max={qtyLimit}
                                            onClick={decNum}
                                            src={IconMinus}
                                            alt="IconMinus"
                                          />
                                        </div>
                                        <div className="quantity ml-4 mr-4">
                                          {num}
                                        </div>
                                        <div className="ml-2 input-group-prepend">
                                          <img
                                            role="button"
                                            min="1"
                                            onClick={incNum}
                                            src={IconPlus}
                                            alt="IconPlus"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row justify-content-between text-left">
                                    <div className="col-sm-6 col-md-12">
                                      <p className="paragraph text-justify">
                                        {qtyLimit === 0 ? (
                                          <></>
                                        ) : (
                                          <>
                                            <p className="text-store mt-1">
                                              Select quantity upto{" "}
                                              <span className="text-store">
                                                {qtyLimit}
                                              </span>
                                            </p>
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex mt-1 mr-2">
                                  {qtyLimit > 0 ?(
                                  <div className="col-6 mr-2 p-0">
                                    <button
                                      onClick={() => {
                                        // handleAddItemToCart(item);
                                        alert(
                                          "Navigate to check out with details"
                                        );
                                      }}
                                      className="btn btn-block text-white mt-auto btn-buy-now"
                                      type="button"
                                    >
                                      Buy Now
                                    </button>
                                  </div>):(<></>)}
                                  {qtyLimit >0 ?(
                                  <div className="col-6 m-0 p-0">
                                    {userToken ? (
                                      <>
                                        {subPropertyType? (
                                          <>
                                            <button
                                              onClick={addItemToCart}
                                              className="btn btn-block text-white mt-auto btn-cart"
                                              type="button"
                                            >
                                              Add to cart
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              className="btn btn-block text-white mt-auto btn-cart"
                                              data-toggle="modal"
                                              data-target="#color-and-size-modal"
                                            >
                                              Add to cart
                                            </button>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          className="btn btn-block text-white mt-auto btn-cart"
                                          data-toggle="modal"
                                          data-target="#login-modal"
                                        >
                                          Add to cart
                                        </button>
                                      </>
                                    )}
                                  </div>):(<></>)}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="details">
                          <div className="d-flex full-product flex-lg-row flex-md-column flex-sm-column ">
                            <div className="left-product mr-1">
                              <fieldset className="card-store">
                                <legend className="w-auto ml-2 mr-2 text-dim">
                                  <div className="ml-2 mr-2">Store</div>
                                </legend>
                                <div className="d-flex justify-content-between ">


                                  <div className="d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-xs-column more_on_store">
                                    <div className="px-2">
                                      {item.storeInfo.logo === null ? (
                                        <>
                                          <img
                                            className="profile-store"
                                            src={StoreProfile}
                                            alt="profile-pic-review"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="profile-store"
                                            src={`${imgBaseUrl}/profile/avatar_${item.storeInfo.logo}`}
                                            alt="product"
                                            loading="lazy"
                                          />
                                        </>
                                      )}
                                    </div>
                                    <div className="d-flex flex-column justify-content-start">
                                      <div className="name mb-1">
                                        {item.storeInfo.storeName}{" "}
                                      </div>
                                      <div className="brand mb-1">
                                        {JSON.parse(item.storeInfo.keyWords)[0]}{" "}
                                      </div>
                                      <Link to={`/store/${item.storeInfo._id}`}>
                                        <div className="brand-link">
                                          Visit Store
                                        </div>
                                      </Link>
                                    </div>
                                  </div>


                                  <div className="border mx-4"></div>
                                  <div className="mx-4">
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="text-dim">
                                        Seller Rating
                                      </div>
                                      <div className="count">
                                        {item.storeInfo.averageRating}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="text-dim">
                                        Ship On Time
                                      </div>
                                      <div className="count">100</div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="text-dim mr-5">
                                        Chat Rate Response
                                      </div>
                                      <div className="count">100</div>
                                    </div>
                                  </div>
                                  <div className=" mr-2 my-2" onClick={()=>(navigateToChat(item.storeInfo._id, true))}>
                                    <img src={IconChat} alt="IconChat" />
                                    <div className="chat" >Chat</div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                            {userInfo ? (
                              <>
                                <div className="right-product ml-1">
                                  <fieldset className="card-store">
                                    <legend className="w-auto text-dim">
                                      <div className="ml-2 mr-2">Delivery</div>
                                    </legend>
                                    {userInfo.address.length === 0 ? (
                                      <>
                                        <div className="d-flex flex-column justify-content-between">
                                          <div className="d-flex">
                                            <div className="mx-2 ">
                                              <img
                                                className="location-icon"
                                                src={IconLocationDanger}
                                                alt="IconLocationDanger"
                                              />
                                            </div>
                                            <div className="card-location-danger">
                                              No address added!
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content-end py-2 px-2">
                                            <div
                                              className="card-location-danger mx-2"
                                              role="button"
                                              onClick={NavigateToAddressBook}
                                            >
                                              Add Address
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="d-flex flex-column justify-content-between">
                                          <div className="d-flex">
                                            <div className="mx-3">
                                              <img
                                                className="location-icon"
                                                src={IconLocation}
                                                alt="IconLocation"
                                              />
                                            </div>
                                            <div className="card-location">
                                              {
                                                userInfo.address[0]
                                                  .completeAddress
                                              }{" "}
                                              {", "}
                                              {userInfo.address[0].landmark}
                                              {", "}
                                              {userInfo.address[0].city}
                                              {", "}
                                              {userInfo.address[0].postalOrZip}
                                              {", "}
                                              {userInfo.address[0].province}
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content-end py-2 px-2">
                                            <div
                                              className="card-location-link mx-2"
                                              role="button"
                                              onClick={NavigateToAddressBook}
                                            >
                                              Change
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </fieldset>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        <div className="details full-pro d-flex flex-lg-row flex-md-row flex-sm-column justify-content-start">
                          <div className=" left-pro mr-4">
                            <fieldset className="card-store h-100">
                              <legend className="w-auto ml-2 mr-2 text-dim">
                                <div className="ml-2 mr-2">Description</div>
                              </legend>
                              <div className="">
                                {item ? (
                                  <>
                                    <div
                                      className="about-product"
                                      dangerouslySetInnerHTML={{
                                        __html: item.listing.description,
                                      }}
                                    ></div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </fieldset>
                          </div>
                          <div className="right-pro">
                            <div className="heading-section">
                              More from store
                            </div>
                            <div className="d-flex flex-lg-column flex-md-column flex-sm-row">
                              {/* top selling products of store */}
                              <ProductPageTopSellingProducts
                                id={item.storeInfo._id}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="container">
                      <div className="d-flex justify-content-between">
                        <div className="heading-section">Products Reviews </div>
                      </div>
                    </div>
                    <div className="container">
                      {/* Product reviews component */}
                      <ProductReviews id={id} />
                    </div>
                  </section>
                  <section className="mb-5">
                    <div className="container mb-2">
                      {/* Product related component */}
                      <ProductPageRelatedProducts
                        id={id}
                        keyWords={item.listing.keyWords}
                      />
                    </div>
                  </section>
                </div>
              </div>

              <LoginModal />
              <ColorAndSizeSelectionModal />
          </>
      )}
    </>
  );
}

export default ProductPage;
