import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import LoadMore from "../../../Assets/images/icons/icon-load-more.svg"
import { useSelector } from "react-redux";
import $ from "jquery";

import iconAccepted from "../../../Assets/images/icons/icon-accepted.svg";
import iconOnWay from "../../../Assets/images/icons/icon-onway.svg";
import iconDelivered from "../../../Assets/images/icons/icon-delivered.svg";
import IconFreeShipping from "../../../Assets/images/icons/icon-free-shipping.svg";
import Loader from "../../Loader/Loader";
import NoOrderYet from "./NoOrderYet";
import OrderModal from "./OrderModal";
import OrderModalReview from "./OrderModalReview";
import { apiBaseUrl, imgBaseUrl } from "../../config/Config";

const AllOrders = () => {
  const { userToken } = useSelector((state) => state.user);

  const [ordervalue, setOrdervalue] = useState([]);

  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [loadData, setLoadData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noDataFound, setNodataFound] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalReview, setOpenModalReview] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [itemId, setItemId] = useState(0);
  const [storeId, setStoreId] = useState(0);
  const [itemIndex, setItemIndex] = useState(0);

  useEffect(() => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .get(`${apiBaseUrl}/api/order/user-order?page=${page}`, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          setOrdervalue([...ordervalue, ...res.data.data.order]);
          setLoading(false);
        }
        if (res.data.meta.statusCode === 206) {
          setLoading(false);
          setNodataFound(true);
        }
        if (res.data.meta.page === page) {
          setIsLoad(false);
        } else {
          setPage(res.data.meta.page);
        }
      })
      .catch((err) => {
        console.log("error is >>>>", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  const handleModalClose = () => {
    $("#order-modal").modal("hide");
  };

  useEffect(() => {
    FilterOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi]);

  const FilterOrder = async () => {
    if (callApi) {
      const config = {
        headers: {
          "x-auth-token": userToken,
        },
      };
      await axios
        .put(`${apiBaseUrl}/api/order/user-cancel-order`, { orderId }, config)
        .then((res) => {
          if (res.data.meta.statusCode === 200) {
            let filtereData = ordervalue.map((value) => {
              if (value._id !== res.data.data.order._id) {
                return value;
              } else {
                return res.data.data.order;
              }
            });
            setOrdervalue(filtereData);
            handleModalClose();
          }
        })
        .catch((err) => {
          console.log("error is >>>>", err);
        });
    }
  };

  const cancelOrder = (id) => {
    setOpenModal(true);
    setOrderId(id);
  };
  const ReviewOrder = (orderId, itemId, storeId, index) => {
    setOpenModalReview(true);
    setOrderId(orderId);
    setItemId(itemId);
    setStoreId(storeId);
    setItemIndex(index);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mt-3">
            {ordervalue.map((i, index) => {
              return (
                <div key={index} className="inner-card mb-3">
                  <div className="d-flex justify-content-between p-2 pb-4 pt-3">
                    <div className="d-flex justify-content-between w-100">
                      <div className="order-number">
                        <div>Order #{i.orderId}</div>
                        <div className="mt-3">
                          Placed on{" "}
                          {moment(i.createdAt).format("DD  MMMM  YYYY")}{" "}
                          {moment(i.createdAt).format("h : mm : ss a")}
                        </div>
                      </div>

                      <div className="d-flex w-50 justify-content-between order-progressbar-track">
                        <div
                          className={
                            i.status === "Accepted"
                              ? "track-progressbar text-center bg-progressbar-accepted"
                              : "track-progressbar text-center bg-progressbar"
                          }
                        >
                          <img src={iconAccepted} alt="iconAccepted" />
                        </div>
                        <div
                          className={
                            i.status === "Shipped"
                              ? "track-progressbar text-center bg-progressbar-onway"
                              : "track-progressbar text-center bg-progressbar"
                          }
                        >
                          <img src={iconOnWay} alt="iconOnWay" />
                        </div>
                        <div
                          className={
                            i.status === "Delivered"
                              ? "track-progressbar text-center bg-progressbar-delivered"
                              : "track-progressbar text-center bg-progressbar"
                          }
                        >
                          <img src={iconDelivered} alt="iconDelivered" />
                        </div>
                        <div id="tracker"></div>
                      </div>

                      <div
                        role="button"
                        className={
                          (i.status === "Delivered" &&
                            "button-status-delivered text-center") ||
                          (i.status === "Cancelled" &&
                            "button-status-cancelled text-center") ||
                          (i.status === "Shipped" &&
                            "button-status-shipped text-center") ||
                          (i.status === "Pending" &&
                            "button-status-pending text-center") ||
                          (i.status === "Accepted" &&
                            "button-status-accepted text-center") ||
                          (i.status === "On Way" &&
                            "button-status-onway text-center")
                        }
                      >
                        {i.status}
                      </div>
                    </div>
                  </div>
                  <div className="ml-2 d-flex justify-content-start">
                    <div className="image-item">
                      <img
                        src={`${imgBaseUrl}/listing/small_${i.itemProperties.image}`}
                        alt="item"
                        loading="lazy"
                      />
                    </div>
                    <div className="middle-div">
                      <div className="item-title">{i.itemProperties.title}</div>
                      <div className="d-flex">
                        <h5 className="mt-3 item-price">
                          RS.{" "}
                          {i.itemProperties.price -
                            (i.itemProperties.price *
                              i.itemProperties.salePercent) /
                            100}
                        </h5>
                        {i.itemProperties.isSale === true && (
                          <h5 className="item-discount-price ml-2 mt-3">
                            {i.itemProperties.price}
                          </h5>
                        )}
                      </div>
                      <div className="mt-1 d-flex justify-content-between w-50">
                        <div className="">
                          <div className="item-detail-heading">Colors</div>
                          <div className="d-flex flex-wrap mt-1">
                            <div
                              className="color-index mr-2 mb-2"
                              style={{
                                background: `${i.itemProperties.color}`,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="">
                          <div className="item-detail-heading">Size</div>

                          <div className="d-flex flex-wrap mt-1">
                            <div className="size-index mr-2 mb-2 text-center">
                              <div className="size-index-text mt-1">
                                {i.itemProperties.type}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="item-detail-heading">Quantity</div>
                          <div className="d-flex mt-2">
                            <div className="item-quantity">
                              {i.itemProperties.quantity}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end flex-column w-25">
                      <div className="d-flex justify-content-end align-items-start mb-5">
                        {i.itemProperties.isDelivery === false && (
                          <img
                            className="free-shipping-icon mb-4"
                            src={IconFreeShipping}
                            alt="IconFreeShipping"
                          />
                        )}
                      </div>
                      <div className="d-flex justify-content-end mb-2">
                        {i.status === "Pending" ? (
                          <div className="mt-2 align-item-end">
                            <div
                              role="button"
                              className="button-status-cancel text-center"
                              onClick={(e) => {
                                e.preventDefault();
                                cancelOrder(i._id);
                              }}
                              data-toggle="modal"
                              data-target="#order-modal"
                            >
                              Cancel Order
                            </div>
                          </div>
                        ) : i.status === "Delivered" && i.isReview === false ? (
                          <>
                            <div
                              role="button"
                              className="button-review text-center"
                              onClick={(e) => {
                                e.preventDefault();
                                ReviewOrder(i._id, i.itemId, i.storeId, index);
                              }}
                              data-toggle="modal"
                              data-target="#reviewordermodal"
                            >
                              Review
                            </div>
                            <div
                              role="button"
                              className="ml-2 button-status-cancel text-center"
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   updateStatus(
                            //     i._id,
                            //     "Delivered"
                            //   );
                            // }}
                            >
                              Refund Order
                            </div>
                          </>
                        ) : i.status === "Cancelled" ? (
                          <div
                            role="button"
                            className="button-status-cancel text-center"
                            disabled
                          >
                            Cancelled
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {isLoad ?
              <>
                <div
                  className="d-flex justify-content-center heading-more mt-3"
                  role="button"
                  onClick={() => setLoadData(!loadData)}
                >
                  Load More
                  <img className="ml-2" src={LoadMore} alt="LoadMore" />
                </div>
              </>
              :
              <></>
            }

            {openModal && (
              <OrderModal
                message={"Are you sure you want to Cancel?"}
                setCallApi={setCallApi}
              />
            )}
            {openModalReview && (
              <OrderModalReview
                ordervalue={ordervalue}
                orderId={orderId}
                itemId={itemId}
                storeId={storeId}
                index={itemIndex}
                setOrdervalue={setOrdervalue}
              />
            )}
          </div>
          {noDataFound === true && <NoOrderYet />}
        </>
      )}
    </>
  );
};

export default AllOrders;
