import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import FavIcon from "../../Assets/images/icons/icon-favourite.svg";
import FavIconFilled from "../../Assets/images/icons/icon-favourite-filled.svg";
import { useSelector } from "react-redux";
import LoginModal from "../LoginModal/LoginModal";
import { apiBaseUrl } from "../config/Config";

const FavHeart = (props) => {
  const { userToken } = useSelector((state) => state.user);

  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    if (userToken) {
      const config = {
        headers: {
          "x-auth-token": userToken,
        },
      };
      axios
        .get(`${apiBaseUrl}/api/favourite/check-favourite-status/${props.itemId}`, config)
        .then((res) => {
          setFavorite(res.data.meta.status);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFavourite = (itemId) => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .delete(`${apiBaseUrl}/api/favourite/delete-favourite/${itemId}`, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          setFavorite(false);
          props?.filterFavourite(itemId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addFavourite = (itemId) => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .post(`${apiBaseUrl}/api/favourite/add-to-favourite/${itemId}`, {}, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          setFavorite(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {userToken ? (
        <>
          {favorite === true ? (
            <span onClick={() => removeFavourite(props.itemId)}>
              <img src={FavIconFilled} alt="CartIcon" />
            </span>
          ) : (
            <span onClick={() => addFavourite(props.itemId)}>
              <img src={FavIcon} alt="CartIcon" />
            </span>
          )}
        </>
      ) : (
        <>
          <span role="button" data-toggle="modal" data-target="#login-modal">
            <img src={FavIcon} alt="CartIcon" />
          </span>
        </>
      )}
      <LoginModal />
    </>
  );
};

export default FavHeart;
