import axios from "axios";
import React, { useEffect, useState } from "react";
import FavHeart from "../FavHeart/FavHeart";
import { Link } from "react-router-dom";
import { apiBaseUrl, imgBaseUrl } from "../config/Config";
import SmallCardSkeleton from "../Skeleton/SmallCardSkeleton";
import StarFilled from "../../Assets/images/icons/icon-star-filled.svg";
import CartIcon from "../../Assets/images/icons/icon-cart.svg";

const ProductsOnSale = () => {
  const [listing, setLising] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  let page = 0;
  let limit = 5;

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${apiBaseUrl}/api/listing/product-on-sale-listing?page=${page}&limit=${limit}`)
      .then((res) => {
        if(res.data.meta.status){
        setLising([...listing, ...res.data.data.listing]);
        setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          {
        <div className="scroll_on_bg_card">
          <div
              id="product-card"
              className="d-flex x-scrolls justify-content-start"
            >
              {[0,1,2,3,4].map((index)=>(<div key={index} className="products-card h-100 mb-3"><SmallCardSkeleton /></div>)
              )}
            </div>
        </div>
      }
        </>
      ) : (
        <>
        <div className="scroll_on_bg_card">
        <div
            id="product-card"
            className="d-flex x-scrolls justify-content-start"
          >
            {listing.map((item, index) => {
              return (
                <div key={index} className="products-card h-100">
                  <div className="position-relative">
                    <div className="d-flex align-item-center p-1 w-100 justify-content-between position-absolute">
                      {item.isSale === true ? (
                        <>
                          <div className="products-card-percentage">
                            <div className="percentage">
                              {item.salePercent} %
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div></div>
                        </>
                      )}
                      <div role="button" className="products-card-fav-icon">
                        <div className="products-card-fav-icon-img position-absolute">
                          <FavHeart key={item._id} itemId={item._id} />
                        </div>
                      </div>
                    </div>
                    <Link to={`/product/${item._id}`}>
                      <img
                        className="products-card-image"
                        src={`${imgBaseUrl}/listing/small_${item.images[0]}`}
                        alt="product"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  <h1>{item.title}</h1>
                  <div className="products-card-price d-flex">
                    <h5 className="card-price">
                      RS. {Math.ceil(item.price - (item.price * item.salePercent) / 100)}
                    </h5>
                    {item.isSale === true && (
                      <h5 className="card-discount-price ml-2">{item.price}</h5>
                    )}
                  </div>
                  <div className="d-flex align-item-center justify-content-between mt-2">
                    <div className="d-flex ">
                      <img
                        className="star-icon"
                        src={StarFilled}
                        alt="StarFilled"
                      />
                      <div className="star-count ml-2">
                        {item.averageRating}
                      </div>
                      <div className="products-card-sold ml-2">
                        ({item.sold} Sold)
                      </div>
                    </div>
                    <div className="products-card-sold">
                      <Link to={`/product/${item._id}`}>
                        <div
                          role="button"
                          className="products-card-cart-icon p-0 m-0"
                        >
                          <img
                            className="products-card-cart-icon-img position-absolute"
                            src={CartIcon}
                            alt="CartIcon"
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        </>
      )}
    </>
  );
};

export default ProductsOnSale;
