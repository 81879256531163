
import React, { useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
const libraries = ['places'];
const MapView = ({ lat, lng, mapAddress, zoom, width, height }) => {
  const mapStyle = {
    width: width || '100%',  
    height: height || '100%',  
  };

  const [activeMarker, setActiveMarker] = useState(false)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA5dpHg91sA_-H5ZYv_4aavdYIDHBLDTZU',
    libraries:libraries
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div style={mapStyle}>
      <GoogleMap
        mapContainerStyle={{
          width: width,
          height: height,
        }}
        zoom={zoom}
        center={{ lat, lng }}
        options={{ zoomControl: true }}
      >
        {activeMarker && (
          <InfoWindow
            position={{ lat, lng }}
            onCloseClick={() => setActiveMarker(false)}
          >
            <div>{mapAddress}</div>
          </InfoWindow>
        )}
        <Marker
          onMouseOver={() => setActiveMarker(true)}
          position={{ lat, lng }}
        />
      </GoogleMap>
    </div>
  );
};

export default MapView