
import React, { useState, useEffect } from "react";
import chat_button from '../../Assets/images/chat/chat_button.png'
import chat_close_icon from '../../Assets/images/chat/chat_close_icon.png'

import './chat.css'
import ChatOpenView from "./ChatOpenView";

const ChatButton = ({productId, storeId, chatStatus}) => {
    console.log(productId, storeId, chatStatus)
    const [openChatView, setOpenChatView] = useState(false);
    useEffect(() => {
        setOpenChatView(chatStatus)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [chatStatus]);
    return (
        <div>
        <div className="main d-flex justify-content-end align-items-end">
            <div className="header">
                {openChatView ? (
                    <div className="close_button">
                        <img onClick={()=>setOpenChatView(false)} src={chat_close_icon} alt="chat_close_icon" />
                    </div>
                ) : (
                    <div className="chat-button-main">
                        <img onClick={()=>setOpenChatView(true)} src={chat_button} alt="chat_button" />
                    </div>
                )}
            </div>
        </div>
          {openChatView && <ChatOpenView setOpenChatView={setOpenChatView} />}
        </div>
    );
};

export default ChatButton;

