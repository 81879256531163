import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import { useSelector } from "react-redux";
import Joi from "joi";
import axios from "axios";
import $ from "jquery";
import { apiBaseUrl } from "../../config/Config";
import { passwordregex } from "../../../shared/validators";

const ChangePasswordModal = () => {
  const { userToken } = useSelector((state) => state.user);

  const [userPassword, setUserPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [userPasswordError, setUserPasswordError] = useState(false);
  const [userPasswordErrorMessage, setUserPasswordErrorMessage] = useState("");

  const handleModalClose = () => {
    $("#password-modal").modal("hide");
  };

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setUserPassword({
      ...userPassword,
      [name]: value,
    });
    setErrors({});
  };

  var schema = Joi.object ({
    oldPassword: Joi.string().required().regex(passwordregex).min(8).max(34).label("oldPassword").messages({
      "string.empty": "Password cannot be empty",
      "string.pattern.base": "Please enter the correct format for the password",
      "string.min": "Password must be at least 8 characters long",
      "string.max": "Password must be no more than 34 characters long"
    }),
    newPassword: Joi.string().required().regex(passwordregex).min(8).max(34).label("newPassword").messages({
      "string.empty": "Password cannot be empty",
      "string.pattern.base": "Please enter the correct format for the password",
      "string.min": "Password must be at least 8 characters long",
      "string.max": "Password must be no more than 34 characters long"
    }),
    confirmPassword: Joi.string().required().valid(Joi.ref("newPassword")).messages({
      "any.only": "Passwords do not match",
      "any.required": "Confirm password is required"
    }),
  });
  const formValidation = () => {
    const result = schema.validate(userPassword);
    if (!result.error) return null;

    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors({ ...formErrors });
    } else {
      setErrors({});
      const config = {
        headers: {
          "x-auth-token": userToken,
        },
      };
      try {
        const oldPassword = userPassword.oldPassword;
        const newPassword = userPassword.newPassword;

        axios
          .put(
            `${apiBaseUrl}/api/user/change-password`,
            { oldPassword, newPassword },
            config
          )
          .then((res) => {
            if (res.data.meta.statusCode === 200) {
              setUserPassword({});
              handleModalClose();
            }
          })
          .catch((err) => {
            if (err.response.data.meta.statusCode === 400) {
              setUserPasswordError(true);
              setUserPasswordErrorMessage(err.response.data.meta.message);
              setTimeout(() => {
                setUserPasswordError(false);
                setUserPasswordErrorMessage("");
              }, 5000);
            }

            if (err.response.data.meta.statusCode === 401) {
              setUserPasswordError(true);
              setUserPasswordErrorMessage(err.response.data.meta.message);
              setTimeout(() => {
                setUserPasswordError(false);
                setUserPasswordErrorMessage("");
              }, 5000);
            }
          });
      } catch (err) {
        return err;
      }
    }
  };

  return (
    <div
      className="modal"
      id="password-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="passwordModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="password-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                <form onSubmit={handleOnSubmit}>
                  <div className="mt-1">
                    <div className="heading-modal">Change Password!</div>
                  </div>
                  {userPasswordError && (
                    <div
                      className="error-text text-center alert-danger"
                      role="alert"
                    >
                      {userPasswordErrorMessage}
                    </div>
                  )}
                  <div className="mt-2">
                    <div className="field-heading">Current Password</div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="********"
                        value={userPassword.oldPassword || ""}
                        name="oldPassword"
                        onChange={handleInputOnChange}
                      />
                      {errors.oldPassword && (
                        <div className="error-text pb-1">
                         {errors.oldPassword}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="forget-text">
                    <Link to="#">Forget Password ?</Link>
                  </div>
                  <div className="mt-2">
                    <div className="field-heading">New Password</div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="********"
                        value={userPassword.newPassword || ""}
                        name="newPassword"
                        onChange={handleInputOnChange}
                      />
                      {errors.newPassword && (
                        <div className="error-text pb-1">
                          {errors.newPassword}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="field-heading">Confirm Password</div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="********"
                        value={userPassword.confirmPassword || ""}
                        name="confirmPassword"
                        onChange={handleInputOnChange}
                      />
                      {errors.confirmPassword && (
                        <div className="error-text pb-1">
                          {errors.confirmPassword}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-1 mb-3">
                    <button type="submit" className="btn-sign-in w-100">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
