import React from "react";
import Header from "../Components/Header/Header";
import HeroSection from "../Components/HeroSection/HeroSection";
import { Link, useNavigate } from "react-router-dom";
import Feature from "../Components/Feature/Feature";
import Banner from "../Components/Banner/Banner";
import { useSelector } from "react-redux";
// Images and icons import
import ShowMore from "../Assets/images/icons/icon-show-more.svg";
import TopStores from "../Components/HomePageComponents/TopStores";
import TopSellingProduct from "../Components/HomePageComponents/TopSellingProduct";
import ProductsOnSale from "../Components/HomePageComponents/ProductsOnSale";
import NearestStore from "../Components/HomePageComponents/NearestStore";

function Home() {
  const { userInfo } = useSelector((state) => state.user);
  let navigate = useNavigate();
  return (
    <>
      <div>
        <Header />
        {/* <HeroSection slider={assets && assets[0]?.Slider} key={1} /> */}
        <HeroSection />
        <Feature />
        <Banner />
        {/* Paid Promotion Section */}

        {/* <section className="p-2 mt-2">
          <div className="container">
            <div className="mb-3 d-flex justify-content-between">
              <div className="heading-section">Paid Promotion's</div>
              <div>
                <Link className="heading-more" to="/product/products">
                  Shop More <img src={ShowMore} alt="ShowMore" />
                </Link>
              </div>
            </div>
            <PaidPromotion />
          </div>
        </section> */}

        {/* Top Selling Section */}

        <section className="p-2 mt-2">
          <div className="container">
            <div className="mb-3 d-flex justify-content-between">
              <div className="heading-section">Top Selling Products</div>
              <div>
                <Link className="heading-more" to="/topsellingproducts">
                  Shop More <img src={ShowMore} alt="ShowMore" />
                </Link>
              </div>
            </div>
            <TopSellingProduct />
          </div>
        </section>


        {/* Product on sale Section */}


        <section className="p-2 mt-2">
          <div className="container">
            <div className="mb-3 d-flex justify-content-between">
              <div className="heading-section">Products on Sale</div>
              <div>
                <Link className="heading-more" to="/productsonsale">
                  Shop More <img src={ShowMore} alt="ShowMore" />
                </Link>
              </div>
            </div>
            <ProductsOnSale />
          </div>
        </section>


        {/*Top Stores On Ecommerce Section */}
        {userInfo ? <><section className="p-2 mt-2">
          <div className="container pb-2">
            <div className="mb-3 d-flex justify-content-between">
              <div className="heading-section">Nearest Store</div>
              <div>
                <Link className="heading-more" to="/nearStore">
                  Explore More <img src={ShowMore} alt="ShowMore" />
                </Link>
              </div>
            </div>
            {userInfo?.isMapAddress ? <NearestStore /> : <p>add address banner here </p>}
          </div>
        </section></> : <>
          <section className="p-2 mt-2">
            <div className="container pb-2">
              <div className="d-flex justify-content-center align-items-center home-login-section">
                <div className="flex-column text-center">
                  <h4 className="mt-5">Login to find nearby stores</h4>
                  <button className="home-login-btn mb-5" onClick={()=>{navigate("/login")}}>Log in</button>
                </div>
              </div>
            </div>
          </section>
        </>}

        <section className="p-2 mt-2">
          <div className="container pb-2">
            <div className="mb-3 d-flex justify-content-between">
              <div className="heading-section">Top Stores On Ecommerce</div>
              <div>
                <Link className="heading-more" to="/topstores">
                  Explore More <img src={ShowMore} alt="ShowMore" />
                </Link>
              </div>
            </div>
            <TopStores />
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
