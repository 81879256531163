import React from "react";
import FooterUser from "./FooterUser";
import HeaderUser from "./HeaderUser";
import SideBarUser from "./SideBarUser";

function PageLayoutUser(props) {
  return (
    <div id="wrapper" className="d-flex flex-column">
      <HeaderUser />
      <div id="content-wrapper" className="d-flex flex-row">
        <SideBarUser />
        <div id="content">{props.children}</div>
      </div>
    </div>
  );
}

export default PageLayoutUser;
