import React, { useEffect, useState } from "react";
import moment from "moment";

import StarFilled from "../../Assets/images/icons/icon-star-filled.svg";
import StarEmpty from "../../Assets/images/icons/icon-star-empty.svg";
import LoadMore from "../../Assets/images/icons/icon-load-more.svg";
import UserAvatar from "../../Assets/images/avatar.svg";
import { apiBaseUrl, imgBaseUrl } from "../config/Config";
import axios from "axios";
import SmallLoader from "../Loader/SmallLoader";
const ProductReviews = ({ id }) => {
  const [itemReviews, setItemReviews] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${apiBaseUrl}/api/reviews/get-product-review?page=${page}&itemId=${id}`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.meta.statusCode === 200) {
          setItemReviews([...itemReviews, ...res.data.data.itemReviews]);
        }
        if (res.data.meta.page === page) {
          setIsLoad(false);
        } else {
          setPage(res.data.meta.page);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);
  return (
    <>
      {isLoading ? (
        <>
          <SmallLoader />
        </>
      ) : (
        <>
          {itemReviews.length === 0 ? (
            <>
              <div className="details-app">
                <div className="card-review">No reviews banner here</div>
              </div>
            </>
          ) : (
            <>
              <div className="details-app">
                {itemReviews?.map((reviews, index) => {
                  return (
                    <div className="card-review" key={index}>
                      <div className="mb-3">
                        <div className="d-flex">
                          {reviews.userInfo.image === null ? (
                            <>
                              <img
                                className="profile-pic-review"
                                src={UserAvatar}
                                alt="profile-pic-review"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                className="profile-pic-review"
                                src={`${imgBaseUrl}/profile/avatar_${reviews.userInfo.image}`}
                                alt="profile-pic-review"
                              />
                            </>
                          )}
                          <div className="d-flex flex-column w-75">
                            <div className="user-name">
                              {reviews.userInfo.name}
                            </div>
                            <div className="d-flex ">
                              {reviews?.itemReviews.ratingNumber ? (
                                <>
                                  {reviews.itemReviews.ratingNumber >= 1 ? (
                                    <img
                                      className="mr-1"
                                      src={StarFilled}
                                      alt="StarFilled"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        className="mr-1"
                                        src={StarEmpty}
                                        alt="StarEmpty"
                                      />
                                    </>
                                  )}
                                  {reviews.itemReviews.ratingNumber >= 2 ? (
                                    <img
                                      className="mr-1"
                                      src={StarFilled}
                                      alt="StarFilled"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        className="mr-1"
                                        src={StarEmpty}
                                        alt="StarEmpty"
                                      />
                                    </>
                                  )}
                                  {reviews.itemReviews.ratingNumber >= 3 ? (
                                    <img
                                      className="mr-1"
                                      src={StarFilled}
                                      alt="StarFilled"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        className="mr-1"
                                        src={StarEmpty}
                                        alt="StarEmpty"
                                      />
                                    </>
                                  )}
                                  {reviews.itemReviews.ratingNumber >= 4 ? (
                                    <img
                                      className="mr-1"
                                      src={StarFilled}
                                      alt="StarFilled"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        className="mr-1"
                                        src={StarEmpty}
                                        alt="StarEmpty"
                                      />
                                    </>
                                  )}
                                  {reviews.itemReviews.ratingNumber === 5 ? (
                                    <img
                                      className="mr-1"
                                      src={StarFilled}
                                      alt="StarFilled"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        className="mr-1"
                                        src={StarEmpty}
                                        alt="StarEmpty"
                                      />
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="ml-2">
                                {reviews.itemReviews.ratingNumber}
                              </div>
                            </div>
                            <p className="content-review mt-3">
                              {reviews.itemReviews.message}
                            </p>
                            <div className="d-flex">
                              {reviews.itemReviews.images.map(
                                (images, index) => {
                                  return (
                                    <img
                                      key={index}
                                      className="pic-review"
                                      src={`${imgBaseUrl}/review/small_${images}`}
                                      alt="product1"
                                    />
                                  );
                                }
                              )}
                            </div>
                          </div>
                          <div className="ml-auto">
                            <p className="review-date">
                              {moment(reviews.itemReviews.createdAt).format(
                                "DD  MMMM  YYYY"
                              )}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="bottom-border mt-3"></div>
                      </div>
                    </div>
                  );
                })}
                {isLoad ? (
                  <>
                    <div
                      role="button"
                      className="d-flex justify-content-center load-more"
                      onClick={() => setLoadData(!loadData)}
                    >
                      Load more {""}
                      <img className="ml-2" src={LoadMore} alt="LoadMore" />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProductReviews;
