import React from "react";
import hero1 from "../../Assets/images/slider1.svg";
import arrowLeft from "../../Assets/images/icons/arrow-circle-left.svg";
import arrowRight from "../../Assets/images/icons/arrow-circle-right.svg";

function HeroSection() {
  return (
    <div id="heroheader">
      <section className="hero-header bg-red">
        <div className="container px-4 px-lg-5 my-5">
          <div className="text-center text-light">
            <div
              id="carouselExampleIndicators"
              className="carousel slide "
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                {/* {props?.slider?.map((slide, index) => {
                  return (
                    <li
                      key={index}
                      data-target="#carouselExampleIndicators"
                      data-slide-to={index}
                      className={`${index === 0 ? "active" : ""}`}
                    ></li>
                  );
                })} */}
              </ol>
              <div className="carousel-inner rounded">
                {/* {props?.slider?.map((slide, index) => {
                  return (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      key={index}
                    >
                      <img
                        className="d-block w-100 banner-height"
                        src={`/api/uploads/${slide}`}
                        alt="index"
                      />
                    </div>
                  );
                })} */}
                <div>
                  <img
                    className="d-block w-100 banner-height"
                    src={hero1}
                    alt="index"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span aria-hidden="true">
            <img src={arrowLeft} alt="arrowLeft" />
          </span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span aria-hidden="true">
            <img src={arrowRight} alt="arrowRight" />
          </span>
          <span className="sr-only">Next</span>
        </a>
      </section>
    </div>
  );
}

export default HeroSection;
