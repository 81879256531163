import React from "react";
import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import $ from "jquery";
import AddMapLocation from "../../../Pages/MapLocation/AddMapLocation";

const AddLocationModal = ({isMapAddress}) => {
    function closeModal() {
        $("#add-location-modal").modal('hide');
    }
  return (
    <div
      className="modal"
      id="add-location-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="AddAddressModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="add-location-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">{isMapAddress?(<>Change Map Address</>):(<>Add New Address!</>)}</div>
                </div>
                <div>
                    <AddMapLocation isMapAddress = {isMapAddress} closeModal={closeModal} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLocationModal;
