import { useNavigate } from "react-router-dom";
import background from "../Assets/images/error-bg.png";
import bg from "../Assets/images/error.png";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <>
      <div
        className="bg-dark py-5 hero-header"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="container px-4 px-lg-5 my-5">
          <div className="text-center">
            <div
              className="container"
              // style={{ height: "400px", width: "auto" }}
            >
              {/* <h1 className="display-4 fw-bolder f-bold">
                Oops! sorry we could not find the page
              </h1> */}
              <img src={bg} alt="error" />
            </div>
            <div className="flexGrow ">
              <button className="btn btn-primary" onClick={goBack}>
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Unauthorized;
