import React from "react";
import NoOrderImage from "../../../Assets/images/icons/dashboard-icons/no-order-yet.svg";

const NoOrderYet = () => {
  return (
    <div id="noorder">
      <div className="h-100 d-flex flex-column align-items-center justify-content-center">
        <img src={NoOrderImage} alt="NoOrderImage" />
        <div className="mt-2 heading-no-order">No order yet</div>
      </div>
    </div>
  );
};

export default NoOrderYet;
