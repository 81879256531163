import React, { useState, useEffect } from 'react';
import Header from "../../Components/Header/Header";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import SmallCardSkeleton from '../../Components/Skeleton/SmallCardSkeleton';
import FavHeart from "../../Components/FavHeart/FavHeart";
import { Link } from "react-router-dom";
import StarFilled from "../../Assets/images/icons/icon-star-filled.svg";
import CartIcon from "../../Assets/images/icons/icon-cart.svg";
import { apiBaseUrl, imgBaseUrl } from '../../Components/config/Config';

const SearchProduct = () => {
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const [listing, setLising] = useState([]);
const [page, setPage] = useState(1);
const [isLoading, setIsLoading] = useState(true);
const [isLoadMore, setIsLoadMore] = useState(true);
const [loadData, setLoadData] = useState(false);
const title = queryParams.get('title');
const minSale = queryParams.get('minSale');
const maxSale= queryParams.get('maxSale');
const minPrice = queryParams.get('minPrice')
const maxPrice= queryParams.get('maxPrice')
const isSale = queryParams.get('isSale');
const isDelivery= queryParams.get('isDelivery');
const minRating= queryParams.get('minRating');
const maxRating= queryParams.get('maxRating');

useEffect(()=>{
  axios.get(`${apiBaseUrl}/api/listing/search?title=${title}&page=${page}&minSale=${minSale}&maxSale=${maxSale}&minPrice=${minPrice}&maxPrice=${maxPrice}&minRating=${minRating}&maxRating=${maxRating}&isSale=${isSale}&isDelivery=${isDelivery}`)
  .then((res) => {
    if(res.data.data.length>0){
      setLising(res.data.data);
      setIsLoading(false);
      if(res.data.meta.page === page) {
        setIsLoadMore(false)
      }else{
        setPage(res.data.meta.page);
      }
      }else{
      setLising([]);
      setIsLoadMore(true);
    }
  })
  .catch((error) => {
    console.log(error); 
  });
}, [loadData, location.search])
  return (
    <div>
      <Header />
      <>
      {isLoading ? (
        <>
           <div className="scroll_on_bg_card">
          <div
              id="product-card" 
              className="container d-flex x-scrolls justify-content-start flex-wrap "
            >
              {[0,1,2,3,4,6,7,8,9,10].map((index)=>(<div key={index} className="products-card h-100 mb-3"><SmallCardSkeleton /></div>)
              )}
            </div>
        </div>
        </>
      ) : (
        <>
        <div className="scroll_on_bg_card">
        <div
            id="product-card"
            className="d-flex x-scrolls justify-content-start"
          >
            {listing.map((item, index) => {
              return (
                <div key={index} className="products-card h-100">
                  <div className="position-relative">
                    <div className="d-flex align-item-center p-1 w-100 justify-content-between position-absolute">
                      {item.isSale === true ? (
                        <>
                          <div className="products-card-percentage">
                            <div className="percentage">
                              {item.salePercent} %
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div></div>
                        </>
                      )}
                      <div role="button" className="products-card-fav-icon">
                        <div className="products-card-fav-icon-img position-absolute">
                          <FavHeart key={item._id} itemId={item._id} />
                        </div>
                      </div>
                    </div>
                    <Link to={`/product/${item.id}`}>
                      <img
                        className="products-card-image"
                        src={`${imgBaseUrl}/listing/${item.images[0]}`}
                        alt="product"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  <h1>{item.title}</h1>
                  <div className="products-card-price d-flex">
                    <h5 className="card-price">
                      RS. {Math.ceil(item.price - (item.price * item.salePercent) / 100)}
                    </h5>
                    {item.isSale === true && (
                      <h5 className="card-discount-price ml-2">{item.price}</h5>
                    )}
                  </div>
                  <div className="d-flex align-item-center justify-content-between mt-2">
                    <div className="d-flex ">
                      <img
                        className="star-icon"
                        src={StarFilled}
                        alt="StarFilled"
                      />
                      <div className="star-count ml-2">
                        {item.averageRating}
                      </div>
                      <div className="products-card-sold ml-2">
                        ({item.sold} Sold)
                      </div>
                    </div>
                    <div className="products-card-sold">
                      <Link to={`/product/${item.id}`}>
                        <div
                          role="button"
                          className="products-card-cart-icon p-0 m-0"
                        >
                          <img
                            className="products-card-cart-icon-img position-absolute"
                            src={CartIcon}
                            alt="CartIcon"
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {isLoadMore? (<>
                  <div  role="button" className="text-center  fw-bold  text-dark mb-3 " 
                   onClick={() => setLoadData(!loadData)}>
                    <p>
                      <strong>Load More</strong>
                    </p>
                  </div>
                  </>) :(<></>)}
        </div>
        </>
      )}
    </>
    </div>
  );
};

export default SearchProduct;
