import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header/Header";
import iconDelete from "../Assets/images/icons/icon-delete.svg";
import IconLocation from "../Assets/images/icons/icon-location-card.svg";
import IconLocationDanger from "../Assets/images/icons/dashboard-icons/icon-location-danger.svg";
import IconShowMore from "../Assets/images/icons/icon-load-more.svg";
import IconFreeShipping from "../Assets/images/icons/icon-free-shipping.svg";
import LoadMore from "../Assets/images/icons/icon-load-more.svg";

import IconEmail from "../Assets/images/icons/icon-email-card.svg";
import IconEmailDanger from "../Assets/images/icons/dashboard-icons/icon-email-danger.svg";
import IconPhone from "../Assets/images/icons/icon-phone-card.svg";
import IconPhoneDanger from "../Assets/images/icons/dashboard-icons/icon-phone-danger.svg";

import NoData from "../Assets/images/no-data.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import ChangeEmailModal from "../Components/DashboardComponents/Modals/ChangeEmailModal";
import AddEmailModal from "../Components/DashboardComponents/Modals/AddEmailModal";
import ChangeNumberModal from "../Components/DashboardComponents/Modals/ChangeNumberModal";
import AddPhoneModal from "../Components/DashboardComponents/Modals/AddPhoneModal";
import SmallLoader from "../Components/Loader/SmallLoader";
import { apiBaseUrl, imgBaseUrl } from "../Components/config/Config";

export const Checkbox = ({ id, type, handleClick, isChecked }) => {
  return (
    <input
      className="mr-2"
      id={id}
      type={type}
      onChange={handleClick}
      checked={isChecked}
    />
  );
};

export default function Cart() {
  const { userToken, userInfo } = useSelector((state) => state.user);
  let navigate = useNavigate();

  const [cartItems, setCartItems] = useState([]);
  const [page, setPage] = useState(0);
  const [loadData, setLoadData] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [addressIndex, setAddressIndex] = useState(0);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios.get(`${apiBaseUrl}/api/cart/cart-listing?page=${page}`, config).then((res) => {
      setIsLoading(false);
      if (res.data.meta.statusCode === 200) {
        setCartItems([...cartItems, ...res.data.data.cartListing]);
      }
      if (res.data.meta.page === page) {
        setIsLoad(false);
      } else {
        setPage(res.data.meta.page);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  const handleRemoveItemFromCart = (id) => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .delete(`${apiBaseUrl}/api/cart/delete-cart/${id}`, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          const items = cartItems.filter(
            (filterItem) => filterItem.id !== res.data.data.cartId
          );
          setCartItems(items);
          calculateSubTotal();
        }
      })
      .catch((err) => {
        console.log("error >>>", err);
      });
  };

  const SelectAddress = (e, index) => {
    e.preventDefault();
    setAddressIndex(index);
  };

  const calculateDeliveryCharges = () => {
    let deliverytotal = 0;
    isCheck.map((item) => {
      if (item.itemProperties.isDelivery === false) {
        return item.itemProperties.deliveryCharges;
      } else if (item.itemProperties.isDelivery === true) {
        return (deliverytotal += item.itemProperties.deliveryCharges);
      }
    });
    return deliverytotal;
  };
  const calculateSubTotal = () => {
    let total = 0;
    isCheck.map((item) => {
      if (item.itemProperties.isSale === false) {
        return (total +=
          item.itemProperties.price * item.itemProperties.quantity);
      } else if (item.itemProperties.isSale === true) {
        let discountPrice =
          item.itemProperties.price -
          Math.ceil((item.itemProperties.price * item.itemProperties.salePercent) / 100);
        return (total += discountPrice * item.itemProperties.quantity);
      }
    });
    return total;
  };

  const calculateTotal = () => {
    let total = 0;
    isCheck.map((item) => {
      if (item.itemProperties.isSale === false) {
        if (item.itemProperties.isDelivery === false) {
          return (total +=
            item.itemProperties.price * item.itemProperties.quantity +
            item.itemProperties.deliveryCharges);
        } else if (item.itemProperties.isDelivery === true) {
          return (total +=
            item.itemProperties.price * item.itemProperties.quantity +
            item.itemProperties.deliveryCharges);
        }
        return total;
      } else if (item.itemProperties.isSale === true) {
        let discountPrice =
          item.itemProperties.price -
          (item.itemProperties.price * item.itemProperties.salePercent) / 100;
        if (item.itemProperties.isDelivery === false) {
          return (total +=
            discountPrice * item.itemProperties.quantity +
            item.itemProperties.deliveryCharges);
        } else if (item.itemProperties.isDelivery === true) {
          return (total +=
            discountPrice * item.itemProperties.quantity +
            item.itemProperties.deliveryCharges);
        }
        return total;
      }
    });
    return total;
  };

  const NavigateToAddressBook = () => {
    navigate("/user-address");
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(
      cartItems.map((item) => ({
        cartItem: item.id,
        itemId: item.data._id,
        storeId: item.data.storeId,
        itemProperties: {
          quantity: item.quantity,
          color: item.color,
          type: item.type,
          image: item.data.images[0],
          title: item.data.title,
          price: item.data.price,
          isSale: item.data.isSale,
          salePercent: item.data.salePercent,
          isDelivery: item.data.isDelivery,
          deliveryCharges: item.data.deliveryCharges,
        },
      }))
    );
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e, item) => {
    const { checked } = e.target;
    setIsCheck([
      ...isCheck,
      {
        cartItem: item.id,
        itemId: item.data._id,
        storeId: item.data.storeId,
        itemProperties: {
          quantity: item.quantity,
          color: item.color,
          type: item.type,
          image: item.data.images[0],
          title: item.data.title,
          price: item.data.price,
          isSale: item.data.isSale,
          salePercent: item.data.salePercent,
          isDelivery: item.data.isDelivery,
          deliveryCharges: item.data.deliveryCharges,
        },
      },
    ]);
    if (!checked) {
      setIsCheck(isCheck.filter((i) => i.cartItem !== item.id));
    }
  };

  const isCheckInclude = (itemId) => {
    let check = isCheck.filter((i) => i.cartItem === itemId);
    if (check.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const placeOrder = async () => {
    await isCheck.filter((i) => (i["addressIndex"] = addressIndex));

    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .post(`${apiBaseUrl}/api/order/place-order`, isCheck, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          navigate("/user-orders");
        } else if (res.data.meta.statusCode === 206) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log("error is >>>>>>>>>", err.response);
      });
  };

  return (
    <>
      <div>
        <Header />
        {isLoading ? (
          <>
            <SmallLoader />
          </>
        ) : (
          <>
            <section className="py-5" id="cart">
              <div className="row  ">
                <div className="col-md-12 col-lg-12 col-xl-12 ">
                  <div className="m-b-30">
                    {cartItems.length === 0 ? (
                      <>
                        <div className="text-center">
                          <img src={NoData} alt="emptyimage" />
                          <div className="no-data-text">No Data Found</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div id="cart">
                          <div className="d-flex full-product flex-wrap justify-content-between">
                            <div className="card-left left-product">
                              <div className="inner-card mb-4">
                                <div className="d-flex justify-content-between p-2">
                                  <div className="text-center">
                                    <input
                                      className="mr-2"
                                      id="selectAll"
                                      name="selectAll"
                                      type="checkbox"
                                      onChange={handleSelectAll}
                                      checked={isCheckAll}
                                    />
                                    <label
                                      className="form-check-label select-all-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      Select All
                                    </label>
                                  </div>
                                  <div>
                                    <img src={iconDelete} alt="iconDelete" />
                                  </div>
                                </div>
                              </div>
                              {cartItems.map((item, index) => {
                                return (
                                  <div key={index} className="inner-card mb-3">
                                    <div className="d-flex justify-content-between p-2">
                                      <div className="m-0">
                                        <input
                                          key={index}
                                          id={item.id}
                                          name={item.data.title}
                                          type="checkbox"
                                          onChange={(e) => handleClick(e, item)}
                                          checked={isCheckInclude(item.id)}
                                        />
                                      </div>
                                      <div className="ml-2 d-flex flex-sm-column move-to-column justify-content-between w-100 mt-1">
                                        <img
                                          className="image-item"
                                          src={`${imgBaseUrl}/listing/small_${item.data.images[0]}`}
                                          alt="item"
                                          loading="lazy"
                                        />
                                        <div className="mx-3 middle-div">
                                          <div className="item-title">
                                            {item.data.title}
                                          </div>
                                          <div className="d-flex mt-3">
                                            <h5 className="item-price">
                                              RS.{" "}
                                              {Math.ceil(item.data.price -
                                                (item.data.price *
                                                  item.data.salePercent) /
                                                  100)}
                                            </h5>
                                            {item.data.isSale === true && (
                                              <h5 className="item-discount-price ml-2 mt-1">
                                                {item.data.price}
                                              </h5>
                                            )}
                                          </div>
                                          <div className="d-flex justify-content-between w-100">
                                            <div className="w-50">
                                              <div className="item-detail-heading">
                                                Colors
                                              </div>
                                              <div className="d-flex flex-wrap mt-1">
                                                <div
                                                  className="color-index mr-2 mb-2"
                                                  style={{
                                                    background: `${item.color}`,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                            <div className="w-50">
                                              <div className="item-detail-heading">
                                                Size
                                              </div>
                                              <div className="d-flex flex-wrap mt-1">
                                                <div className="size-index mr-2 mb-2 text-center">
                                                  <div className="size-index-text mt-1">
                                                    {item.type}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-50">
                                              <div className="item-detail-heading">
                                                Quantity
                                              </div>
                                              <div className="d-flex mt-1">
                                                <div className="item-quantity">
                                                  {item.quantity}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex flex-column align-items-end">
                                          <div
                                            role="button"
                                            onClick={() => {
                                              handleRemoveItemFromCart(item.id);
                                            }}
                                          >
                                            <img
                                              src={iconDelete}
                                              alt="iconDelete"
                                            />
                                          </div>
                                          {item.data.isDelivery === false && (
                                            <img
                                              className="mt-3 free-shipping-icon"
                                              src={IconFreeShipping}
                                              alt="IconFreeShipping"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="card-right right-product">
                              <div className="inner-card p-3 pb-4">
                                <div className="order-detail">
                                  Order Details
                                </div>
                                <fieldset className="card-order mt-3">
                                  <legend className="w-auto ml-2 mr-2 text-dim">
                                    <div className="ml-2 mr-2">Delivery</div>
                                  </legend>
                                  {userInfo.address.length === 0 ? (
                                    <>
                                      <div className="d-flex flex-column justify-content-between">
                                        <div className="d-flex">
                                          <div className="mx-3">
                                            <img
                                              className="location-icon"
                                              src={IconLocationDanger}
                                              alt="IconLocationDanger"
                                            />
                                          </div>
                                          <div className="card-location-danger">
                                            No address added!
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-end py-2 px-2">
                                          <div
                                            className="card-location-danger mx-2"
                                            role="button"
                                            onClick={NavigateToAddressBook}
                                          >
                                            Add Address
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="d-flex flex-column justify-content-between">
                                        <div className="d-flex">
                                          <div className="mx-3">
                                            <img
                                              className="location-icon"
                                              src={IconLocation}
                                              alt="IconLocation"
                                            />
                                          </div>
                                          <div className="card-location">
                                            {
                                              userInfo.address[addressIndex]
                                                .completeAddress
                                            }{" "}
                                            {", "}
                                            {
                                              userInfo.address[addressIndex]
                                                .landmark
                                            }
                                            {", "}
                                            {
                                              userInfo.address[addressIndex]
                                                .city
                                            }
                                            {", "}
                                            {
                                              userInfo.address[addressIndex]
                                                .postalOrZip
                                            }
                                            {", "}
                                            {
                                              userInfo.address[addressIndex]
                                                .province
                                            }
                                          </div>
                                          <div
                                            className="mx-2"
                                            type="button"
                                            id="locationDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img
                                              className="location-icon"
                                              src={IconShowMore}
                                              alt="IconShowMore"
                                            />
                                          </div>
                                          <div
                                            className="dropdown-menu dropdown-menu-right shadow drop-down-address"
                                            aria-labelledby="locationDropdown"
                                          >
                                            {userInfo.address.map(
                                              (address, index) => {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="dropdown-item drop-down-item"
                                                    role="button"
                                                    onClick={(e) =>
                                                      SelectAddress(e, index)
                                                    }
                                                  >
                                                    {address.completeAddress}{" "}
                                                    {", "}
                                                    {address.landmark}
                                                    {", "}
                                                    {address.city}
                                                    {", "}
                                                    {address.postalOrZip}
                                                    {", "}
                                                    {address.province}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-end py-2 px-2">
                                          <div
                                            className="card-location-link mx-2"
                                            role="button"
                                            onClick={NavigateToAddressBook}
                                          >
                                            Change
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </fieldset>

                                <fieldset className="card-order mt-2">
                                  <legend className="w-auto ml-2 mr-2 text-dim">
                                    <div className="ml-2 mr-2">Email</div>
                                  </legend>
                                  {userInfo.isEmailVerify === true ? (
                                    <>
                                      <div className="d-flex flex-column justify-content-between">
                                        <div className="d-flex">
                                          <div className="mx-3">
                                            <img
                                              className="location-icon"
                                              src={IconEmail}
                                              alt="IconLocation"
                                            />
                                          </div>
                                          <div className="card-location">
                                            {userInfo.email}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-end py-2 px-2">
                                          <div
                                            className="card-location-link mx-2"
                                            role="button"
                                            data-toggle="modal"
                                            data-target="#email-modal"
                                          >
                                            Change
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="d-flex flex-column justify-content-between">
                                        <div className="d-flex">
                                          <div className="mx-3">
                                            <img
                                              className="location-icon"
                                              src={IconEmailDanger}
                                              alt="IconEmailDanger"
                                            />
                                          </div>
                                          <div className="card-location-danger">
                                            Email not verified yet!
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-end py-2 px-2">
                                          <div
                                            className="card-location-danger mx-2"
                                            role="button"
                                            data-toggle="modal"
                                            data-target="#add-email-modal"
                                          >
                                            Add Email
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </fieldset>

                                <fieldset className="card-order mt-2">
                                  <legend className="w-auto ml-2 mr-2 text-dim">
                                    <div className="ml-2 mr-2">Phone</div>
                                  </legend>
                                  {userInfo.isContactVerify === true ? (
                                    <>
                                      <div className="d-flex flex-column justify-content-between">
                                        <div className="d-flex">
                                          <div className="mx-3">
                                            <img
                                              className="location-icon"
                                              src={IconPhone}
                                              alt="IconLocation"
                                            />
                                          </div>
                                          <div className="card-location">
                                            {userInfo.contact}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-end py-2 px-2">
                                          <div
                                            className="card-location-link mx-2"
                                            role="button"
                                            data-toggle="modal"
                                            data-target="#number-modal"
                                          >
                                            Change
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="d-flex flex-column justify-content-between">
                                        <div className="d-flex">
                                          <div className="mx-3">
                                            <img
                                              className="location-icon"
                                              src={IconPhoneDanger}
                                              alt="IconPhoneDanger"
                                            />
                                          </div>
                                          <div className="card-location-danger">
                                            Contact not verified yet!
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-end py-2 px-2">
                                          <div
                                            className="card-location-danger mx-2"
                                            role="button"
                                            data-toggle="modal"
                                            data-target="#add-number-modal"
                                          >
                                            Add Phone
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </fieldset>
                                <div className="d-flex flex-wrap justify-content-between mt-1 align-item-center">
                                  <div className="item-number">
                                    Number of Items
                                  </div>
                                  <div className="item-count">
                                    {isCheck.length}
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between mt-1 align-item-center">
                                  <div className="item-number">Sub Total</div>
                                  <div className="item-count">
                                    {calculateSubTotal()}
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between mt-1 align-item-center">
                                  <div className="item-number">
                                    Delivery Chrges
                                  </div>
                                  <div className="item-count">
                                    {calculateDeliveryCharges()}
                                  </div>
                                </div>
                                <br></br>
                                <div className="d-flex flex-wrap justify-content-between mt-2 align-item-center">
                                  <div className="item-number">Total</div>
                                  <div className="item-count">
                                    {calculateTotal()}
                                  </div>
                                </div>
                                {userInfo.address.length === 0 ? (
                                  <></>
                                ) : (
                                  <>
                                    {isCheck.length > 0 && (
                                      <div className="mt-3 d-flex align-item-center justify-content-center">
                                        <div
                                          role="button"
                                          className="order-button text-center"
                                          onClick={placeOrder}
                                        >
                                          Place Order
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {isLoad ? (
                            <>
                              <div
                                className="d-flex justify-content-center w-50 heading-more mt-3"
                                role="button"
                                onClick={() => setLoadData(!loadData)}
                              >
                                Load More{" "}
                                <img
                                  className="ml-2"
                                  src={LoadMore}
                                  alt="LoadMore"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </div>

      <ChangeEmailModal />
      <AddEmailModal />
      <ChangeNumberModal />
      <AddPhoneModal />
    </>
  );
}
