import React, { useState } from 'react'
import chat_close_icon from '../../Assets/images/chat/chat_close_icon.png'
import './chat.css'
import ChatConversations from './ChatConversations'
import ChatMessages from './ChatMessages'

const ChatOpenView = ({ setOpenChatView }) => {
 const [conversationId, setConversationId] = useState("");
 const [itemId, setItemId] = useState("");
 const [storeId, setStoreId] = useState("");
 const [messageStatusId, setMessageStatusId] = useState(null);
    return (
        <div>
            <div class="main chat-overlay">
                <div class="parent">
                    <div class="chat-header d-flex justify-content-between p-3">
                        <div class="live-chat">
                            <h6>LIVE CHAT</h6>
                        </div>
                        <div class="min-livechat">
                            <img onClick={()=>setOpenChatView(false)} src={chat_close_icon} alt="chat_close" />
                        </div>
                    </div>
                    <div>
                        <div class="d-flex">
                            <ChatConversations setConversationId={setConversationId} setItemId={setItemId} setStoreId={setStoreId} messageStatusId={messageStatusId} setMessageStatusId={setMessageStatusId}/>
                            <ChatMessages conversationId = {conversationId} itemId = {itemId} storeId = {storeId} setMessageStatusId={setMessageStatusId}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ChatOpenView
