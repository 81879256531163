import React from "react";
import Header from "../Components/Header/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import slide1 from "../Assets/images/slide1.png";
import storevatar from "../Assets/images/storeavatar.svg";
import IconShare from "../Assets/images/icons/icon-share.svg";
import IconChat from "../Assets/images/icons/icon-chat.svg";
import arrowLeft from "../Assets/images/icons/arrow-circle-left.svg";
import arrowRight from "../Assets/images/icons/arrow-circle-right.svg";
import { apiBaseUrl, imgBaseUrl } from "../Components/config/Config";
import searchIcon from "../Assets/images/icons/search-normal.svg";
import StoreLatestProductListing from "../Components/AboutStore/StoreLatestProductListing";
import StoreTopSellingProducts from "../Components/AboutStore/StoreTopSellingProducts";
import StoreTopRatedProducts from "../Components/AboutStore/StoreTopRatedProducts";
import Loader from "../Components/Loader/Loader";
import NoLocation from "../Components/Loader/NoLocation";
import StoreSearch from "../Components/AboutStore/StoreSearch";
import StoreReviews from "../Components/AboutStore/StoreReviews";
import ShareOptionsStore from "../Components/ShareOptions/ShareOptionsStore";
import MapView from "../Components/MapView/MapView";

function AboutStore() {
  const { id } = useParams();
  let storeUrl = window.location.pathname;

  const [storeData, setStoreData] = useState({});
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("All Products");
  const [searchFilter, setSearchFilter] = useState(null);
  const [searchItemsStatus, setSearchItemsStatus] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setSearchItemsStatus(false);
    axios.get(`${apiBaseUrl}/api/store/detail-store-info/${id}`).then((res) => {
      if (res.data.meta.statusCode === 200) {
        setStoreData(res.data.data.store);
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabSelected = (tabitem) => {
    setTab(tabitem);
  };
  const handleInputChange = (e) => {
    if (e.target.value.length > 0) {
      setSearchFilter(e.target.value);
    } else {
      setSearchItemsStatus(false);
      setSearchFilter(null);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    setSearchItemsStatus(true);
  };

  const OnClickShare = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <Header />
            <div id="aboutstore" className="container">
              {storeData && (
                <div className="d-flex location flex-xl-row flex-lg-row flex-md-column flex-sm-column justify-content-between w-100">
                  <div className="card-container  pt-3 pb-3 pl-2 pr-2">
                    <div className="d-flex flex-wrap justify-content-start">
                      {storeData?.logo === null ? (
                        <>
                          <img
                            className="logo-brand"
                            src={storevatar}
                            alt="storeLogo"
                          />
                        </>
                      ) : (
                        <>
                          {storeData?.logo && (
                            <img
                              className="logo-brand"
                              src={`${imgBaseUrl}/profile/avatar_${storeData.logo}`}
                              alt="storeLogo"
                              loading="lazy"
                            />
                          )}
                        </>
                      )}

                      <div className="px-4 d-flex flex-column align-item-center mt-3 mb-3">
                        <div className="brand-name">{storeData.storeName}</div>
                        {storeData?.keyWords && (
                          <div className="brand-type mt-2">
                            {JSON.parse(storeData.keyWords)[0]}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between mt-4 p-1">
                      <div className="store-text">Seller Rating</div>
                      <div className="store-count">91</div>
                    </div>
                    <div className="d-flex justify-content-between mt-2 p-1">
                      <div className="store-text">Ship On Time</div>
                      <div className="store-count">100</div>
                    </div>
                    <div className="d-flex justify-content-between p-1 mt-2">
                      <div className="store-text">Chat Rate Response</div>
                      <div className="store-count">100</div>
                    </div>

                    {isMenuOpen && (
                      <>
                        <ShareOptionsStore
                          setIsMenuOpen={setIsMenuOpen}
                          storeUrl={storeUrl}
                          storeData={storeData}
                        />
                      </>
                    )}
                    <div className="d-flex justify-content-between p-1 mt-4">
                      <img
                        role="button"
                        src={IconShare}
                        alt="IconShare"
                        onClick={OnClickShare}
                      />
                      <div role="button" className="mr-2 mt-1">
                        <img className="mt-2" src={IconChat} alt="IconChat" />
                        <div className="chat-text">Chat</div>
                      </div>
                    </div>
                  </div>

                  <div className="card-container car-loc">
                    <div className="d-flex  about_cards">
                      <div
                        id="carouselExampleControls"
                        className="carousel slide slider-slide"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner">
                          {storeData?.banner?.length > 0 ? (
                            storeData.banner.slice(0, 5).map((slide, index) => (
                              <div
                                className={`carousel-item ${
                                  index === 0 ? "active" : ""
                                }`}
                                key={index}
                              >
                                <img
                                  className="d-block slider-slide-image"
                                  src={`${imgBaseUrl}/profile/${slide}`}
                                  alt={`slide ${index}`}
                                />
                              </div>
                            ))
                          ) : (
                            <>
                              <div className="carousel-item active carts-bay-store">
                                <div className="d-flex flex-column-reverse  justify-content-center align-items-center">
                                  <div className="text-center mt-3">
                                    <h2 className="text-warning">Carts Bay</h2>
                                    <h5 className="text-dark">store</h5>
                                  </div>
                                  <div className="ml-3">
                                    <img
                                      className="img-fluid"
                                      src={slide1}
                                      alt="First slide"
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <a
                          className="carousel-control-prev"
                          href="#carouselExampleControls"
                          role="button"
                          data-slide="prev"
                        >
                          <span aria-hidden="true">
                            <img src={arrowLeft} alt="arrowLeft" />
                          </span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#carouselExampleControls"
                          role="button"
                          data-slide="next"
                        >
                          <span aria-hidden="true">
                            <img src={arrowRight} alt="arrowLeft" />
                          </span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                      <div className="ml-2">
                        <div className="store-location">
                          {storeData?.isMapAddress ? (
                            <MapView
                              lat={storeData?.mapLocation?.coordinates?.[1]}
                              lng={storeData?.mapLocation?.coordinates?.[0]}
                              mapAddress={storeData?.mapAddress}
                              zoom={15}
                              width={"18vw"}
                              height={"39vh"}
                            />
                          ) : (
                            <NoLocation />
                          )}
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-5 mb-5">
                <div className="mb-3 d-flex flex-wrap justify-content-between">
                  <div className="d-flex">
                    <div
                      role="button"
                      className={
                        tab === "All Products"
                          ? "heading-section heading-section-selected mr-5"
                          : "heading-section mr-5"
                      }
                      onClick={(e) => {
                        tabSelected("All Products");
                      }}
                    >
                      <div className="tab-data">All Products</div>
                    </div>
                    <div
                      role="button"
                      className={
                        tab === "Reviews"
                          ? "heading-section heading-section-selected mr-5"
                          : "heading-section mr-5"
                      }
                      onClick={(e) => {
                        tabSelected("Reviews");
                      }}
                    >
                      <div className="tab-data">Reviews</div>
                    </div>
                  </div>
                  <div className="d-flex search-bar">
                    <input
                      type="search"
                      className="border-0 ml-1"
                      placeholder="Search in store"
                      aria-describedby="button-addon4"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                    <div
                      id="button-addon4"
                      type="button"
                      className="search-button text-center ml-2 mr-1"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <img src={searchIcon} alt="searchIcon" />
                    </div>
                  </div>
                </div>
              </div>

              {tab === "All Products" && (
                <>
                  {searchItemsStatus && (
                    <StoreSearch
                      id={id}
                      searchFilter={searchFilter}
                      setSearchItemsStatus={setSearchItemsStatus}
                    />
                  )}
                  {/* store Latest product lising */}
                  {!searchItemsStatus ? (
                    <>
                      <StoreLatestProductListing id={id} />

                      {/* Top selling products of store */}
                      <StoreTopSellingProducts id={id} />

                      {/* Top Rated products of store */}
                      <StoreTopRatedProducts id={id} />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {tab === "Reviews" && (
                <>
                  <StoreReviews id={id} />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AboutStore;
