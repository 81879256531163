import React from "react";
import IconCancel from "../../Assets/images/icons/icon-cancel.svg";
import IconWave from "../../Assets/images/icons/icon-wave.svg";
import IconFacebook from "../../Assets/images/icons/icon-facebook.svg";
import IconGoogle from "../../Assets/images/icons/icon-google.svg";

const LoginModal = () => {
  return (
    <>
      {/* Modal */}
      <div
        className="modal"
        id="login-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="login-modal">
            <div className="modal-content border-0">
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <img src={IconCancel} alt="IconCancel" />
                  </span>
                </button>
              </div>
              <div className="modal-body m-0 p-0">
                <div className="body-modal">
                  <img src={IconWave} alt="IconCancel" />
                  <div className="py-3">
                    <div className="heading-modal">Welcome back!</div>
                    <div className="text-para mt-2">
                      Please Login to Continue
                    </div>
                  </div>
                  <div className="py-2 mt-3">
                    <div className="field-heading">E-mail or phone number</div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Type your e-mail or phone number"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="field-heading">Password</div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="********"
                        name="password"
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <button type="button" className="btn-sign-in w-100">
                      Sign In
                    </button>
                  </div>
                  <div className="d-flex justify-content-center align-items-center py-2">
                    <span className="line"></span>
                    <small className="px-2 line-text">
                      or do it via other accounts
                    </small>
                    <span className="line"></span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center py-3">
                    <div role="button" className="icon-card mx-3">
                      <img src={IconGoogle} alt="IconGoogle" />
                    </div>
                    <div role="button" className="icon-card mx-3">
                      <img src={IconFacebook} alt="IconFacebook" />
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="login-footer mx-3">
                      Don’t have an account?{" "}
                      <span className="login-footer-span">Get started</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
