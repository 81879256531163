import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import LoginImage from "../Assets/images/login-img.svg";
import IconWave from "../Assets/images/icons/icon-wave.svg";
import Logo from "../Assets/images/logo-white.svg";
import { clearOtpStorage } from "../shared/clearOtpStorage"
import axios from "axios";
import { apiBaseUrl } from "../Components/config/Config";

function ForgetOtp() {
  let navigate = useNavigate();

  const [OTP, setOTP] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const handleChange = (OTP) => {
    setOTP(OTP);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const otpParse = Number(OTP);
    const config = {
      headers: {
        "x-auth-otp": sessionStorage.getItem("token"),
      },
    };
    axios
      .post(`${apiBaseUrl}/api/user/verify-forget-password-otp`, { otp: otpParse }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          sessionStorage.setItem("token", res.data.data.token);
          navigate("/reset-password");
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setLoginError(true);
          setLoginErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setLoginError(false);
            setLoginErrorMessage("");
          }, 5000);
        }

        if (err.response.data.meta.statusCode === 401) {
          setLoginError(true);
          setLoginErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setLoginError(false);
            setLoginErrorMessage("");
          }, 5000);
        }
      });
  };

  const BackToLogin = () => {
    clearOtpStorage();
    navigate("/login");
  };

  return (
    <div id="forgetpassword">
      <div className="girl-image">
      <div className="login-container">
        <Link to="/">
          <div className="header-logo">
            <img src={Logo} alt="Logo" />
          </div>
        </Link>
        <div className="d-flex full-sec flex-wrap  justify-content-between">
          <div className="left-sec">
            <div className="heading-login">Amazing day.</div>
            <div className="description-login mt-3">
              Maybe some text here will help me see it better. Oh God. Oke,
              let’s do it then.
            </div>
          </div>
          {/* <div>
          <img
              className="login-image-section"
              src={LoginImage}
              alt="LoginImage"
            />
          </div> */}
          <div className="mb-5 right-sec form-wid">
            <div className="login-form mr-5">
              <img src={IconWave} alt="IconCancel" />
              <div className="mt-2">
                <div className="heading-form">Enter OTP!</div>
                <div className="text-para mt-2">
                  Please Enter OTP Send to Email or Phone Number
                </div>
                {loginError && (
                  <div
                    className="error-text text-center alert-danger"
                    role="alert"
                  >
                    {loginErrorMessage}
                  </div>
                )}
              </div>
              <form onSubmit={handleOnSubmit}>
                <div className="py-2 mt-2">
                  <div className="field-heading">OTP</div>
                  <div className="mt-1">
                    <OtpInput
                      onChange={handleChange}
                      value={OTP}
                      inputStyle="otpinputStyle"
                      numInputs={6}
                      separator={<span></span>}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-2 mb-3">
                  <div className="otp-resend">Didn't receive OTP</div>
                  <div role="button" className="otp-resend-link">
                    Resend
                  </div>
                </div>
                <div className="py-2">
                  <button type="submit" className="btn-sign-in w-100">
                    Verify
                  </button>
                </div>
              </form>
              <div className="mt-5 ">
                <div className="login-footer mx-3">
                  Back to{" "}
                  <span
                    role="button"
                    className="login-footer-span"
                    onClick={BackToLogin}
                  >
                    Login
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default ForgetOtp;
