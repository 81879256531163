import { createSlice } from "@reduxjs/toolkit";
import { userData } from "./userActions";

const userToken = sessionStorage.getItem("userToken")
  ? sessionStorage.getItem("userToken")
  : null;

const initialState = {
  loading: false,
  userInfo: null,
  userToken,
  error: null,
  success: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
    updateLogo: (state, { payload }) => {
      state.userInfo["image"] = payload;
    },
    updateName: (state, { payload }) => {
      state.userInfo["name"] = payload;
    },
    updateEmail: (state, { payload }) => {
      state.userInfo["email"] = payload;
      state.userInfo["isEmailVerify"] = true;
    },
    updateContact: (state, { payload }) => {
      state.userInfo["contact"] = payload;
      state.userInfo["isContactVerify"] = true;
    },
    addAddress: (state, { payload }) => {
      state.userInfo["address"] = payload;
    },
    addMapAddress: (state, { payload }) => {
      state.userInfo["mapAddress"] = payload['mapAddress'];
      state.userInfo["location"] = (payload.location.coordinates);
      state.userInfo["isMapAddress"] = payload['isMapAddress'];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userData.pending, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(userData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload?.data?.user;
      state.userToken = payload?.data?.token;
    });
    builder.addCase(userData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const {
  logout,
  updateLogo,
  updateName,
  updateEmail,
  updateContact,
  addAddress,
  addMapAddress
} = userSlice.actions;
export default userSlice.reducer;
