import React from "react";
import Lottie from "react-lottie";
import animationData from "../../Assets/SmallCardSkeleton.json";

const SmallCardSkeleton = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="d-flex align-items-start justify-content-start">
        <Lottie options={defaultOptions} width={'12rem'}  />
    </div>
  );
};

export default SmallCardSkeleton;
