import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Assets/images/logo.svg";
import UserAvatar from "../../../Assets/images/avatar.svg";

import iconDashboard from "../../../Assets/images/icons/dashboard-icons/dashboard.svg";
import iconDashboardActive from "../../../Assets/images/icons/dashboard-icons/dashboardactive.svg";
import iconMyorder from "../../../Assets/images/icons/dashboard-icons/myorder.svg";
import iconMyorderActive from "../../../Assets/images/icons/dashboard-icons/myorderactive.svg";
import iconAddressbook from "../../../Assets/images/icons/dashboard-icons/addressbook.svg";
import iconAddressbookActive from "../../../Assets/images/icons/dashboard-icons/addressbookactive.svg";
import iconMyfavourite from "../../../Assets/images/icons/dashboard-icons/myfavourite.svg";
import iconMyfavouriteActive from "../../../Assets/images/icons/dashboard-icons/myfavouriteactive.svg";
import iconSetting from "../../../Assets/images/icons/dashboard-icons/setting.svg";
import iconSettingActive from "../../../Assets/images/icons/dashboard-icons/settingactive.svg";
import iconLogout from "../../../Assets/images/icons/dashboard-icons/logout.svg";
import { imgBaseUrl } from "../../config/Config";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../app/features/user/userSlice";

function HeaderUser() {
  const { userInfo } = useSelector((state) => state.user);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  let pathname = window.location.pathname;

  const logOut = () => {
    dispatch(logout());
    navigate("/login");
  };
  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">
        <Link className="nav-link logo" to="/">
          <img src={Logo} alt="Logo" />
        </Link>
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {userInfo.image === null ? (
                <>
                  <img
                    className="img-profile rounded-circle"
                    src={UserAvatar}
                    width="30"
                    height="30"
                    alt="userprofile"
                  />
                </>
              ) : (
                <>
                  <img
                    className="img-profile rounded-circle"
                    src={`${imgBaseUrl}/profile/avatar_${userInfo.image}`}
                    width="30"
                    height="30"
                    alt="userprofile"
                    loading="lazy"
                  />
                </>
              )}
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <Link className="dropdown-item mr-4" to="/dashboard-buyer">
                {pathname.match("/dashboard-buyer") ? (
                  <>
                    <img
                      className="mr-3"
                      src={iconDashboardActive}
                      alt="iconDashboardActive"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="mr-3"
                      src={iconDashboard}
                      alt="iconDashboard"
                    />
                  </>
                )}
                <span
                  className={`${
                    pathname.match("/dashboard-buyer")
                      ? "sidebar-span-active"
                      : "sidebar-span"
                  }`}
                >
                  Dashboard
                </span>
              </Link>
              <Link className="dropdown-item mr-4" to="/user-orders">
                {pathname.match("/user-orders") ? (
                  <>
                    <img
                      className="mr-3"
                      src={iconMyorderActive}
                      alt="iconMyorderActive"
                    />
                  </>
                ) : (
                  <>
                    <img className="mr-3" src={iconMyorder} alt="iconMyorder" />
                  </>
                )}
                <span
                  className={`${
                    pathname.match("/user-orders")
                      ? "sidebar-span-active"
                      : "sidebar-span"
                  }`}
                >
                  My Orders
                </span>
              </Link>
              <Link className="dropdown-item mr-4" to="/user-address">
                {pathname.match("/user-address") ? (
                  <>
                    <img
                      className="mr-3"
                      src={iconAddressbookActive}
                      alt="iconAddressbookActive"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="mr-3"
                      src={iconAddressbook}
                      alt="iconAddressbook"
                    />
                  </>
                )}
                <span
                  className={`${
                    pathname.match("/user-address")
                      ? "sidebar-span-active"
                      : "sidebar-span"
                  }`}
                >
                  Address Book
                </span>
              </Link>
              <Link className="dropdown-item mr-4" to="/user-favourite">
                {pathname.match("/user-favourite") ? (
                  <>
                    <img
                      className="mr-3"
                      src={iconMyfavouriteActive}
                      alt="iconMyfavouriteActive"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="mr-3"
                      src={iconMyfavourite}
                      alt="iconMyfavourite"
                    />
                  </>
                )}
                <span
                  className={`${
                    pathname.match("/user-favourite")
                      ? "sidebar-span-active"
                      : "sidebar-span"
                  }`}
                >
                  My Favourites
                </span>
              </Link>
              <Link className="dropdown-item mr-4" to="/user-profile-setting">
                {pathname.match("/user-profile-setting") ? (
                  <>
                    <img
                      className="mr-3"
                      src={iconSettingActive}
                      alt="iconSettingActive"
                    />
                  </>
                ) : (
                  <>
                    <img className="mr-3" src={iconSetting} alt="iconSetting" />
                  </>
                )}
                <span
                  className={`${
                    pathname.match("/user-profile-setting")
                      ? "sidebar-span-active"
                      : "sidebar-span"
                  }`}
                >
                  Accout Setting
                </span>
              </Link>

              <div className="dropdown-divider"></div>
              <div
                className="dropdown-item mr-4"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  logOut();
                }}
              >
                <img className="mr-2" src={iconLogout} alt="iconLogout" />
                <span className="sidebar-span">Logout</span>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default HeaderUser;
