import React, { useEffect, useState } from "react";
import currentLocations from "../../Assets/images/currentLocations.svg"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import axios from "axios";
import { apiBaseUrl } from "../../Components/config/Config";
import { toast } from "react-toastify";
import MapView from "../../Components/MapView/MapView";
import 'react-toastify/dist/ReactToastify.css';
import { GoogleApiWrapper} from 'google-maps-react';
import { useSelector,useDispatch } from "react-redux";
import { addMapAddress } from "../../app/features/user/userSlice";
import NoLocation from "../../Components/Loader/NoLocation";

const Maplocation = ({closeModal, stepCount, setStepCount, ...props }) => {
  const dispatch=useDispatch()
  const { userToken ,userInfo} = useSelector((state) => state.user);
  const [address, setAddress] = useState('');
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [searchLocation, setSearchLocation] = useState({});
  const [mapAddress, setMapAddress] = useState("");
  const { adressError, setAddressError } = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  useEffect(()=>{
    if(userInfo.isMapAddress){
        setSearchLocation({lng: userInfo?.location?.coordinates?.[0],  lat: userInfo?.location?.coordinates?.[1]});
        setAddress(userInfo.mapAddress)
        setMapAddress(userInfo.mapAddress)
    }
  },[]);
  const getCurrentLocation = async(e) => {
    e.preventDefault();
    setAddress('');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setSearchLocation({ lat: latitude, lng: longitude });
          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyA5dpHg91sA_-H5ZYv_4aavdYIDHBLDTZU`
            );
            if (!response.ok) {
              throw new Error('Error fetching current location address');
            }
            const data = await response.json();
            const currentAddress = data.results[0].formatted_address;
            setMapAddress(currentAddress)
            setAddress(currentAddress);
          } catch (error) {
          }
        },
        (error) => {
          setAddress('Error getting current location');
        }
      );
    }
  };

  const handlePlaceSelect = async (address, placeId) => {
    setAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setSearchLocation(latLng);
      setSelectedPlace(results[0]);
      setMapAddress(results[0].formatted_address);
    } catch (error) {
      console.error('Error selecting place', error);
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (searchLocation['lat'] && searchLocation['lng'] && mapAddress !== "") {
      const config = {
        headers: {
          "x-auth-token":userToken,
        },
      };
      const locationDetails = { mapAddress: mapAddress,  location:{ type: "Point", coordinates:[ searchLocation['lng'],searchLocation['lat']] } }
      axios
        .put(`${apiBaseUrl}/api/user/location`, locationDetails, config)
        .then((res) => {
          if(res.data.data){
            dispatch(addMapAddress(res.data.data))
            toast.success("Location Added Successfully!");
            closeModal()
             }
        })
        .catch((err) => {
          if (err.response.data.meta.statusCode === 400) {
            setAddressError(true);
            setAddressErrorMessage(err.response.data.meta.message);
            setTimeout(() => {
              setAddressError(false);
              setAddressErrorMessage("");
            }, 5000);
          }

          if (err.response.data.meta.statusCode === 401) {
            setAddressError(true);
            setAddressErrorMessage(err.response.data.meta.message);
            setTimeout(() => {
              setAddressError(false);
              setAddressErrorMessage("");
            }, 5000);
          }
        })
    }
    else {
      toast.error("Please select address location");
    }
  };
  return (
    <form onSubmit={handleOnSubmit}>
      <div className="map-current-location-card">
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handlePlaceSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <div className='d-flex justify-content-start'>
                <div className="map-location-search-container m-1">
                  <input
                    {...getInputProps({
                      placeholder: 'Search  location',
                      className: 'location-search-input',
                    })}
                  />
                </div>
                <div>
                  <div className=" mt-2" style={{width:'40px',height:'40px'}} onClick={(e) => getCurrentLocation(e)}>
                    <img src={currentLocations} alt="img-not-found"/>
                  </div>
                </div>
              </div>
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => (
                  <div {...getSuggestionItemProps(suggestion)}>
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <div className="ml-2" >
          {address?(
            <MapView lat={searchLocation?.lat} lng={searchLocation?.lng} mapAddress={mapAddress} zoom={15} width={'46.5vw'} height={'55vh'} />
          ):(<>
          <NoLocation/>
          </>)}
        </div>
      </div>

      <div className="d-flex justify-content-between mt-3">
      
        <div className="w-100 ml-2">
          <button type="submit" className="btn-next w-100">
            Save
          </button>
        </div>
      </div>
     
    </form>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyA5dpHg91sA_-H5ZYv_4aavdYIDHBLDTZU',
  libraries: ['places'],
})(Maplocation);