import React from "react";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const ShareOptions = ({ setIsMenuOpen, productUrl, item }) => {
  const title = item.title;
  const media = item.images[0];

  return (
    <div className="share-options">
      <div className="d-flex flex-column justify-content-end ">
        <WhatsappShareButton
          url={productUrl}
          onShareWindowClose={() => setIsMenuOpen(false)}
          title={title}
          media={media}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <FacebookShareButton
          url={productUrl}
          hashtag={`#${title}`}
          description={title}
          onShareWindowClose={() => setIsMenuOpen(false)}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <LinkedinShareButton
          url={productUrl}
          onShareWindowClose={() => setIsMenuOpen(false)}
          title={title}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <TelegramShareButton
          url={productUrl}
          onShareWindowClose={() => setIsMenuOpen(false)}
          title={title}
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <TwitterShareButton
          url={productUrl}
          onShareWindowClose={() => setIsMenuOpen(false)}
          title={title}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <EmailShareButton
          url={productUrl}
          onShareWindowClose={() => setIsMenuOpen(false)}
          subject={title}
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default ShareOptions;
