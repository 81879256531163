import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import filterIcon from "../../Assets/images/icons/icon-filter.svg";
import searchIcon from "../../Assets/images/icons/icon-search.svg";
import arrowDown from "../../Assets/images/icons/arrow-down.svg";
import arrowUp from "../../Assets/images/icons/arrow-up.svg";
import CartIcon from "../../Assets/images/icons/icon-cart-theme.svg";
import iconDashboard from "../../Assets/images/icons/dashboard-icons/dashboard.svg";
import iconMyorder from "../../Assets/images/icons/dashboard-icons/myorder.svg";
import iconAddressbook from "../../Assets/images/icons/dashboard-icons/addressbook.svg";
import iconMyfavourite from "../../Assets/images/icons/dashboard-icons/myfavourite.svg";
import iconSetting from "../../Assets/images/icons/dashboard-icons/setting.svg";
import iconLogout from "../../Assets/images/icons/dashboard-icons/logout.svg";
import UserAvatar from "../../Assets/images/avatar.svg";
import iconNotification from "../../Assets/images/chat/iconNotification.png";
import Logo from "../../Assets/images/logo.svg";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../app/features/user/userSlice";
import Downshift from "downshift";
import axios from "axios";
import { apiBaseUrl, imgBaseUrl } from "../config/Config";
import HeaderNotification from "../HeaderNotification/HeaderNotification";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useLocation } from "react-router-dom";

export default function Header() {
  const { userToken, userInfo } = useSelector((state) => state.user);
  const socket = window.globalSocket;
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [query, setQuery] = useState("");
  const [price, setPrice] = useState([0, 100000]);
  const [rating, setRating] = useState([0, 5]);
  const [salesPercent, setSalesPercent] = useState([1, 100]);
  const [showFilter, setShowFilter] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isNotification, SetIsNotification] = useState(false);
  const [isSale, setIsSale] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [loading, setLoading] = useState(false);


  const navigateSearchPage = () => {
    let url = `/search`;
    if (query) {
      url = url + "?title=" + query;
    }
    if (isDelivery) {
      if (url.includes("?")) {
        url = url + "&isDelivery=" + isDelivery;
      } else {
        url = url + "?isDelivery=" + isDelivery;
      }
    }
    if (isSale) {
      if (url.includes("?")) {
        url = url + "&isSale=" + isSale;
      } else {
        url = url + "?isSale=" + isSale;
      }
    }
    if (price[0] > 0 || price[1] < 100000) {
      if (url.includes("?")) {
        url = url + "&minPrice=" + price[0] + "&maxPrice=" + price[1];
      } else {
        url = url + "?minPrice=" + price[0] + "&maxPrice=" + price[1];
      }
    }
    if (salesPercent[0] > 1 || salesPercent[1] < 100) {
      if (url.includes("?")) {
        url =
          url + "&minSale=" + salesPercent[0] + "&maxSale=" + salesPercent[1];
      } else {
        url =
          url + "?minSale=" + salesPercent[0] + "&maxSale=" + salesPercent[1];
      }
    }
    if (rating[0] > 1 || rating[1] < 5) {
      if (url.includes("?")) {
        url = url + "&minRating=" + rating[0] + "&maxRating=" + rating[1];
      } else {
        url = url + "?minRating=" + rating[0] + "&maxRating=" + rating[1];
      }
    }
    navigate(url);
  };
  const clearfilters = () => {
    setPrice([0, 100000]);
    setIsSale(false);
    setIsDelivery(false);
    setRating([0, 5]);
    setSalesPercent([1, 100]);
  };

  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
    socket.disconnect();
    navigate("/login");
  };

  const filterShow = () => {
    setShowFilter(!showFilter);
  };
  const handleSalesFormatter = (value) => {
    return `${value[0]}% - ${value[1]}%`;
  };

  const handlePriceChange = (newRange) => {
    if (newRange[0] < newRange[1]) {
      setPrice(newRange);
    }
  };

  const handleRatingChange = (newRating) => {
    if (newRating[0] < newRating[1]) {
      setRating(newRating);
    }
  };

  const handleSaleChange = (newSale) => {
    setSalesPercent(newSale);
  };

  let config = {
    headers: {
      "x-auth-token": userToken,
    },
  };
  socket?.on("recive-notification", (data) => {
    if (data) {
      setNotificationCount((prevCount) => prevCount + 1);
    } else {
      setNotificationCount(0);
    }
  });

  useEffect(() => {
    if (queryParams.get("minPrice") || queryParams.get("maxPrice")) {
      setPrice([queryParams.get("minPrice"), queryParams.get("maxPrice")]);
    }
    if (queryParams.get("isSale")) {
      setIsSale(true);
    }
    if (queryParams.get("isDelivery")) {
      setIsDelivery(true);
    }
    if (queryParams.get("minRating") || queryParams.get("maxRating")) {
      setRating([queryParams.get("minRating"), queryParams.get("maxRating")]);
    }
    if (queryParams.get("minSale") || queryParams.get("maxSale")) {
      setSalesPercent([queryParams.get("minSale"), queryParams.get("maxSale")]);
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/api/notification/user-notification-count`, config)
      .then((res) => {
        if (res) {
          setNotificationCount(res.data.data.notificationCount);
        }
      })
      .catch((error) => {
        setNotificationCount(0);
      });
  }, []);
  const toggleToast = () => {
    SetIsNotification(!isNotification);
    axios
      .put(`${apiBaseUrl}/api/notification/user-notification-status`, {}, config)
      .then((res) => {
        setNotificationCount(0);
      })
      .catch((error) => {
        setNotificationCount(notificationCount);
      });
  };

  return (
    <>
      {/* sticky-top */}
      <div className="sticky-top">
        <nav id="header" className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <button
              className="navbar-toggler navbar-toggler-right border-0 p-0"
              type="button"
              data-toggle="collapse"
              data-target="#navbar20"
            >
              <p className="navbar-brand mb-0 logo">
                <img src={Logo} alt="Logo" />
              </p>
            </button>
            <div className="collapse navbar-collapse" id="navbar20">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    <div className="logo">
                      <img src={Logo} alt="Logo" />
                    </div>
                  </Link>
                </li>
                <li className="nav-item mx-2 mt-2">
                  <div className="d-none d-md-block lead mb-0">
                    <div
                      className="d-flex search-bar search-bar-header"
                      id="navbarSupportedContent"
                    >
                      <Downshift
                        onInputValueChange={async (inputValue) => {
                          try {
                            if (inputValue.length > 1) {
                              setQuery(inputValue);
                              setLoading(true);
                              const response = await axios.get(
                                `${apiBaseUrl}/api/listing/auto-suggestion`,
                                { params: { title: inputValue } }
                              );
                              if (response.data.meta["status"]) {
                                if (response.data["data"].length > 0) {
                                  setSuggestions(response.data["data"]);
                                } else {
                                  setSuggestions([]);
                                }
                              } else {
                                setSuggestions([]);
                              }
                              setLoading(false);
                            } else {
                              setSuggestions([]);
                            }
                          } catch (error) {
                            setSuggestions([]);
                            setLoading(false);
                          }
                        }}
                        onChange={(selection) => {
                          if (selection) {
                            setQuery(selection.title);
                            navigate(`/search?title=${selection.title}`);
                          } else {
                            setQuery([]);
                          }
                        }}
                        itemToString={(item) => (item ? item.title : "")}
                      >
                        {({
                          getInputProps,
                          getItemProps,
                          getMenuProps,
                          isOpen,
                          inputValue,
                          highlightedIndex,
                          selectedItem,
                          getRootProps,
                        }) => (
                          <div>
                            <div
                              {...getRootProps({}, { suppressRefError: true })}
                            >
                              <input
                                {...getInputProps()}
                                placeholder="Search in Cartsbay"
                                className=" border-0 ml-1 custom-search"
                                aria-describedby="button-addon4"
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    if (inputValue) {
                                      navigate(`/search?title=${inputValue}`);
                                    }
                                  }
                                }}
                              />
                            </div>
                            <ul className="list-search" {...getMenuProps()}>
                              {isOpen && suggestions.length > 0
                                ? suggestions.map((item, index) => {
                                  const pattern = new RegExp(
                                    `(${inputValue})`,
                                    "i"
                                  );
                                  const parts = item.title.split(pattern);
                                  return (
                                    <li
                                      {...getItemProps({
                                        key: item.title,
                                        index,
                                        item,
                                        style: {
                                          backgroundColor:
                                            highlightedIndex === index
                                              ? "white"
                                              : "white",
                                          color:
                                            highlightedIndex === index
                                              ? "orange"
                                              : "black",
                                        },
                                      })}
                                    >
                                      {parts.map((part, partIndex) =>
                                        part.match(pattern) ? (
                                          <strong
                                            key={partIndex}
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "120%",
                                            }}
                                          >
                                            {part}
                                          </strong>
                                        ) : (
                                          <span key={partIndex}>{part}</span>
                                        )
                                      )}
                                    </li>
                                  );
                                })
                                : null}
                            </ul>
                          </div>
                        )}
                      </Downshift>
                      <div
                        onClick={navigateSearchPage}
                        id="button-addon4"
                        type="button"
                        className="search-button text-center m-1"
                      >
                        <img src={searchIcon} alt="filter icon" />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item mt-3">
                  <div
                    role="button"
                    className="icon-filter ml-3 "
                    onClick={filterShow}
                  >
                    <img src={filterIcon} alt="filter icon" />
                  </div>
                </li>
              </ul>

              <ul className="navbar-nav ml-auto">
                <>
                  <ul>
                    <li className="mt-2 mr-4 list-unstyled">
                      {userToken ? (
                        <div
                          role="button"
                          id="liveToastBtn"
                          onClick={toggleToast}
                        >
                          <div className="position-relative">
                            <img
                              className="cart-icon img-profile"
                              src={iconNotification}
                              alt="CartIcon"
                            />
                            {notificationCount > 0 &&
                              (notificationCount > 9 ? (
                                <span className="notification-badge bg-danger rounded-circle text-white">
                                  9+
                                </span>
                              ) : (
                                <span className="notification-badge bg-danger rounded-circle text-white">
                                  {notificationCount}
                                </span>
                              ))}{" "}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </li>{" "}
                  </ul>
                  {isNotification === true ? (
                    <HeaderNotification isNotification={isNotification} />
                  ) : (
                    <></>
                  )}
                </>
                <li className="nav-item  mt-2 mr-4">
                  {userToken ? (
                    <>
                      <Link to="/cart">
                        <div role="button">
                          <img
                            className="cart-icon img-profile"
                            src={CartIcon}
                            alt="CartIcon"
                          />
                          {/* <span className="num rounded-circle ">
                          {cart.length}
                        </span> */}
                        </div>
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                </li>
                {userToken ? (
                  <ul className="navbar-nav ">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <li className="nav-item dropdown no-arrow">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {userInfo.image === null ? (
                          <>
                            <img
                              className="img-profile rounded-circle"
                              src={UserAvatar}
                              width="30"
                              height="30"
                              alt="userprofile"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              className="img-profile rounded-circle"
                              src={`${imgBaseUrl}/profile/avatar_${userInfo.image}`}
                              width="30"
                              height="30"
                              alt="userprofile"
                              loading="lazy"
                            />
                          </>
                        )}
                      </Link>
                      {userToken ? (
                        <>
                          <div
                            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown"
                          >
                            <Link
                              className="dropdown-item mr-4"
                              to="/dashboard-buyer"
                            >
                              <img
                                className="mr-3"
                                src={iconDashboard}
                                alt="iconDashboard"
                              />
                              <span className="sidebar-span">Dashboard</span>
                            </Link>

                            <Link
                              className="dropdown-item mr-4"
                              to="/user-orders"
                            >
                              <img
                                className="mr-3"
                                src={iconMyorder}
                                alt="iconMyorder"
                              />
                              <span className="sidebar-span mr-4">My Orders</span>
                            </Link>
                            <Link
                              className="dropdown-item mr-4"
                              to="/user-address"
                            >
                              <img
                                className="mr-3"
                                src={iconAddressbook}
                                alt="iconAddressbook"
                              />
                              <span className="sidebar-span mr-4">
                                Address Book
                              </span>
                            </Link>
                            <Link
                              className="dropdown-item mr-4"
                              to="/user-favourite"
                            >
                              <img
                                className="mr-3"
                                src={iconMyfavourite}
                                alt="iconMyfavourite"
                              />
                              <span className="sidebar-span">My Favourites</span>
                            </Link>
                            <Link
                              className="dropdown-item mr-4"
                              to="/user-profile-setting"
                            >
                              <img
                                className="mr-3"
                                src={iconSetting}
                                alt="iconSetting"
                              />
                              <span className="sidebar-span">
                                Account Setting
                              </span>
                            </Link>

                            <div className="dropdown-divider"></div>
                            <div
                              className="dropdown-item  mr-4"
                              role="button"
                              onClick={(e) => {
                                e.preventDefault();
                                logOut();
                              }}
                            >
                              <img
                                className="mr-2"
                                src={iconLogout}
                                alt="iconLogout"
                              />
                              <span className="sidebar-span">Logout</span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </li>
                  </ul>
                ) : (
                  <>
                    <li className="nav-item mx-1">
                      <a href="https://seller.cartsbay.com/register-seller" className="nav-link">
                        Become a Seller
                      </a>
                    </li>
                    <li className="nav-item mx-1">
                      <Link to="/login" className="nav-link">
                        Login
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>

        {showFilter === false ? (
          <>
            <div className="navbar-expand-lg header-bottom d-flex align-item-center justify-content-center postion-absolute">
              <img
                role="button"
                className="m-1"
                src={arrowDown}
                alt="arrowDown"
                onClick={filterShow}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <div>
          {showFilter === true ? (
            <>
              <div className="bg-white">
                <div className="main-filter">
                  <div className="d-flex justify-content-start w-100">
                    <div className="price-filter">
                      <label htmlFor="range">Price</label>
                      <Slider
                        className="custom-slider"
                        range
                        min={0}
                        max={100000}
                        value={price}
                        onChange={handlePriceChange}
                        marks={{ 0: price[0], 100000: price[1] }}
                      />
                    </div>
                    <div className="w-50 d-flex">
                      <div className="form-group  mt-4">
                        <label className="mr-2" for="sales">
                          Sales
                        </label>
                        <input
                          type="checkbox"
                          checked={isSale}
                          onChange={() => setIsSale(!isSale)}
                          id="sales"
                        />
                      </div>
                      <div className="free-delivery-filter form-group d-flex">
                        <label className="mr-2" for="freedelivery">
                          Free delivery
                        </label>
                        <input
                          type="checkbox"
                          checked={isDelivery}
                          onChange={() => setIsDelivery(!isDelivery)}
                          id="freedelivery"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="rating-sale-filter d-flex justify-content-start">
                    <div className="form-group ml-4  mr-5 w-50">
                      <label htmlFor="rating">Rating</label>
                      <Slider
                        range
                        min={0}
                        max={5}
                        step={0.1}
                        value={rating}
                        onChange={handleRatingChange}
                        marks={{ 0: rating[0], 5: rating[1] }}
                      />
                    </div>

                    <div className="form-group  w-50">
                      <label htmlFor="salesPercent">Sales %</label>
                      <Slider
                        range
                        min={1}
                        max={100}
                        value={salesPercent}
                        onChange={handleSaleChange}
                        tipFormatter={handleSalesFormatter}
                        marks={{ 1: salesPercent[0], 100: salesPercent[1] }}
                      />
                    </div>
                  </div>
                  <div className="clear-buttons d-flex justify-content-start  border-0">
                    <div className="d-flex ml-3 ">
                      <button className="clear-button text-white" onClick={clearfilters}>
                        clear
                      </button>
                    </div>
                    <div>
                      <button
                        className="apply-button text-white ml-4  border-0"
                        onClick={navigateSearchPage}
                      >
                        apply
                      </button>
                    </div>
                  </div>
                </div>
                <div className="navbar-expand-lg header-bottom d-flex align-item-center justify-content-center postion-absolute">
                  <img
                    role="button"
                    className="m-1"
                    src={arrowUp}
                    alt="arrowUp"
                    onClick={filterShow}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
