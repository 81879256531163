import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import FavHeart from "../../Components/FavHeart/FavHeart";
import Loader from "../../Components/Loader/Loader";
import StarFilled from "../../Assets/images/icons/icon-star-filled.svg";
import CartIcon from "../../Assets/images/icons/icon-cart.svg";
import LoadMore from "../../Assets/images/icons/icon-load-more.svg";
import { apiBaseUrl, imgBaseUrl } from "../../Components/config/Config";
import NoFavourite from "../../Assets/images/icons/dashboard-icons/no-favourite-yet.svg";

import { useSelector } from "react-redux";

function MyFavourite() {
  const { userToken } = useSelector((state) => state.user);

  const [favouriteItems, setFavouriteItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isLoad, setIsLoad] = useState(true);
  const [page, setPage] = useState(0);
  const [loadData, setLoadData] = useState(false);


  useEffect(() => {
    const config = {
      headers: {
        "x-auth-token": userToken,
      },
    };
    axios
      .get(`${apiBaseUrl}/api/favourite/user-favourite-listing?page=${page}`, config)
      .then((res) => {
        if (res.data.meta.statusCode === 206) {
          setLoading(false);
        }
        if (res.data.meta.statusCode === 200) {
          setFavouriteItems([
            ...favouriteItems,
            ...res.data.data.favouriteListing,
          ]);
          setLoading(false);
        }
        if (res.data.meta.page === page) {
          setIsLoad(false);
        } else {
          setPage(res.data.meta.page);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  const filterFavourite = async (itemId) => {
    let filtereData = await favouriteItems.filter((value) => {
      if (value._id !== itemId) {
        return value;
      } else {
        return false;
      }
    });
    setFavouriteItems(filtereData);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            {favouriteItems.length === 0 ? (
              <>
                <div id="nodata">
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <img src={NoFavourite} alt="NoFavourite" />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div id="myfavourite" className="container">
                  <div
                    id="product-card"
                    className="d-flex flex-wrap justify-content-start"
                  >
                    {favouriteItems.map((item, index) => {
                      return (
                        <div key={index} className="products-card h-100">
                          <div className="position-relative">
                            <div className="d-flex align-item-center p-1 w-100 justify-content-between position-absolute">
                              {item.isSale === true ? (
                                <>
                                  <div className="products-card-percentage">
                                    <div className="percentage">
                                      {item.salePercent} %
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div></div>
                                </>
                              )}
                              <div
                                role="button"
                                className="products-card-fav-icon"
                              >
                                <div className="products-card-fav-icon-img position-absolute">
                                  <FavHeart
                                    key={item._id}
                                    itemId={item._id}
                                    filterFavourite={filterFavourite}
                                  />
                                </div>
                              </div>
                            </div>
                            <Link to={`/product/${item._id}`}>
                              <img
                                className="products-card-image"
                                src={`${imgBaseUrl}/listing/small_${item.images[0]}`}
                                alt="product"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                          <h1>{item.title}</h1>
                          <div className="products-card-price d-flex">
                            <h5 className="card-price">
                              RS.{" "}
                              {Math.ceil(item.price -
                                (item.price * item.salePercent) / 100)}
                            </h5>
                            {item.isSale === true && (
                              <h5 className="card-discount-price ml-2">
                                {item.price}
                              </h5>
                            )}
                          </div>
                          <div className="d-flex align-item-center justify-content-between mt-2">
                            <div className="d-flex ">
                              <img
                                className="star-icon"
                                src={StarFilled}
                                alt="StarFilled"
                              />
                              <div className="star-count ml-2">
                                {item.averageRating}
                              </div>
                              <div className="products-card-sold ml-2">
                                ({item.sold} Sold)
                              </div>
                            </div>
                            <div className="products-card-sold">
                              <Link to={`/product/${item._id}`}>
                                <div
                                  role="button"
                                  className="products-card-cart-icon p-0 m-0"
                                >
                                  <img
                                    className="products-card-cart-icon-img position-absolute"
                                    src={CartIcon}
                                    alt="CartIcon"
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {isLoad ? (
                    <>
                      <div
                        className="d-flex justify-content-center heading-more mt-3"
                        role="button"
                        onClick={() => setLoadData(!loadData)}
                      >
                        Load More{" "}
                        <img className="ml-2" src={LoadMore} alt="LoadMore" />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default MyFavourite;
