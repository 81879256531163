import React, { useEffect, useState } from "react";
import storeavatar from "../../Assets/images/storeavatar.svg";
import StarFilled from "../../Assets/images/icons/icon-star-filled.svg";
import StarEmpty from "../../Assets/images/icons/icon-star-empty.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import SmallCardSkeleton from "../Skeleton/SmallCardSkeleton";
import { imgBaseUrl } from "../config/Config";
import { useSelector } from "react-redux";

const NearestStore = () => {
  const {userInfo} = useSelector((state) => state.user);
  const [listing, setLising] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let page = 0;
  let limit = 5;
  useEffect(() => {
    const geometry =   { type:"Point",  coordinates:userInfo?.location}
    axios
      .get(`api/store/nearest-store?page=${page}&limit=${limit}}&geometry=${JSON.stringify(geometry)}`)
      .then((res) => {
        if(res.data.data.meta.status){
          setLising([...listing, ...res.data.data.store]);
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <div className="scroll_on_bg_card">
          <div
            id="store-card"
            className="d-flex x-scrolls justify-content-start"
          >
            {[0,1,2,3,4].map((index)=>(<div key={index} className="store-card h-100">
                <SmallCardSkeleton />
              </div>))}
          </div>
          </div>
        </>
      ) : (
        <>
          <div className="scroll_on_bg_card">
          <div
            id="store-card"
            className="d-flex x-scrolls justify-content-start"
          >
            {listing.map((store, index) => {
              return (
                <div key={index} className="store-card h-100">
                  <Link to={`/store/${store._id}`}>
                    {store.logo === null ? (
                      <>
                        <img
                          className="store-card-image"
                          src={storeavatar}
                          alt="product"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className="store-card-image"
                          src={`${imgBaseUrl}/profile/small_${store.logo}`}
                          alt="product"
                          loading="lazy"
                        />
                      </>
                    )}
                  </Link>
                  <h1 className="text-center brand-name">
                    {JSON.parse(store.keyWords)[0]}
                  </h1>
                  <h5 className="text-center store-name">{store.storeName}</h5>
                  <div className="text-center">
                    {store.averageRating ? (
                      <>
                        {store.averageRating >= 1 ? (
                          <img
                            className="star-icon"
                            src={StarFilled}
                            alt="StarFilled"
                          />
                        ) : (
                          <>
                            <img
                              className="star-icon"
                              src={StarEmpty}
                              alt="StarEmpty"
                            />
                          </>
                        )}
                        {store.averageRating >= 2 ? (
                          <img
                            className="star-icon"
                            src={StarFilled}
                            alt="StarFilled"
                          />
                        ) : (
                          <>
                            <img
                              className="star-icon"
                              src={StarEmpty}
                              alt="StarEmpty"
                            />
                          </>
                        )}
                        {store.averageRating >= 3 ? (
                          <img
                            className="star-icon"
                            src={StarFilled}
                            alt="StarFilled"
                          />
                        ) : (
                          <>
                            <img
                              className="star-icon"
                              src={StarEmpty}
                              alt="StarEmpty"
                            />
                          </>
                        )}
                        {store.averageRating >= 5 ? (
                          <img
                            className="star-icon"
                            src={StarFilled}
                            alt="StarFilled"
                          />
                        ) : (
                          <>
                            <img
                              className="star-icon"
                              src={StarEmpty}
                              alt="StarEmpty"
                            />
                          </>
                        )}
                        {store.averageRating === 5 ? (
                          <img
                            className="star-icon"
                            src={StarFilled}
                            alt="StarFilled"
                          />
                        ) : (
                          <>
                            <img
                              className="star-icon"
                              src={StarEmpty}
                              alt="StarEmpty"
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <img
                          className="star-icon"
                          src={StarEmpty}
                          alt="StarFilled"
                        />
                        <img
                          className="star-icon"
                          src={StarEmpty}
                          alt="StarFilled"
                        />
                        <img
                          className="star-icon"
                          src={StarEmpty}
                          alt="StarFilled"
                        />
                        <img
                          className="star-icon"
                          src={StarEmpty}
                          alt="StarFilled"
                        />
                        <img
                          className="star-icon"
                          src={StarEmpty}
                          alt="StarFilled"
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          </div>
        </>
      )}
    </>
  );
};

export default NearestStore;
