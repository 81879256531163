import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginImage from "../Assets/images/login-img.svg";
import IconWave from "../Assets/images/icons/icon-wave.svg";
import axios from "axios";
import Joi from "joi";
import { apiBaseUrl } from "../Components/config/Config";
import { clearOtpStorage } from "../shared/clearOtpStorage"

function ResetPassword() {
  let navigate = useNavigate();

  const [userPassword, setUserPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const [userPasswordError, setUserPasswordError] = useState(false);
  const [userPasswordErrorMessage, setUserPasswordErrorMessage] = useState("");

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setUserPassword({
      ...userPassword,
      [name]: value,
    });
    setErrors({});
  };

  var schema =Joi.object ({
    password: Joi.string().required().label("password").messages({
      "string.empty": "Password cannot be empty",
      "string.pattern.base": "Please enter the correct format for the password",
      "string.min": "Password must be at least 8 characters long",
      "string.max": "Password must be no more than 34 characters long"
    }),
    confirmPassword: Joi.string().required().valid(Joi.ref("password")).messages({
      "any.only": "Passwords do not match",
      "any.required": "Confirm password is required"
    }),
  });

  const formValidation = () => {
    const result = schema.validate(userPassword);
    if (!result.error) return null;

    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors({ ...formErrors });
    } else {
      setErrors({});
      const config = {
        headers: {
          "x-auth-otp": sessionStorage.getItem("token"),
        },
      };
      try {
        const password = userPassword.password;
        const confirmPassword = userPassword.confirmPassword;

        axios
          .put(
            `${apiBaseUrl}/api/user/forget-change-password`,
            { password, confirmPassword },
            config
          )
          .then((res) => {
            if (res.data.meta.statusCode === 200) {
              setUserPassword({});
              navigate("/login");
              clearOtpStorage();
            }
          })
          .catch((err) => {
            if (err.response.data.meta.statusCode === 400) {
              setUserPasswordError(true);
              setUserPasswordErrorMessage(err.response.data.meta.message);
              setTimeout(() => {
                setUserPasswordError(false);
                setUserPasswordErrorMessage("");
              }, 5000);
            }

            if (err.response.data.meta.statusCode === 401) {
              setUserPasswordError(true);
              setUserPasswordErrorMessage(err.response.data.meta.message);
              setTimeout(() => {
                setUserPasswordError(false);
                setUserPasswordErrorMessage("");
              }, 5000);
            }
          });
      } catch (err) {
        return err;
      }
    }
  };

  return (
    <div id="resetpassword">
      <div className="login-container">
        <Link to="/">
          <div className="header-logo">LOGO</div>
        </Link>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <div className="heading-login">Amazing day.</div>
            <div className="description-login mt-3">
              Maybe some text here will help me see it better. Oh God. Oke,
              let’s do it then.
            </div>
            <img
              className="login-image-section"
              src={LoginImage}
              alt="LoginImage"
            />
          </div>
          <div className="w-50 d-flex justify-content-end">
            <div className="login-form mr-5">
              <img src={IconWave} alt="IconCancel" />
              <div className="mt-2">
                <div className="heading-form">New Password!</div>
              </div>
              {userPasswordError && (
                <div
                  className="error-text text-center alert-danger"
                  role="alert"
                >
                  {userPasswordErrorMessage}
                </div>
              )}

              <form className="pb-4" onSubmit={handleOnSubmit}>
                <div className="mt-4">
                  <div className="field-heading">New Password</div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="********"
                      value={userPassword.password || ""}
                      name="password"
                      onChange={handleInputOnChange}
                    />
                    {errors.password && (
                      <div className="error-text pb-1">
                        {errors.password}
                      </div>
                    )}
                  </div>
                </div>
                <div className="pt-2">
                  <div className="field-heading">Confirm Password</div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="********"
                      value={userPassword.confirmPassword || ""}
                      name="confirmPassword"
                      onChange={handleInputOnChange}
                    />
                    {errors.confirmPassword && (
                      <div className="error-text pb-1">
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-2">
                  <button type="submit" className="btn-sign-in w-100">
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
