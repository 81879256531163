import React, { useEffect, useState, useRef } from "react";
import "./chat.css";
import axios from "axios";
import icon from "../../Assets/images/chat/icon.svg";
import attachfile from "../../Assets/images/chat/attachfile.svg";
import { apiBaseUrl, imgBaseUrl } from "../config/Config";
import no_data from "../../Assets/images/no-data.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { event } from "jquery";

const ChatMessages = ({
  conversationId,
  itemId,
  storeId,
  setMessageStatusId,
}) => {
  const [messageList, setMessageList] = useState([]);
  const [recentMessage, setRecentMessage] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const socket = window.globalSocket;
  const { userToken, userInfo } = useSelector((state) => state.user);
  const validationSchema = Yup.object({
    textdata: Yup.string(),
    fileInput: Yup.mixed(),
  });
  const ScrolRef = useRef();
  let config = {
    headers: {
      "x-auth-token": userToken,
    },
  };

  const scrollToBottom = () => {
    if (ScrolRef.current) {
      ScrolRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };
  const getMessageList = (list, pageNumber) => {
    setIsLoading(true);
    const apiUrl = `${apiBaseUrl}/api/message/user-message-list/${conversationId}?page=${pageNumber}`;
    axios.get(apiUrl, config).then((res) => {
      setIsLoading(false);
      setMessageList([...list, ...res?.data?.data.messageData]);
      if (res.data.meta.page === page) {
        setIsLoad(false);
      } else {
        setPage(res.data.meta.page);
      }
    });
  }

  useEffect(() => {
    getMessageList([], 0);
    socket.off("recive-message");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  useEffect(() => {
    getMessageList(messageList, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    socket?.on("recive-message", (data) => {
      if (conversationId === data.conversationId) {
        setRecentMessage(data);
      } else {
        if (data.conversationId) {
          setMessageStatusId(data.conversationId);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  useEffect(() => {
    if (recentMessage) {
      setMessageList((messageList) => [recentMessage, ...messageList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentMessage]);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList]);

  const formik = useFormik({
    initialValues: {
      textdata: "",
      fileInput: [],
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let imagesArray = [];
      if (values.fileInput.length > 0 && values.fileInput.length <=5) {
        const formData = new FormData();
        for (const key of Object.keys(values.fileInput)) {
          formData.append("images", values.fileInput[key]);
        }
        const response = await axios.post(
         `${apiBaseUrl}/api/message/storeMessage-file-upload`,
          formData,
          config
        );
        imagesArray = response.data.images;
      } else if (values.fileInput.length > 5) {
        alert("Length greater, cannot upload more than five images");
        return false;
      }
      if (values.fileInput.length === 0 && values.textdata.trim() === "") {
        alert("Text or files cannot be empty");
        return false;
      }

      const timestamp = new Date(); // Get the current timestamp

      const message = {
        message: values.textdata,
        senderId: userInfo._id,
        to: storeId,
        itemid: itemId,
        type: imagesArray.length > 0 ? "image" : "text",
        images: imagesArray,
        timestamp: timestamp,
      };
      setMessageList((messageList) => [message, ...messageList]);
      const messagedata = {
        conversationId: conversationId,
        itemid: itemId,
        message: values.textdata,
        images: imagesArray,
        type: imagesArray.length > 0 ? "image" : "text",
        to: storeId,
        timestamp: timestamp,
      };

      socket.emit("send-message-to-store", messagedata);
      resetForm();
      scrollToBottom();
      socket.off("send-message-to-store");
    },
  });

  return (
    <div className="message-section">
      <div className="messages">
        {messageList.length > 0 ? (
          messageList.toReversed().map((message, index) =>
            message.senderId === userInfo._id ? (
              <>
                {message.type === "text" ? (
                  <div
                    key={index}
                    className="lable-chat-sender pl-3 float-right pt-3 mb-3"
                    ref={ScrolRef}
                  >
                    <label>{message.message}</label>
                  </div>
                ) : (
                  <div className="chat-sender-images ml-5">
                    <div className="image-container d-block p-1 mb-3 ml-5 ">
                      <div className="image-group d-flex justify-content-center align-items-center">
                        {message?.images.slice(0, 2).map((file, index) => (
                          <div key={index} className="image-wrapper">
                            <img
                              className="img-tagss"
                              src={`${imgBaseUrl}/messages/small_${file}`}
                              alt={`tag-pic-${index}`}
                              loading="lazy"
                            />
                          </div>
                        ))}
                      </div>
                      {message?.images.slice(2, 4).length > 0 && (
                        <div className="image-group d-flex justify-content-center align-items-center">
                          {message?.images.slice(2, 4).map((file, index) => (
                            <div key={index} className="image-wrapper ">
                              <img
                                className="img-tagss"
                                src={`${imgBaseUrl}/messages/small_${file}`}
                                alt={`tag-pic-${index + 2}`}
                                loading="lazy"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      {message.images.length > 4 && (
                        <div className="overlay-text">{message.images.length - 4}+</div>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {message.type === "text" ? (<div
                  key={index}
                  className="lable-chat-response float-left pl-3 pt-3 mb-3"
                  ref={ScrolRef}
                >
                  <label>{message.message}</label>
                </div>) : (
                  message?.images.map((file, index) => (
                    <img
                      key={index}
                      className="img-tag"
                      src={`${imgBaseUrl}/messages/small_${file}`}
                      alt="tag-pic"
                      loading="lazy"
                    />
                  ))
                )}
              </>
            )
          )
        ) : (
          <p>No messages to show</p>
        )}
      </div >
      <div className="pl-3 mb-5 d-flex">
        <form onSubmit={formik.handleSubmit} className="w-100">
          <div className="form-row align-items-center d-flex flex-nowrap">
            <div className="textarea-width w-75">
              <textarea
                name="textdata"
                value={formik.values.textdata}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control input-with-shadow"
                placeholder="Message..."
                rows="1"
                cols="17"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    formik.handleSubmit();
                  }
                }}
              />
            </div>
            <div className="ml-3 mr-3">
              <input
                name="fileInput"
                id="fileInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onClick={(event) => { event.currentTarget.value = null; }}
                onChange={(event) => {
                  const files = Array.from(event.currentTarget.files);
                  formik.setFieldValue("fileInput", files);
                }}
                onBlur={formik.handleBlur}
                multiple
               
              />
              <div className="d-flex">
               <label htmlFor="fileInput" className="custom-file-upload mt-3">
                <img src={attachfile} alt="attach_file" width="24px" height="40px" />
              </label>
              <button type="submit" className="w-100 btn chat-submit">
                <img type="submit" src={icon} alt="" className="w-100 h-100" />
              </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatMessages;
