import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiBaseUrl } from "../../../Components/config/Config";

// export const userData = createAsyncThunk(
//   "register/user",
//   async (otp, { rejectWithValue }) => {
//     try {
//       const otpParse = Number(otp);
//       const config = {
//         headers: {
//           "x-auth-otp": sessionStorage.getItem("token"),
//         },
//       };
//       const { data } = await axios.post(
//         `${apiBaseUrl}/api/user/verify-signin-user-otp`,
//         { otp: otpParse },
//         config
//       );
//       return data;
//     } catch (error) {
//       if (error.response && error.response.data.meta.message) {
//         return rejectWithValue(error.response.data.meta.message);
//       } else {
//         return rejectWithValue(error.response.data.meta.message);
//       }
//     }
//   }
// );

export const userData = createAsyncThunk(
  "register/user",
  async (user, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${apiBaseUrl}/api/user/signin`,
        { ...user }
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.meta.message) {
        return rejectWithValue(error.response.data.meta.message);
      } else {
        return rejectWithValue(error.response.data.meta.message);
      }
    }
  }
);
