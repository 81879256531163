import React from "react";
import Lottie from "react-lottie";
import animationData from "../../Assets/DetailScreenSkeleton.json";

const DetailScreenSkeleton = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
      <div className="d-flex align-items-center justify-content-center">
        <Lottie options={defaultOptions} width={'52rem'} />
      </div>
  );
};

export default DetailScreenSkeleton;
