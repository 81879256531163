import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute({ component: Component }) {
  const { userToken } = useSelector((state) => state.user);
  const location = useLocation();

  return userToken ? (
    <Component />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

export default ProtectedRoute;
