import React from "react";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const ShareOptionsStore = ({ setIsMenuOpen, storeUrl, storeData }) => {
  const title = storeData.storeName;
  const media = storeData.logo;

  return (
    <div className="store-share">
      <div className="d-flex justify-content-between">
        <WhatsappShareButton
          url={storeUrl}
          onShareWindowClose={() => setIsMenuOpen(false)}
          title={title}
          media={media}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <FacebookShareButton
          url={storeUrl}
          hashtag={`#${title}`}
          description={title}
          onShareWindowClose={() => setIsMenuOpen(false)}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <LinkedinShareButton
          url={storeUrl}
          onShareWindowClose={() => setIsMenuOpen(false)}
          title={title}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <TelegramShareButton
          url={storeUrl}
          onShareWindowClose={() => setIsMenuOpen(false)}
          title={title}
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <TwitterShareButton
          url={storeUrl}
          onShareWindowClose={() => setIsMenuOpen(false)}
          title={title}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <EmailShareButton
          url={storeUrl}
          onShareWindowClose={() => setIsMenuOpen(false)}
          subject={title}
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default ShareOptionsStore;
